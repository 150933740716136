import { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

/* UI Elements */
import {
  Box,
  Typography,
  Divider,
  IconButton,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

/* i18n */
import { useIntl } from 'react-intl';

export default function ExperienceWalletDisplayItem({ experience, setCurrentContent, index, deleteItem, length }) {
  const intl = useIntl();
  const [atLeast1Open, setAtLeast1Open] = useState(false);
  const [expandedSkills, setExpandedSkills] = useState(false);
  const formattedStartDate = experience.start_date
    ? format(
        typeof experience.start_date === 'string' ? new Date(experience.start_date) : experience.start_date,
        experience.end_date ? 'MM/yyyy' : 'MM/dd/yyyy'
      )
    : '';
  const formattedEndDate = experience.end_date
    ? format(typeof experience.end_date === 'string' ? new Date(experience.end_date) : experience.end_date, 'MM/yyyy')
    : '';

  return (
    <>
      <Box
        sx={{
          // border: (theme) => `dotted 1px ${theme.palette.grey[800]}`,
          backgroundColor: (theme) => theme.palette.grey[200],
          boxShadow: '1px 2px 7px 1px rgba(0, 0, 0, 0.2)',
          borderRadius: 2,
          px: 2,
          py: 2,
          mb: 2,
          mt: 1.5,
        }}
      >
        <Typography sx={{ fontWeight: 700, pr: 1 }}>{experience.title}</Typography>
        {experience.employer_provider ? (
          <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 400 }}>
            {experience.employer_provider}
          </Typography>
        ) : null}
        <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 400 }}>
          {formattedStartDate} -{' '}
          {formattedEndDate ? formattedEndDate : intl.formatMessage({ id: 'experience.present' })}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mb: 1 }}>
        <Typography sx={{ fontWeight: 700, pr: 1 }}>
          {intl.formatMessage({ id: 'experience.drawer.field' })}:
        </Typography>
        <Typography>{experience.field?.title}</Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ fontWeight: 700 }}>{intl.formatMessage({ id: 'experience.drawer.skillPlural' })}:</Typography>
        <Box
          sx={{
            px: 1,
            '& .MuiChip-root': {
              mb: 1,
              '&:not(:last-of-type)': {
                mr: 1,
              },
            },
          }}
        >
          {experience.skills && experience.skills.length
            ? (expandedSkills ? experience.skills : experience.skills.slice(0, 6)).map((skill) => (
                <Chip
                  label={skill.name}
                  icon={skill.wallet ? <AccountBalanceWalletIcon /> : null}
                  color={skill.wallet ? 'primary' : 'secondary'}
                  key={skill.id}
                  size="small"
                  sx={{ '& .MuiChip-icon': { fontSize: 14 }, maxWidth: 210 }}
                  onDelete={skill.wallet ? null : () => setCurrentContent('edit')}
                />
              ))
            : null}
          {experience.skills?.length > 6 ? (
            <Typography
              onClick={() => setExpandedSkills(!expandedSkills)}
              component="span"
              sx={{
                pl: 1,
                textDecoration: 'underline',
                color: (theme) => theme.palette.primary.dark,
                fontSize: 13,
              }}
            >
              {intl.formatMessage({ id: `experience.form.${expandedSkills ? 'seeLess' : 'seeMore'}` })}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
        <IconButton
          aria-label="delete"
          onClick={() => {
            if (length !== 1) {
              deleteItem(index);
            } else {
              setAtLeast1Open(true);
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="edit" onClick={() => setCurrentContent('edit')}>
          <EditIcon />
        </IconButton>
      </Box>

      <Dialog open={atLeast1Open} onClose={() => setAtLeast1Open(false)}>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: 'experience.atLeast1' })}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setAtLeast1Open(false)}>
            {intl.formatMessage({ id: 'experience.ok' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
ExperienceWalletDisplayItem.propTypes = {
  setCurrentContent: PropTypes.func,
  deleteItem: PropTypes.func,
  index: PropTypes.number,
  length: PropTypes.number,
  experience: PropTypes.shape({
    field: PropTypes.object,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    typeLabel: PropTypes.string,
    title: PropTypes.string,
    employer_provider: PropTypes.string,
    skills: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slice: PropTypes.func,
      })
    ),
  }),
};
