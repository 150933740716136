import { ArrowBack } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function ContinuedClaimConfirmation() {
  const intl = useIntl();
  const { selectedClaim } = useSelector((state) => state.ui);
  return (
    <Box sx={{ py: 3.75 }}>
      <Alert severity="success">
        <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
          {intl.formatMessage(
            { id: 'continuedClaim.confirmation.successAlert.title' },
            { weekStart: selectedClaim.weekStart, weekEnd: selectedClaim.weekEnd }
          )}
        </AlertTitle>
        <Typography color="inherit">
          {intl.formatMessage({ id: 'continuedClaim.confirmation.successAlert.body' })}
        </Typography>
        <Button
          component={Link}
          to="/ui-dashboard"
          sx={{ mt: 2 }}
          variant="contained"
          color="secondary"
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage({ id: 'continuedClaim.confirmation.successAlert.cta' })}
        </Button>
      </Alert>
    </Box>
  );
}

export default ContinuedClaimConfirmation;
