import { Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Analytics */
import { trackEvent } from 'lib/analytics';

// Mui v4
import { Container, Typography } from '@mui/material';

// Mui v5
import { AppBar, Box, Toolbar, Button, Hidden, MenuItem } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setActiveSoc } from 'app/slices/jobsSlice';

const NavButton = ({ active, path = '', label, onClick, sx, ...rest }) => {
  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);

  return (
    <Button
      LinkComponent={path.indexOf('http') > -1 ? 'a' : Link}
      href={path.indexOf('http') > -1 ? path : null}
      onClick={onClick}
      to={path.indexOf('http') > -1 ? null : `${urlPrefix}/${path}`}
      color="inherit"
      sx={{
        fontWeight: 600,
        fontFamily: "Avenir, Arial, 'Helvetica Neue', Helvetica, sans-serif",
        fontSize: '1rem',
        textTransform: 'none',
        mr: 2,
        ':after': {
          content: '""',
          color: 'white',
          height: '1px',
          width: '100%',
          position: 'absolute',
          bottom: '-3px',
          borderTop: active ? 'solid 3px' : '',
        },
        ':hover::after': {
          borderTop: 'solid 3px',
        },
        padding: '6px 8px',
        ...(sx ?? null),
      }}
      {...rest}
    >
      {label}
    </Button>
  );
};

NavButton.propTypes = {
  active: PropTypes.bool,
  path: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

const RecommendationsHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];
  const UIEnabled = process.env.REACT_APP_AR_UI === 'true';

  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);
  let history = useHistory();

  const user_settings = useSelector((state) => state.app.user_settings);
  const worksearch_status = useSelector((state) => state.worksearch.status);

  const handleHeroButtonClick = (path) => {
    if (path === 'job-search-activity') {
      trackEvent('HORIZONTAL_MENU_WORKSEARCH');
    }
    if (path !== activePath) {
      history.push(`${urlPrefix}/${path}`);
    }
  };

  const handleMenuItemClick = (page) => () => {
    const eventName = `HORIZONTAL_MENU_${page}`;
    trackEvent(eventName);
    if (page === 'FIND_JOBS') {
      dispatch(setActiveSoc(''));
    }
  };

  return (
    <Hidden lgDown>
      <AppBar position="static" sx={{ mb: 2, bgcolor: 'secondary.main' }}>
        <Container maxWidth="lg" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Toolbar style={{ paddingLeft: 16 }}>
            {worksearch_status.worksearch_enabled && UIEnabled && (
              <NavButton
                path="ui-dashboard"
                active={[
                  'ui-dashboard',
                  'ui-work-search-activity',
                  'file-a-claim',
                  'ui-law-changes',
                  'continued-claim',
                ].includes(activePath)}
                label={intl.formatMessage({ id: 'layout.menu.uidashboard' })}
                onClick={handleMenuItemClick('UI_DASHBOARD')}
                id="ui-dashboard-menu-item"
              />
            )}
            <NavButton
              path="jobs"
              active={activePath === 'jobs'}
              label={intl.formatMessage({ id: 'layout.menu.jobs' })}
              onClick={handleMenuItemClick('FIND_JOBS')}
              id="jobs-menu-item"
            />
            <NavButton
              path="careers"
              active={activePath === 'careers'}
              label={intl.formatMessage({ id: 'layout.menu.careers' })}
              onClick={handleMenuItemClick('EXPLORE_CAREERS')}
              id="explore-careers-menu-item"
            />
            <Box
              display="flex"
              sx={{ position: 'relative', display: 'inline-block', '&:hover .menuContent': { display: 'block' } }}
            >
              <NavButton
                path="training"
                active={activePath === 'training'}
                label={intl.formatMessage({ id: 'layout.menu.training' })}
                onClick={handleMenuItemClick('TRAINING')}
                sx={{
                  '.MuiButton-endIcon': { marginLeft: '2px' },
                  mr: 1,
                }}
                endIcon={<KeyboardArrowDownIcon />}
                id="training-menu-item"
              />
              {/* <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose} onClick={handleMenuClose}> */}
              <Box
                className="menuContent"
                sx={{
                  display: 'none',
                  position: 'absolute',
                  backgroundColor: '#ffffff',
                  borderRadius: '4px',
                  minWidth: '160px',
                  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                  zIndex: 1,
                  py: 1,
                }}
              >
                <MenuItem
                  onClick={() => {
                    history.push(`${urlPrefix}/training`);
                    handleMenuItemClick('TRAINING')();
                  }}
                >
                  <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)' }} id="training-recommendations-link">
                    {intl.formatMessage({ id: 'layout.menu.trainingRecommendations' })}
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    component="a"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://myardashboard.arkansas.gov/etprovidersmap.html"
                    onClick={() => handleMenuItemClick('TRAININGPROVIDER')()}
                  >
                    {intl.formatMessage({ id: 'layout.menu.findTrainingProvider' })}
                    <OpenInNewIcon sx={{ fontSize: 18, ml: 0.5 }} />
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}
                    component="a"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://myardashboard.arkansas.gov/hepg.html"
                    onClick={() => handleMenuItemClick('HIGHERED')()}
                  >
                    {intl.formatMessage({ id: 'layout.menu.findHigherEdProvider' })}
                    <OpenInNewIcon sx={{ fontSize: 18, ml: 0.5 }} />
                  </Typography>
                </MenuItem>
              </Box>

              {/* </Menu> */}
            </Box>
            <NavButton
              path="experience"
              active={activePath === 'experience'}
              label={intl.formatMessage({ id: 'layout.menu.experience' })}
              onClick={handleMenuItemClick('EXPERIENCE')}
              id="experience-menu-item"
            />
            {user_settings.add_job_search_activity_hero_button ? (
              <NavButton
                path="job-search-activity"
                active={activePath === 'job-search-activity'}
                label={intl.formatMessage({ id: 'jobSearchActivity.titleShort' })}
                onClick={() => handleHeroButtonClick('job-search-activity')}
                id="work-search-menu-item"
              />
            ) : null}
            {false && (
              <NavButton
                path="http://arkansas.gov/"
                rel="noopener noreferrer"
                target="_blank"
                label={
                  <>
                    {intl.formatMessage({ id: 'layout.menu.otherServices' })}
                    <OpenInNewIcon sx={{ fontSize: 18, ml: 0.5 }} />
                  </>
                }
                onClick={() => trackEvent('MENU_ARKANSASGOV_CLICK')}
              />
            )}
            <NavButton
              path="https://myarciviform.arkansas.gov/programs"
              rel="noopener noreferrer"
              target="_blank"
              label={intl.formatMessage({ id: 'layout.menu.applyForBenefits' })}
              id="connect-to-services-menu-item"
              endIcon={<OpenInNewIcon />}
            />
          </Toolbar>
        </Container>
      </AppBar>
    </Hidden>
  );
};

RecommendationsHeader.propTypes = {
  width: PropTypes.string,
};

export default RecommendationsHeader;
