import { Helmet } from 'react-helmet-async';

function FeatureRequestSurveyScript() {
  return (
    <Helmet>
      <script id="feature-request-survey" type="text/javascript">
        {`!function(e,t,r,n,c){if(e[n])return;for(var o=e[n]=[],s=["setPersonProperties","setSurveyProperties","logout"],i=0;i<s.length;i++){var a=s[i];o[a]=o[a]||function(e){return function(){var t=Array.prototype.slice.call(arguments);o.push([e,t])}}(a)}o.SNIPPET_VERSION="1.0.0",o.WK=r;const u=t.currentScript.nonce||t.currentScript.getAttribute("nonce")||!1;var p=t.createElement("script");p.type="text/javascript",p.nonce=u,p.async=!0,p.src=c||"https://surveys-web.delighted.com/v1/library/"+n+".js";var l=t.getElementsByTagName("script")[0];l.parentNode.insertBefore(p,l)}(window,document,"CnmYoMTvW8m5","delightedSurveys",null);`}
      </script>
    </Helmet>
  );
}

export default FeatureRequestSurveyScript;
