export const getUiDashboard = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        info: {
          uuid: 'bgordon@ripl.org',
          audit: {
            timestamp: '2024-09-09T19:54:50.733642',
            context: '/api/dashboard/bgordon@ripl.org',
          },
          status: {
            code: 200,
            message: 'Success',
          },
        },
        data: {
          claim_week: [
            {
              week_end: '2024-09-21',
              status: 'I',
            },
            {
              week_end: '2024-09-14',
              status: 'I',
            },
            {
              week_end: '2024-09-07',
              status: 'I',
              activities: [
                {
                  activity_date: '2024-09-05',
                  worksearch_id: 'activity#20240517144535#2267afb187c54e5cbe9dd68cc265101a',
                  created_on: '2024-05-17T14:45:35.096087Z',
                  updated_on: '2024-05-17T14:45:35.096087Z',
                  upload_documents: [],
                  notes: '',
                  activity_type: 'attend_job_fair',
                  draft: false,
                  job_fair: 'Sample',
                  job_fair_website: 'the-job-fair.com',
                  contact_email: 'josetrezza@gmail.com',
                  date_of_job_fair: '2024-05-16',
                  time_of_job_fair: '2024-05-17T07:10:00.000Z',
                  location: '234',
                  result_of_job_fair: ['performed_interview'],
                  week_start_date: '2024-09-01',
                },
              ],
            },
          ],
          summary: {
            week_end: '2024-09-14',
            benefits_processed_dt: '2024-09-18',
            benefits_amount: 200.0,
            total_deductions: 190.0,
          },
          profile: {
            name: 'B Gordon',
          },
          annual_claim: {
            start_date: null,
            end_date: null,
            used_amount: null,
            total_amount: null,
            remaining_balance: null,
          },
        },
      });
    }, 300);
  });
};

export const getMailingAddress = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        info: {
          uuid: 'bgordon@ripl.org',
          audit: {
            timestamp: '2024-09-17T02:39:06.818341',
            context: '/api/profile/bgordon@ripl.org/mailing-address',
          },
          status: {
            code: 200,
            message: 'Success',
          },
        },
        data: {
          addr_line: '222 Halleck St',
          addr_line2: '',
          city: 'San Francisco',
          state: 'CA',
          country: 'USA',
          zip: '94129',
        },
      });
    }, 300);
  });
};

export const getBankingInfo = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        info: {
          uuid: 'bgordon@ripl.org',
          audit: {
            timestamp: '2024-09-17T02:39:06.818341',
            context: '/api/profile/bgordon@ripl.org/banking-info',
          },
          status: {
            code: 200,
            message: 'Success',
          },
        },
        data: {
          account_number: '3456',
          routing_number: 311093120,
          account_type: 'Checking',
          current_payment: 'DD',
        },
      });
    }, 300);
  });
};

const routingNumbersDict = {
  311093120: {
    bank_name: 'BANK OF AMERICA',
  },
  '067004764': {
    bank_name: 'CITIBANK',
  },
};

export const validateRoutingNumber = (number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (!Object.keys(routingNumbersDict).includes(String(number))) {
        reject(new Error('Routing Number Not Found'));
      }
      resolve({
        info: {
          uuid: 'bgordon@ripl.org',
          audit: {
            timestamp: '2024-09-17T02:39:06.818341',
            context: '/api/profile/bgordon@ripl.org/banking-info',
          },
          status: {
            code: 200,
            message: 'Success',
          },
        },
        data: routingNumbersDict[String(number)],
      });
    }, 300);
  });
};
