import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { Field } from 'formik';
import PropTypes from 'prop-types';

export default function TextInputField({ name, label, helperText, disabled, error }) {
  return (
    <Field name={name}>
      {({ field }) => (
        <FormControl disabled={disabled} error={error}>
          <FormLabel htmlFor={field.name}>{label}</FormLabel>
          <OutlinedInput
            id={field.name}
            aria-labelledby={field.name}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
}

TextInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};
