import { useState, useEffect } from 'react';
import { apiRequest } from 'lib/API';
import { pathOr } from 'ramda';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setActivities, setStatus, setLoaded } from 'app/slices/workSearchSlice';

/* API */
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

import { getWeekArr } from 'components/JobSearchActivity';

const useNotifications = () => {
  const dispatch = useDispatch();
  const myProfileNotificationShown = localStorage && localStorage.getItem('myProfileNotificationShown');
  const loaded_worksearch = useSelector((state) => state.worksearch.loaded);
  const worksearch_status = useSelector((state) => state.worksearch.status);
  const worksearch_activities = useSelector((state) => state.worksearch.activities);
  const { isAuthenticated } = useAuth0();

  const idQuery = useQuery({
    queryKey: ['/users/me'],
    enabled: isAuthenticated,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const additionalRequests = isAuthenticated && idQuery.isSuccess;

  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
    refetchOnMount: 'always',
    cacheTime: 0,
    enabled: additionalRequests,
    refetchOnWindowFocus: false,
  });
  const experiences = pathOr([], ['data', 'job_experience'], experienceQuery);
  const likedSkills = experiences.reduce((acc, curr) => {
    if (curr.skills?.length) {
      return acc.concat(curr.skills);
    }
    return acc;
  }, []);

  const [weekArray, setWeekArray] = useState([]);
  const incompleteWeeks = weekArray.filter((week) => week.required > week.items.length);

  useEffect(() => {
    if (idQuery.isSuccess && !loaded_worksearch) {
      apiRequest('GET', '/users/me/worksearch/').then(({ activities, status }) => {
        const weekArr = getWeekArr(status, activities);
        setWeekArray(weekArr);
        dispatch(setActivities(activities));
        dispatch(setStatus(status));
        if (!status.ssn_required) {
          dispatch(setLoaded(true));
        }
      });
    } else {
      const weekArr = getWeekArr(worksearch_status, worksearch_activities);
      setWeekArray(weekArr);
    }
  }, [worksearch_activities.length, idQuery.isSuccess]);

  return [incompleteWeeks, likedSkills, myProfileNotificationShown ? 0 : 1];
};

export default useNotifications;
