import { Alert, Box, Typography } from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';

export default function PaymentMethodAlert() {
  const intl = useIntl();
  return (
    <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
      <Typography variant="body2" mb={2}>
        {intl.formatMessage({ id: 'fileClaim.bankingModal.info.p1' })}
      </Typography>
      <Typography variant="body2" mb={2}>
        {intl.formatMessage({ id: 'fileClaim.bankingModal.info.p2' })}
      </Typography>
      <Typography variant="body2">{intl.formatMessage({ id: 'fileClaim.bankingModal.info.p3' })}</Typography>
      <Box component="ul" sx={{ mb: 0.5 }}>
        <Typography variant="body2" component="li">
          {intl.formatMessage({ id: 'fileClaim.bankingModal.info.b1' })}
        </Typography>
        <Typography variant="body2" component="li">
          {intl.formatMessage({ id: 'fileClaim.bankingModal.info.b2' })}
        </Typography>
        <Typography variant="body2" component="li">
          {intl.formatMessage({ id: 'fileClaim.bankingModal.info.b3' })}
        </Typography>
      </Box>
    </Alert>
  );
}
