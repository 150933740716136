import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Box, Typography, LinearProgress, useMediaQuery } from '@mui/material';

function ContinuedClaimProgressTracker(props) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const intl = useIntl();
  return (
    <Box
      mt={2}
      mb={2}
      sx={{
        background: 'white',
        pt: 2,
        top: 0,
        position: 'sticky',
        zIndex: 99,
      }}
    >
      <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', px: 3 }}>
        <Typography variant="h6" fontWeight="bold" color="text.secondary">
          {props.title}
        </Typography>
        <Typography variant="h6" fontWeight="bold" color="text.secondary">{`${Math.round(props.value)}% ${
          isMobile ? '' : intl.formatMessage({ id: 'continuedClaim.progressBar.completed' })
        }`}</Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

ContinuedClaimProgressTracker.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default ContinuedClaimProgressTracker;
