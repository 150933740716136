import { createTheme, darken, lighten } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import vars from './vars.module.scss';

const primaryColor = '#007041';
const secondaryColor = '#0F3A60';
const infoColor = '#327ABD';
const errorColor = '#c10230';
const textColor = '#202124';
const actionColor = '#0056B8';

const defaultTheme = createTheme();

const theme = createTheme({
  typography: {
    fontFamily: vars.fontFamily,
    h1: {
      fontSize: 28,
      lineHeight: '36px',
      fontWeight: 700,
      color: textColor,
    },
    h2: {
      fontSize: 40,
      fontWeight: 600,
      color: textColor,
    },
    h3: {
      fontSize: 18,
      lineHeight: '27px',
      fontWeight: 400,
      color: grey['900'],
    },
    h4: {
      fontSize: 20,
      lineHeight: '27px',
      fontWeight: 700,
      color: grey['900'],
    },
    h5: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 700,
      color: grey['800'],
    },
    h6: {
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 400,
      color: textColor,
    },
    body2: {
      fontSize: 16,
      lineHeight: '24px',
      color: grey['800'],
    },
    caption: {
      fontSize: 12,
      color: grey['800'],
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: 14,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: vars.lightGray,
        },
        input: {
          padding: '17px 11px 9px',
          fontSize: 12,
          '@media (min-width: 640px)': {
            fontSize: 12,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: vars.mediumGray,
          },
          '&:hover:not(.Mui-focused):before': {
            borderBottomColor: vars.mediumGray,
          },
          '&:after': {
            // focused
            borderBottomColor: vars.actionBlue,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingRight: 0,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: '#007041',
          height: 11,
          width: 11,
        },
        track: {
          color: '#007041',
          height: 2,
        },
        rail: {
          color: '#007041',
          height: 2,
        },
        mark: {
          width: 2,
          height: 2,
        },
        markLabel: {
          fontSize: 14,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 16,
          width: '100%',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
        formControl: {
          //top: -5,
        },
        filled: {
          color: grey['800'],
          '&.Mui-focused': {
            color: vars.actionBlue,
          },
          '.MuiFormControl-root:hover &:not($focused)': {
            color: grey['800'],
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: vars.mediumGray,
          '&.Mui-focused': {
            color: grey['900'],
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: grey['900'],
          fontSize: 9,
          margin: 10,
          padding: '0',
          textAlign: 'right',
          position: 'absolute',
          top: '100%',
          right: 0,
        },
        contained: {
          margin: '0 10px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingRight: '0',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.75rem',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          borderRadius: 0,
          backgroundColor: vars.tagSkill,
          height: 22,
          position: 'relative',
          zIndex: 0,
        },
        inputRoot: {
          paddingTop: '10px !important;',
        },
        option: {
          fontSize: 14,
        },
        input: {
          fontSize: 16,
          '@media (min-width: 640px)': {
            fontSize: 12,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 12,
          '@media (min-width: 640px)': {
            fontSize: 12,
          },
          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      dark: darken(primaryColor, 0.05),
      light: lighten(primaryColor, 0.05),
    },
    secondary: {
      main: secondaryColor,
      dark: darken(secondaryColor, 0.15),
      light: lighten(secondaryColor, 0.05),
    },
    tertiary: defaultTheme.palette.augmentColor({
      color: { main: darken(secondaryColor, 0.1) },
      name: 'tertiary',
    }),
    error: {
      main: errorColor,
      dark: darken(errorColor, 0.05),
      light: lighten(errorColor, 0.05),
    },
    info: {
      main: infoColor,
      dark: darken(infoColor, 0.05),
      light: lighten(infoColor, 0.05),
    },
    background: {
      default: '#FFF',
    },
    action: {
      active: actionColor,
    },
    text: {
      primary: textColor,
      disabled: 'rgba(0, 0, 0, 0.55)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
    },
  },
});

export default theme;
