import { FormControl, FormHelperText, FormLabel, MenuItem, Select } from '@mui/material';
import { Field } from 'formik';
import PropTypes from 'prop-types';

export default function SelectField({ name, label, helperText, disabled, error, locale, options }) {
  return (
    <Field name={name}>
      {({ field }) => (
        <FormControl disabled={disabled} error={error}>
          <FormLabel htmlFor={field.name}>{label}</FormLabel>

          <Select
            id={field.name}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            inputProps={{ id: field.name }}
          >
            {options.map((option) => (
              <MenuItem sx={{ whiteSpace: 'normal' }} key={option.value} value={option.value}>
                {option.label[locale]}
              </MenuItem>
            ))}
          </Select>

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  locale: PropTypes.oneOf(['en', 'es']).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.shape({
        en: PropTypes.string,
        es: PropTypes.string,
      }),
    })
  ),
};
