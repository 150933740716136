import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';

/* i18n */
import { useIntl } from 'react-intl';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  Typography,
  Grid,
  Divider,
  Alert,
  TextField,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { handleKeyDown } from 'components/forms/DirectDepositEnrollForm';

import TimeMe from 'timeme.js';
import { LoadingButton } from '@mui/lab';

const UpdatePaymentConfirmation = () => {
  const [submitting, setSubmitting] = useState(false);

  let history = useHistory();
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'fileClaim.bankingModal.title' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('UI_FILE_A_CLAIM');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 6, mb: 2.5 }}>
        <Typography variant="h1">
          <CreditCardOutlinedIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
          {intl.formatMessage({ id: 'fileClaim.bankingModal.title' })}
        </Typography>
        <Typography variant="body2">{intl.formatMessage({ id: 'payments.dwsPaymentSystem' })}</Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <Alert severity="warning" sx={{ mb: 2.5 }}>
        <Typography variant="body2">{intl.formatMessage({ id: 'payments.paymentWarning.p1' })}</Typography>
        <Typography variant="body2">
          <b>{intl.formatMessage({ id: 'payments.paymentWarning.p2' })}</b>{' '}
          {intl.formatMessage({ id: 'payments.paymentWarning.p3' })}
        </Typography>
      </Alert>
      <Grid container>
        <Grid item xs={12} sm={9} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Formik
            enableReinitialize={true}
            validationSchema={yup.object({
              confirmation_number: yup
                .string(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' }))
                .required(intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' })),
            })}
            initialValues={{ confirmation_number: '' }}
            onSubmit={async ({ confirmation_number }) => {
              setSubmitting(true);
              setTimeout(() => {
                console.log('Sending number:', confirmation_number);
                history.push(`${urlPrefix}/payment/enroll`);
              }, 500);
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => {
              return (
                <form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
                  <Card variant="outlined" sx={{ mb: 2.5 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: 700 }}>
                          {intl.formatMessage({ id: 'payments.confirmationNumber' })}
                          <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                            *
                          </Typography>
                        </Typography>
                        <TextField
                          sx={{
                            '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                            '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                              display: 'none',
                            },
                          }}
                          error={touched.confirmation_number && Boolean(errors.confirmation_number)}
                          fullWidth
                          helperText={touched.confirmation_number && errors.confirmation_number}
                          name="confirmation_number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                          variant="filled"
                          value={values.confirmation_number ?? ''}
                        />
                      </Box>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end', pb: 1.5 }} id="osidfj">
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        disabled={!values.confirmation_number}
                        loading={submitting}
                        loadingPosition={submitting ? 'start' : undefined}
                        startIcon={submitting ? <SaveIcon /> : null}
                        sx={{ mr: 1 }}
                      >
                        {intl.formatMessage({ id: 'payments.verify' })}
                      </LoadingButton>
                    </CardActions>
                  </Card>
                </form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UpdatePaymentConfirmation;
