/*-- Routing --*/
import { Route, Switch, Redirect } from 'react-router-dom';

/* Redux */
import { useSelector } from 'react-redux';

/*-- Layout --*/
import RecommendationsHeader from 'components/RecommendationsHeader';

/*-- Pages --*/
import LoadingRecommendations from 'components/LoadingRecommendations';
import UIDashboard from 'components/UIDashboard';
import UILaws from 'components/UILaws';
import UIWorkSearchActivity from 'components/UIWorkSearchActivity';
import FileClaim from 'components/FileClaim';
import Jobs from 'components/Jobs';
import Careers from 'components/Careers';
import Training from 'components/Training';
import Profile from 'components/Profile';
import ExperienceWallet from 'components/ExperienceWallet';
import Ratings from 'components/Ratings';

import JobSearchActivity from 'components/JobSearchActivity';
import ContinuedClaim from 'components/ContinuedClaim';
import UpdateMailingAddress from 'components/UpdateMailingAddress';
import UpdatePaymentMethod from 'components/UpdatePaymentMethod';
import UpdatePaymentConfirmation from 'components/UpdatePaymentConfirmation';

const AuthenticatedPagesSwitch = () => {
  const { loadingRecommendations, user_settings, urlPrefix } = useSelector((state) => state.app);
  const worksearch_status = useSelector((state) => state.worksearch.status);
  const UIEnabled = process.env.REACT_APP_AR_UI === 'true';

  return loadingRecommendations ? (
    <LoadingRecommendations />
  ) : (
    <>
      <RecommendationsHeader />
      <Switch>
        {user_settings.add_job_search_activity_hero_button && UIEnabled && (
          <Route path={[`/ui-dashboard`, `/:lang/ui-dashboard`]}>
            <UIDashboard />
          </Route>
        )}
        {user_settings.add_job_search_activity_hero_button && UIEnabled && (
          <Route path={[`/ui-law-changes`, `/:lang/ui-law-changes`]}>
            <UILaws />
          </Route>
        )}
        {user_settings.add_job_search_activity_hero_button && UIEnabled && (
          <Route path={[`/ui-work-search-activity`, `/:lang/ui-work-search-activity`]}>
            <UIWorkSearchActivity />
          </Route>
        )}
        {user_settings.add_job_search_activity_hero_button && UIEnabled && (
          <Route path={[`/file-a-claim`, `/:lang/file-a-claim`]}>
            <FileClaim />
          </Route>
        )}

        {user_settings.add_job_search_activity_hero_button && UIEnabled && (
          <Route path={[`/continued-claim`, `/:lang/continued-claim`]}>
            <ContinuedClaim />
          </Route>
        )}
        {user_settings.add_job_search_activity_hero_button && UIEnabled && (
          <Route path={[`/mailing`, `/:lang/mailing`]}>
            <UpdateMailingAddress />
          </Route>
        )}
        {user_settings.add_job_search_activity_hero_button && UIEnabled && (
          <Route path={[`/payment/enroll`, `/:lang/payment/enroll`]}>
            <UpdatePaymentMethod />
          </Route>
        )}
        {user_settings.add_job_search_activity_hero_button && UIEnabled && (
          <Route path={[`/payment`, `/:lang/payment`]}>
            <UpdatePaymentConfirmation />
          </Route>
        )}
        <Route path={[`/jobs`, `/:lang/jobs`]}>
          <Jobs />
        </Route>
        <Route path={[`/careers`, `/:lang/careers`]}>
          <Careers />
        </Route>
        <Route path={[`/training`, `/:lang/training`]}>
          <Training />
        </Route>
        <Route path={[`/experience`, `/:lang/experience`]}>
          <ExperienceWallet />
        </Route>
        <Route path={['/job-search-activity', '/:lang/job-search-activity']}>
          {user_settings.add_job_search_activity_hero_button ? (
            <JobSearchActivity />
          ) : (
            <Redirect to={`${urlPrefix}/jobs`} />
          )}
        </Route>
        <Route path={[`/saved-items`, `/:lang/saved-items`]}>
          <Ratings />
        </Route>
        <Route path={[`/hidden-items`, `/:lang/hidden-items`]}>
          <Ratings />
        </Route>
        <Route path={[`/my-profile`, `/:lang/my-profile`]}>
          <Profile />
        </Route>
        <Route>
          <Redirect to={`${urlPrefix}/${worksearch_status.worksearch_enabled ? 'job-search-activity' : 'jobs'}`} />
        </Route>
      </Switch>
    </>
  );
};

export default AuthenticatedPagesSwitch;
