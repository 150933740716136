import { useEffect } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/slick-theme-overrides.css';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* API */
import { apiRequest } from 'shared/API';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import useMediaQuery from '@mui/material/useMediaQuery';

/* Assets */
import thumbsUp from 'assets/images/ThumbsUpBlue.svg';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setActivities, setStatus } from 'app/slices/workSearchSlice';
import {
  setActiveSoc,
  setActiveCareer,
  setCareerPathsFilterValues,
  setSkillsFilter as setJobsSkillsFilter,
  setEmployerFilter,
  setSalaryRangesFilter as setJobsSalaryRangesFilter,
  setLocationsFilterValues,
} from 'app/slices/jobsSlice';
import {
  setCareerPathsFilterValues as setTrainingsCareerFilter,
  setSkillsFilter as setTrainingsSkillsFilter,
  setProvidersFilter,
  setLocationsFilterValues as setTrainingsLocationsFilterValues,
} from 'app/slices/trainingSlice';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Container, Typography, Divider, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Slider from 'react-slick';

/* UI Components */
import { useSnackbar } from 'notistack';
import CareersCard from 'shared/src/components/CareersCard';
import JobsCard from 'shared/src/components/JobsCard';
import TrainingCard from 'shared/src/components/TrainingCard';

import { useInfiniteRatings, useRateItem } from 'shared/hooks';
import { CircularProgress } from '@mui/material';

const Ratings = () => {
  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);
  const isSmallScreen = useMediaQuery('(max-width:959px)');

  const location = useLocation();
  const activePath = location.pathname.split('/').reverse()[0];
  const isSavedPage = activePath === 'saved-items';
  const currentTab = isSavedPage ? 'up' : 'down';

  const dispatch = useDispatch();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { loadingLanguage } = useSelector((state) => state.app);
  const { tagsActive } = useSelector((state) => state.careers);
  const worksearch_status = useSelector((state) => state.worksearch.status);
  const worksearch_activities = useSelector((state) => state.worksearch.activities);

  const filters = { currentTab, limit: 2000 };

  const ratingsQuery = useInfiniteRatings(filters);
  const rateItem = useRateItem(filters);

  const responses = ratingsQuery.isSuccess ? ratingsQuery.data.pages.flat(1) : [];
  const careers = responses.filter((r) => r.type === 'career');
  const careersBy2 = [];
  while (careers.length) careersBy2.push(careers.splice(0, 2));

  const jobs = responses.filter((r) => r.type === 'job');
  const jobsBy3 = [];
  while (jobs.length) jobsBy3.push(jobs.splice(0, 3));

  const trainings = responses.filter((r) => r.type === 'training');
  const trainingsBy3 = [];
  while (trainings.length) trainingsBy3.push(trainings.splice(0, 3));

  const showJobs = (item) => {
    dispatch(setCareerPathsFilterValues([item?.soc]));
    const { soc: soc_code } = item;
    dispatch(setActiveSoc(soc_code));
    dispatch(setActiveCareer(item));
    dispatch(setJobsSkillsFilter([]));
    dispatch(setEmployerFilter([]));
    dispatch(setJobsSalaryRangesFilter([]));
    dispatch(setLocationsFilterValues([]));

    history.push(`${urlPrefix}/jobs`);
    if (window) {
      window.scrollTo({ top: 0 });
    }
  };

  const handleButtonClick = (item) => () => {
    const { type } = item;
    const actionDict = {
      career: 'RATINGS_CAREER_JOBS',
      job: 'RATINGS_JOB_APPLY',
      training: 'RATINGS_TRAINING_APPLY',
    };
    trackEvent(actionDict[type], item);
    // Show jobs when clicking on a careers' card
    if (type === 'career') {
      showJobs(item);
    }
  };

  const handleLikeClick = (item) => () => {
    const newRating = item.rating !== 0 ? 0 : 1;
    const { type } = item;

    rateItem.mutate(
      { item, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
      }
    );

    if (newRating !== 0) {
      const eventName = `RATINGS_${type === 'skill' ? 'SKILLS' : type.toUpperCase()}_LIKE`;
      trackEvent(eventName, item);
    }
  };

  const handleDislikeClick = (item) => () => {
    const newRating = item.rating === -1 ? 0 : -1;
    const { type } = item;
    rateItem.mutate(
      { item, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
      }
    );

    if (newRating !== 0) {
      const eventName = `RATINGS_${type === 'skill' ? 'SKILLS' : type.toUpperCase()}_DISLIKE`;
      trackEvent(eventName, item);
    }
  };

  const handleJobApplyClick = (item) => {
    const today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;

    trackEvent('JOBS_GLOBAL_APPLY', item);

    if (worksearch_status.worksearch_enabled) {
      return apiRequest('post', '/users/me/worksearch/', {
        data: {
          activity: {
            company_name: item.org,
            contact_phone_number: '0000000000',
            contact_email: '',
            position_applied_for: item.title,
            result_of_contact: 'Applied for Job through Arkansas LAUNCH',
            application_submission_format: 'online',
            activity_type: 'applied_for_job',
            activity_date: `${year}-${month}-${day}`,
            date_of_application: `${year}-${month}-${day}`,
            job_id: item.job_id,
            draft: true,
          },
        },
      })
        .then(({ activity, status }) => {
          dispatch(setActivities(worksearch_activities.concat(activity)));
          dispatch(setStatus(status));
          return '';
        })
        .catch(() => {
          return 'not_created';
        });
    }
  };

  const handleShowTrainingsClick = (item) => () => {
    dispatch(setTrainingsCareerFilter([item?.soc?.slice(0, 3)]));
    dispatch(setTrainingsSkillsFilter([]));
    dispatch(setProvidersFilter([]));
    dispatch(setLocationsFilterValues([]));
    dispatch(setTrainingsLocationsFilterValues([]));

    trackEvent('CAREERS_GLOBAL_SEETRAININGS', item);

    history.push(`${urlPrefix}/training`);
    if (window) {
      window.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    document.title = intl.formatMessage({ id: isSavedPage ? 'ratings.thumbsUpTitle' : 'ratings.thumbsDownTitle' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('RATINGS');
    TimeMe.startTimer();
  }, [activePath]);

  const getCareerCard = (item) => (
    <CareersCard
      item={item}
      key={item.id}
      rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
      onButtonClick={handleButtonClick(item)}
      onTrainingsButtonClick={handleShowTrainingsClick(item)}
      onLikeClick={handleLikeClick(item)}
      onDislikeClick={handleDislikeClick(item)}
      tagsActive={tagsActive}
      ratingsPage
    />
  );
  const getJobCard = (item) => (
    <JobsCard
      key={item.guid}
      item={item}
      rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
      onButtonClick={() => handleJobApplyClick(item)}
      onLikeClick={handleLikeClick(item)}
      onDislikeClick={handleDislikeClick(item)}
      ratingsPage
    />
  );
  const getTrainingCard = (item) => (
    <TrainingCard
      ratingsPage
      key={item.id}
      item={item}
      rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
      onButtonClick={() => {
        trackEvent('TRAINING_GLOBAL_APPLY', item);
      }}
      onLikeClick={handleLikeClick(item)}
      onDislikeClick={handleDislikeClick(item)}
    />
  );

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <Typography variant="h1">
              {intl.formatMessage({
                id: isSavedPage ? 'ratings.thumbsUpTitle' : 'ratings.thumbsDownTitle',
              })}
            </Typography>
            <Typography variant="body1" component="p" sx={{ a: { color: 'primary.dark' }, mb: { xs: 2, md: 0 } }}>
              <Link to={`${urlPrefix}/${isSavedPage ? 'hidden-items' : 'saved-items'}`}>
                {intl.formatMessage({ id: isSavedPage ? 'ratings.seeRemoved' : 'ratings.seeSaved' })}
              </Link>
            </Typography>
          </Box>
          <Box m={{ xs: 0, md: 4 }} />
          {loadingLanguage || ratingsQuery.isLoading ? (
            <>
              <Box m={3} />
              <Box m={2} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            </>
          ) : null}
          {/* Empty state */}
          {!loadingLanguage && responses.length === 0 && !ratingsQuery.isLoading && (
            <>
              <Box m={3} />
              <Box align="center" sx={{ maxWidth: 520, margin: '0 auto' }}>
                <Typography variant="body2" gutterBottom>
                  {intl.formatMessage({
                    id: currentTab === 'up' ? 'ratings.thumbsUpEmptyStateText1' : 'ratings.thumbsDownEmptyStateTitle',
                  })}
                </Typography>
                {currentTab === 'up' ? (
                  <Typography variant="body2" gutterBottom>
                    {intl.formatMessage(
                      {
                        id: 'ratings.thumbsUpEmptyStateText2',
                      },
                      {
                        icon: (
                          <img alt="Thumbs Up" src={thumbsUp} style={{ width: 20, position: 'relative', top: 1 }} />
                        ),
                      }
                    )}
                  </Typography>
                ) : null}
              </Box>
            </>
          )}
          {!loadingLanguage && careersBy2.length > 0 ? (
            <Box sx={{ mb: { xs: 6, md: 4 } }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: { xs: 'column-reverse', md: 'row' },
                }}
              >
                <Typography variant="h4" component="h2">
                  {intl.formatMessage({
                    id: isSavedPage ? 'ratings.savedCareers' : 'ratings.hiddenCareers',
                  })}
                </Typography>
              </Box>
              <Typography variant="h3" component="p">
                {intl.formatMessage(
                  {
                    id: careersBy2.flat().length === 1 ? 'ratings.resultsCountSingular' : 'ratings.resultsCount',
                  },
                  { n: careersBy2.flat().length }
                )}
              </Typography>

              <Box mt={2} mb={1}>
                {isSmallScreen ? null : <Divider />}
              </Box>
              {careersBy2.length === 1 && !isSmallScreen ? (
                <Box sx={{ py: 2.5 }}>
                  <Grid container spacing={2}>
                    {careersBy2.flat().map((item) => getCareerCard(item))}
                  </Grid>
                </Box>
              ) : careersBy2.flat().length === 1 ? (
                getCareerCard(careersBy2.flat()[0])
              ) : (
                <Box sx={{ px: { xs: 1.5, md: 0 } }}>
                  <Slider slidesToShow={1} slidesToScroll={1} infinite={false} dots>
                    {!isSmallScreen
                      ? careersBy2.map((arr) => (
                          <Box sx={{ py: 2.5 }} key={arr[0]?.id}>
                            <Grid container spacing={2}>
                              {arr.map((item) => getCareerCard(item))}
                            </Grid>
                          </Box>
                        ))
                      : careersBy2.flat().map((item) => getCareerCard(item))}
                  </Slider>
                </Box>
              )}
            </Box>
          ) : null}

          {!loadingLanguage && jobsBy3.length > 0 ? (
            <Box sx={{ mb: { xs: 6, md: 4 } }}>
              <Typography variant="h4" component="h2">
                {intl.formatMessage({
                  id: isSavedPage ? 'ratings.savedJobs' : 'ratings.hiddenJobs',
                })}
              </Typography>
              <Typography variant="h3" component="p">
                {intl.formatMessage(
                  {
                    id: jobsBy3.flat().length === 1 ? 'ratings.resultsCountSingular' : 'ratings.resultsCount',
                  },
                  { n: jobsBy3.flat().length }
                )}
              </Typography>

              <Box mt={2} mb={1}>
                {isSmallScreen ? null : <Divider />}
              </Box>
              {jobsBy3.length === 1 && !isSmallScreen ? (
                <Box sx={{ py: 2.5 }}>
                  <Grid container spacing={2}>
                    {jobsBy3.flat().map((item) => getJobCard(item))}
                  </Grid>
                </Box>
              ) : jobsBy3.flat().length === 1 ? (
                getJobCard(jobsBy3.flat()[0])
              ) : (
                <Box sx={{ px: { xs: 1.5, md: 0 } }}>
                  <Slider slidesToShow={1} slidesToScroll={1} infinite={false} dots>
                    {!isSmallScreen
                      ? jobsBy3.map((arr) => (
                          <Box sx={{ py: 2.5 }} key={arr[0]?.guid}>
                            <Grid container spacing={2}>
                              {arr.map((item) => getJobCard(item))}
                            </Grid>
                          </Box>
                        ))
                      : jobsBy3.flat().map((item) => getJobCard(item))}
                  </Slider>
                </Box>
              )}
            </Box>
          ) : null}

          {!loadingLanguage && trainingsBy3.length > 0 ? (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h4" component="h2">
                {intl.formatMessage({
                  id: isSavedPage ? 'ratings.savedTrainings' : 'ratings.hiddenTrainings',
                })}
              </Typography>
              <Typography variant="h3" component="p">
                {intl.formatMessage(
                  {
                    id: trainingsBy3.flat().length === 1 ? 'ratings.resultsCountSingular' : 'ratings.resultsCount',
                  },
                  { n: trainingsBy3.flat().length }
                )}
              </Typography>

              <Box mt={2} mb={1}>
                {isSmallScreen ? null : <Divider />}
              </Box>
              {trainingsBy3.length === 1 && !isSmallScreen ? (
                <Box sx={{ py: 2.5 }}>
                  <Grid container spacing={2}>
                    {trainingsBy3.flat().map((item) => getTrainingCard(item))}
                  </Grid>
                </Box>
              ) : trainingsBy3.flat().length === 1 ? (
                getTrainingCard(trainingsBy3.flat()[0])
              ) : (
                <Box sx={{ px: { xs: 1.5, md: 0 } }}>
                  <Slider slidesToShow={1} slidesToScroll={1} infinite={false} dots>
                    {!isSmallScreen
                      ? trainingsBy3.map((arr) => (
                          <Box sx={{ py: 2.5 }} key={arr[0]?.id}>
                            <Grid container spacing={2}>
                              {arr.map((item) => getTrainingCard(item))}
                            </Grid>
                          </Box>
                        ))
                      : trainingsBy3.flat().map((item) => getTrainingCard(item))}
                  </Slider>
                </Box>
              )}
            </Box>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default Ratings;
