import { useState } from 'react';
import Joyride from 'react-joyride';
import { Typography } from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setNewUser, setForceMobileMenuOpen } from 'app/slices/appSlice';

export default function NewUserTutorialMobile() {
  const [currentStep, setCurrentStep] = useState(0);

  const intl = useIntl();
  const dispatch = useDispatch();
  const preferredName = useSelector((state) => state.app.preferredName);
  const stepLocale = {
    skip: intl.formatMessage({ id: 'newUserTutorial.skip' }),
    next: intl.formatMessage({ id: 'forms.onboarding.next' }),
    back: intl.formatMessage({ id: 'forms.onboarding.back' }),
    last: intl.formatMessage({ id: 'newUserTutorial.getStarted' }),
  };
  const steps = [
    {
      target: 'body',
      content: (
        <Typography sx={{ textAlign: 'left' }}>
          {intl.formatMessage({ id: 'newUserTutorial.step1' }, { name: preferredName })}
        </Typography>
      ),
      placement: 'center',
    },
    {
      target: '#experience-mobile-menu-item',
      content: (
        <Typography sx={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'newUserTutorial.step2' })}</Typography>
      ),
    },
    {
      target: '#jobs-mobile-menu-item',
      content: (
        <Typography sx={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'newUserTutorial.step4' })}</Typography>
      ),
    },
    {
      target: '#careers-mobile-menu-item',
      content: (
        <Typography sx={{ textAlign: 'left' }}>
          {intl.formatMessage(
            { id: 'newUserTutorial.step3' },
            { link: <b>{intl.formatMessage({ id: 'profile.title' })}</b> }
          )}
        </Typography>
      ),
      placement: 'top',
    },
    {
      target: '#training-mobile-menu-item',
      content: (
        <Typography sx={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'newUserTutorial.step5' })}</Typography>
      ),
    },
  ];

  return (
    <Joyride
      locale={stepLocale}
      steps={steps}
      stepIndex={currentStep}
      showSkipButton
      continuous
      hideCloseButton
      styles={{ options: { primaryColor: '#007041' } }}
      callback={({ status, index, action, type }) => {
        if (type === 'step:before') {
          return;
        }

        const needsToOpen = [0, 1, 2, 3].includes(index);
        if (action === 'next' && needsToOpen) {
          dispatch(setForceMobileMenuOpen(true));
          setCurrentStep(currentStep + 1);
        } else if (action === 'next' && !needsToOpen) {
          dispatch(setForceMobileMenuOpen(false));
          setCurrentStep(currentStep + 1);
        }
        if (['skipped', 'ready', 'finished'].includes(status) || action === 'close') {
          dispatch(setNewUser(false));
          dispatch(setForceMobileMenuOpen(false));
          if (window) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
      }}
      debug
    />
  );
}
