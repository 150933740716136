import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  lighten,
  Typography,
} from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';
import { grey } from '@mui/material/colors';

/* Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function DepositInfoCard({
  setShowEnroll,
  setPaymentInfoData,
  paymentInfoData: { account_type, routing_number, account_number },
}) {
  const intl = useIntl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const headerContent = (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex' }}>
        <CheckCircleIcon sx={{ color: 'success.main', fontSize: 22 }} />{' '}
        <Typography sx={{ color: 'success.main', fontWeight: 700, pl: 0.5 }}>
          {intl.formatMessage({
            id: 'fileClaim.bankingModal.currentMethod',
          })}
        </Typography>
      </Box>

      <Button size="small" onClick={() => setDeleteDialogOpen(true)}>
        {intl.formatMessage({ id: 'experience.remove' })}
      </Button>
    </Box>
  );

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          borderStyle: 'solid',
          borderColor: grey['300'],
          height: '100%',
        }}
      >
        <CardHeader
          title={headerContent}
          sx={{ py: 1, bgcolor: (theme) => lighten(theme.palette.success.main, 0.95) }}
        />
        <CardContent sx={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: grey['300'] }}>
          <Typography variant="h3" sx={{ textAlign: 'center', mb: 1.5 }}>
            {intl.formatMessage({ id: 'fileClaim.bankingModal.directDeposit' })}
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
            {intl.formatMessage({ id: 'fileClaim.bankingForm.accountType' })}:{' '}
            <Typography component="span" sx={{ fontWeight: 700 }}>
              {account_type}
            </Typography>
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
            {intl.formatMessage({ id: 'fileClaim.bankingForm.routingNumber' })}:{' '}
            <Typography component="span" sx={{ fontWeight: 700 }}>
              {routing_number}
            </Typography>
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
            {intl.formatMessage({ id: 'fileClaim.bankingForm.accountNumber' })}:{' '}
            <Typography component="span" sx={{ fontWeight: 700 }}>
              *{account_number.slice(-4)}
            </Typography>
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button onClick={() => setShowEnroll(true)} variant="contained">
              {intl.formatMessage({ id: 'fileClaim.bankingForm.changeBank' })}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={deleteDialogOpen} onClose={() => {}}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {intl.formatMessage({ id: 'experience.row.delete' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{intl.formatMessage({ id: 'fileClaim.deleteDialog.text' })}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={() => setDeleteDialogOpen(false)}
            id="delete-go-back-payment-method-button"
          >
            {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.cancel' })}
          </Button>
          <Button
            variant="contained"
            id="delete-yes-experience-button"
            onClick={() => {
              setPaymentInfoData({ current_payment: 'DB' });
            }}
          >
            {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.continue' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DepositInfoCard.propTypes = {
  paymentInfoData: PropTypes.shape({
    account_number: PropTypes.string,
    routing_number: PropTypes.number,
    account_type: PropTypes.string,
    current_payment: PropTypes.string,
  }),
  setShowEnroll: PropTypes.func,
  setPaymentInfoData: PropTypes.func,
};
