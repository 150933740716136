import * as Yup from 'yup';

const q01ValidationSchema = Yup.object().shape({
  q01_01: Yup.string().required('Required'),
  q01_02: Yup.string().required('Required'),
  q01_03: Yup.string().required('Required'),
  q01_04: Yup.string().when('q01_03', {
    is: (value) => value === 'Fired' || value === 'Quit' || value === 'Other',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  q01_05: Yup.string().required('Required'),
  q01_06: Yup.string().required('Required'),
});

const q02ValidationSchema = Yup.object().shape({
  q02_07: Yup.string().required('Required'),
  q02_08: Yup.string().required('Required'),
  q02_09: Yup.string().required('Required'),
});

const q03ValidationSchema = Yup.object().shape({
  q03_10: Yup.string().required('Required'),
  q03_11: Yup.string().required('Required'),
  q03_12: Yup.string().required('Required'),
});

const q04ValidationSchema = Yup.object().shape({
  q04_13: Yup.string().required('Required'),
  q04_14: Yup.string().required('Required'),
  q04_15: Yup.string().required('Required'),
});

const q05ValidationSchema = Yup.object().shape({
  q05_16: Yup.string().required('Required'),
  q05_17: Yup.string().required('Required'),
  q05_18: Yup.string().required('Required'),
});

const q06ValidationSchema = Yup.object().shape({
  q06_19: Yup.string().required('Required'),
  q06_20: Yup.string().required('Required'),
  q06_21: Yup.string().required('Required'),
});

const q07ValidationSchema = Yup.object().shape({
  q07_22: Yup.string().required('Required'),
  q07_23: Yup.string().required('Required'),
});

const q08ValidationSchema = Yup.object().shape({
  q08_24: Yup.string().required('Required'),
  q08_25: Yup.string().required('Required'),
  q08_26: Yup.string().required('Required'),
});

const q16ValidationSchema = Yup.object().shape({
  q16_40: Yup.string().required('Required'),
  q16_41: Yup.string().required('Required'),
});

export {
  q01ValidationSchema,
  q02ValidationSchema,
  q03ValidationSchema,
  q04ValidationSchema,
  q05ValidationSchema,
  q06ValidationSchema,
  q07ValidationSchema,
  q08ValidationSchema,
  q16ValidationSchema,
};
