import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Field } from 'formik';
import PropTypes from 'prop-types';

export default function YesNoRadioGroupField({
  name,
  label,
  helperText,
  disabled,
  error,
  locale,
  horizontalLayoutEnabled,
  handleAddCompletedQuestion,
  isLoading,
  optionReportingSectionAsCompleted,
}) {
  const calculateIfSectionShouldBeMarkedAsCompleted = (value) => {
    if (optionReportingSectionAsCompleted) {
      if (optionReportingSectionAsCompleted === 'all') return true;
      return optionReportingSectionAsCompleted === value;
    }

    return value === 'no';
  };

  return (
    <Field name={name}>
      {({ field }) => (
        <FormControl
          disabled={disabled}
          error={error}
          sx={{
            ...(horizontalLayoutEnabled && {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }),
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {horizontalLayoutEnabled && (
              <Box
                sx={{
                  width: '24px',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  <ChevronRight color={disabled ? 'disabled' : 'inherit'} sx={{ fontSize: 24 }} />
                )}
              </Box>
            )}

            <FormLabel id={field.name} component="legend">
              {label}
            </FormLabel>
          </Box>

          <RadioGroup
            aria-labelledby={field.name}
            name={field.name}
            value={field.value}
            onChange={(event) => {
              field.onChange(event);
              if (handleAddCompletedQuestion) {
                const sectionId = field.name.split('_')[0];
                handleAddCompletedQuestion(
                  {
                    [field.name]: event.target.value,
                  },
                  calculateIfSectionShouldBeMarkedAsCompleted(event.target.value) && sectionId
                );
              }
            }}
            row
            sx={{ flexWrap: 'nowrap' }}
          >
            <FormControlLabel value={'yes'} control={<Radio />} label={locale === 'es' ? 'Si' : 'Yes'} />
            <FormControlLabel value={'no'} control={<Radio />} label={'No'} />
          </RadioGroup>

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
}

YesNoRadioGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  locale: PropTypes.oneOf(['en', 'es']).isRequired,
  horizontalLayoutEnabled: PropTypes.bool,
  handleAddCompletedQuestion: PropTypes.func,
  optionReportingSectionAsCompleted: PropTypes.oneOf(['yes', 'no', 'all']),
};
