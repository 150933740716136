import { useEffect } from 'react';

/* Styles */
import styles from 'styles/recommendations-list.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Box, Container } from '@mui/material';
import CloseTabButton from 'components/CloseTabButton';

const TermsOfUseES = () => {
  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <>
      <div className={styles.content}>
        <Container maxWidth="lg">
          <CloseTabButton className={layoutStyles.closeButton} />
          <Typography variant="h1" gutterBottom>
            Términos de Uso y Política de Privacidad
          </Typography>
          <hr className={layoutStyles.separator} />
          <Box m={4} />
          <Typography variant="body2" component="p" gutterBottom>
            Bienvenido a Arkansas LAUNCH para Solicitantes de empleo.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Estamos tomando todas las precauciones, como esforzarnos al máximo por cumplir todas las leyes y normativas
            estatales y federales pertinentes relacionadas con la privacidad, para garantizar que su información esté lo
            más segura posible.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Estos Términos de Uso (estos &quot;<u>Términos</u>&quot;) son publicados por el Departamento de
            Transformación y Servicios Compartidos de Arkansas (&quot;<u>ADTSS</u>&quot;, &quot;<u>nosotros</u>&quot;,
            &quot;<u>nos</u>&quot;, o &quot;<u>nuestro(a)(s)</u>&quot;) y regulan su uso de LAUNCH para solicitantes de
            empleo.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al visitar y utilizar este sitio web, sus subpáginas y contenidos (conocidos colectivamente como este sitio
            web), usted acepta los Términos de Uso y la Política de Privacidad.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Elegibilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Al utilizar este sitio web, usted garantiza que es mayor de 14 años y que tiene autoridad legal para aceptar
            estos Términos de Uso. Se prohíbe terminantemente el uso de este sitio web a los participantes menores de 13
            años.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Cumplimiento de las leyes laborales estatales y federales
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Debe cumplir todas las leyes estatales y federales pertinentes, incluidas, entre otras, las leyes de
            Arkansas sobre trabajo infantil, que regulan las condiciones y el horario de trabajo de los menores. Si bien
            los menores de entre 14 y 17 años pueden trabajar bajo ciertas condiciones y limitaciones, para comprender
            mejor sus derechos y obligaciones, consulte las reglas, regulaciones y requisitos aplicables establecidos en
            la Ley de Normas Laborales Justas (FLSA) en{' '}
            <Typography
              component="a"
              target="_blank"
              href="https://www.dol.gov/agencies/whd/youthrules"
              rel="noopener noreferrer"
              sx={{
                color: (theme) => theme.palette.info.main,
                fontWeight: 700,
                wordBreak: 'break-all',
              }}
            >
              https://www.dol.gov/agencies/whd/youthrules
            </Typography>{' '}
            y las leyes laborales del estado de Arkansas en{' '}
            <Typography
              component="a"
              target="_blank"
              href="https://www.labor.arkansas.gov/labor/labor-standards/child-labor/"
              rel="noopener noreferrer"
              sx={{
                color: (theme) => theme.palette.info.main,
                fontWeight: 700,
                wordBreak: 'break-all',
              }}
            >
              https://www.labor.arkansas.gov/labor/labor-standards/child-labor/
            </Typography>
            . No hacemos declaraciones, garantías ni acuerdos con respecto a las decisiones que usted tome en relación
            con el empleo o el trabajo. Nada de lo expuesto aquí debe interpretarse como un estímulo o respaldo a su
            trabajo como menor de edad.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Uso de la Información
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            El Departamento de Transformación y Servicios Compartidos de Arkansas y sus agentes, contratistas y socios
            utilizarán los registros del Estado de Arkansas y la información que usted proporcione cuando se registre en
            LAUNCH para solicitantes de empleo para conectarlo con oportunidades personalizadas de empleo y
            capacitación.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            También podemos utilizar y compartir la información proporcionada según sea razonablemente necesario para
            cumplir con la ley o con un proceso legal (incluida una orden o citación judicial o gubernamental); para
            detectar, prevenir o abordar de otro modo el fraude, la seguridad o los problemas técnicos; para hacer
            cumplir los Términos de Uso y la Política de Privacidad; para proteger los derechos, la propiedad o la
            seguridad del Estado de Arkansas, de nuestros usuarios y del público; o de otro modo con su consentimiento
            expreso.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al inscribirse en LAUNCH para solicitantes de empleo, acepta que el Departamento y sus agentes, contratistas
            y socios utilicen la información anterior, así como cualquier dato asociado que haya puesto a disposición
            del Departamento, para crear recomendaciones personalizadas de empleo y oportunidades profesionales para
            usted. Asimismo, acepta que el Departamento y sus agentes, contratistas y socios se pongan en contacto con
            usted o le envíen comunicados que pueden incluir, entre otras cosas, información importante sobre
            oportunidades de contratación laboral, información sobre puestos de trabajo disponibles, oportunidades de
            capacitación profesional, oportunidades de educación y otros recursos y oportunidades relacionados con sus
            habilidades.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Al proporcionar su número de teléfono celular, usted acepta específicamente que el Departamento le envíe
            comunicados a su teléfono celular que pueden incluir información importante sobre programas de seguro de
            desempleo, oportunidades de contratación laboral que pueden ser adecuadas para usted, información sobre
            empleos disponibles que pueden ser adecuados para usted y oportunidades de capacitación en habilidades
            disponibles.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Puede optar por permitir que el Departamento comparta su información de contacto directamente con
            empleadores y reclutadores que puedan tener interés en contratarle en base a sus habilidades, intereses y
            experiencia. Si elige este servicio, acepta que el Departamento y sus agentes, contratistas y socios
            compartan su información de contacto, historial de empleo y formación, y una recomendación sobre su
            idoneidad para un puesto de trabajo o programa de formación directamente con empleadores y agencias de
            contratación, colocación y gubernamentales de Arkansas con el único fin de ponerse en contacto con usted
            sobre oportunidades de contratación o capacitación que puedan ser adecuadas para sus habilidades y
            antecedentes.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Aviso de Seguridad y Monitoreo
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Si navega por este sitio web para leer, imprimir o descargar información, es posible que se recopile
            información de navegación.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Por motivos de seguridad del sitio, todo el tráfico de la red se controla con el fin de identificar intentos
            no autorizados de cargar o modificar información, o de realizar actividades criminales. La información de
            navegación puede utilizarse para ayudar a obtener información personal en el marco de investigaciones
            policiales autorizadas y de procesos legales.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, para proteger este sitio web, así como el sistema informático asociado que lo soporta, de usos no
            autorizados y para garantizar que el sistema informático funciona correctamente, las personas que accedan a
            este sitio web y al sistema informático asociado están sujetas a que todas sus actividades sean monitoreadas
            y grabadas por personal autorizado para ello por el Estado de Arkansas. Cualquier persona que utilice este
            sitio web y el sistema informático asociado consiente expresamente dicho monitoreo y se le advierte de que
            si dicho monitoreo revela evidencia de un posible abuso o actividad criminal, el personal del Estado de
            Arkansas puede proporcionar los resultados de dicho monitoreo a los funcionarios apropiados. Los intentos no
            autorizados de cargar o cambiar el contenido del sitio web, o de causar daños de cualquier otra forma a este
            sitio web o al sistema informático están estrictamente prohibidos y pueden ser sancionados por la ley
            aplicable.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Modificaciones sin previo aviso
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web es dinámico y puede cambiar con el tiempo sin previo aviso. Por lo tanto, los usuarios de
            este sitio web son plenamente responsables de comprobar por sí mismos la exactitud, integridad, actualidad e
            idoneidad de los contenidos encontrados en el sitio web.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, nos reservamos el derecho a modificar los Términos de Uso y la Política de Privacidad en cualquier
            momento. Le recomendamos que revise periódicamente los Términos de Uso y la Política de Privacidad. El uso
            continuo de LAUNCH para solicitantes de empleo después de cualquier modificación del sitio web y de los
            Términos de Uso y Política de Privacidad constituye su aceptación de dichas modificaciones.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Exclusión de garantías
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web se proporciona “TAL CUAL” y sin garantías de ningún tipo. En la máxima medida permitida por
            la ley, el Estado de Arkansas, incluida cada agencia, funcionario o empleado del Estado de Arkansas,
            renuncia a todas las garantías, expresas o implícitas, incluidas, entre las que se incluyen, las garantías
            de comerciabilidad e idoneidad para un propósito particular, con respecto a este sitio web; esta renuncia de
            garantías incluye, entre otras, las renuncias con respecto a lo siguiente: (1) que el funcionamiento de este
            sitio web no sufrirá interrupciones ni contendrá errores; (2) que se corregirá cualquier defecto u otro
            error de este sitio web; y (3) que este sitio web, así como el sistema informático, están o estarán libres
            de virus u otros componentes dañinos.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Además, ni el Estado de Arkansas ni ninguna agencia, funcionario o empleado del Estado de Arkansas hace
            ninguna representación, garantía o promesa en cuanto a: (1) la exactitud, integridad, vigencia o idoneidad
            de la información proporcionada a través de este sitio web; (2) el uso o los resultados del uso de este
            sitio web; y (3) la exactitud, confiabilidad, disponibilidad o integridad o utilidad del contenido de los
            sitios web creados y gestionados por personas ajenas al Estado de Arkansas y vinculados a o desde este sitio
            web.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Limitación de responsabilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Bajo ninguna circunstancia, incluida, entre otras, la negligencia, el Estado de Arkansas ni ninguna agencia,
            funcionario o empleado del Estado de Arkansas será responsable de ningún daño especial, consecuente y/o
            incidental que pueda surgir del uso o la incapacidad de acceder o usar este sitio web, incluso si el Estado
            de Arkansas ha sido advertido de la posibilidad de tales daños. Es posible que la ley aplicable no permita
            la limitación o exclusión de responsabilidad o daños incidentales o consecuentes, de modo que es posible que
            la limitación o exclusión anterior no se aplique en su caso. En ningún caso la responsabilidad total del
            Estado de Arkansas ante usted por todos y cada uno de los daños, pérdidas y causas de acción (ya sea por
            contrato, agravio (incluida, entre otras, la negligencia, o de otro modo)) excederá la cantidad que usted
            pagó, si fuere el caso, para acceder a este sitio web.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            ESTA LIMITACIÓN DE RESPONSABILIDAD SEGUIRÁ VIGENTE TRAS LA RESOLUCIÓN DEL PRESENTE CONTRATO. LA LIMITACIÓN
            DE RESPONSABILIDAD MENCIONADA ANTERIORMENTE SE APLICARÁ A TODAS LAS PARTES QUE ACTÚEN EN NOMBRE DE ADTSS,
            INCLUIDOS SUS EMPLEADOS, AGENTES Y CONTRATISTAS.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Aviso de Derechos de Autor
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Queda prohibida la reproducción o utilización de cualquier contenido de este sitio web con fines comerciales
            o de cualquier forma que pueda generar la impresión de aprobación oficial por parte del Estado de Arkansas.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Enlaces a sitios y páginas web externos
          </Typography>
          <Typography variant="body2" component="div" gutterBottom>
            <p>
              Este sitio web puede contener enlaces de hipertexto a sitios y páginas externas que contienen información
              creada y gestionada por organizaciones públicas y privadas distintas del Estado de Arkansas. Estos enlaces
              de hipertexto pueden ser creados por el Estado de Arkansas si determina que el establecimiento del enlace
              externo concuerda con la ayuda o el fomento del propósito de este sitio web, que es promover de forma
              significativa y diligente el servicio público a los ciudadanos y a las empresas mediante:
            </p>
            <ul>
              <li>
                la ampliación del acceso de las empresas y los ciudadanos a los servicios e información gubernamentales;
              </li>
              <li>
                el ofrecimiento de un proceso fácil y cómodo para que las empresas y los ciudadanos realicen
                transacciones en línea con el gobierno del estado;
              </li>
              <li>
                la aceleración del desarrollo y la prestación de un mayor volumen de servicios públicos en línea de
                calidad;
              </li>
              <li>la mejora del nivel de atención al cliente por parte del gobierno del estado; y </li>
              <li>
                la ampliación de los servicios electrónicos gubernamentales a las personas de las ciudades y los
                condados.
              </li>
            </ul>
            <p>
              Además, el Estado de Arkansas puede crear enlaces de hipertexto con fines informativos, es decir, cuando
              el sitio web externo enlazado proporcione información útil y valiosa a los visitantes de este sitio web, o
              cuando el sitio web externo enlazado esté obligado o autorizado por ley.
            </p>
            <p>
              El Estado de Arkansas, a su entera discreción, determinará si el sitio web externo se ajusta a la
              finalidad de este sitio web o a los fines informativos especificados. La inclusión de un enlace de
              hipertexto a un sitio web externo no supone la aprobación de ningún producto o servicio ofrecido o
              mencionado en el sitio web enlazado, de las organizaciones que patrocinan dicho sitio web ni de ninguna
              opinión que pueda expresarse o mencionarse en el sitio web.
            </p>
            <p>
              Estos enlaces de hipertexto a sitios y páginas externas pueden ser eliminados o sustituidos a discreción
              del Estado de Arkansas, en cualquier momento y sin previo aviso.
            </p>
            <p>
              En caso de que tenga problemas o tenga dudas sobre el formato, la exactitud, la actualidad o la integridad
              de un sitio externo enlazado, póngase en contacto con la organización responsable del sitio externo
              enlazado - el Estado de Arkansas no controla ni es responsable de ningún sitio y página externos
              enlazados.
            </p>
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Descargo de responsabilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Cualquier referencia en este sitio web a productos, procesos o servicios comerciales específicos por su
            nombre comercial, marca registrada, fabricante u otro, no constituye ni implica su aprobación, recomendación
            o preferencia por parte del Estado de Arkansas.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Jurisdicción
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Toda persona que decida acceder y utilizar este sitio web, se somete a la jurisdicción del Estado de
            Arkansas.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Estos términos se regirán e interpretarán de acuerdo con las leyes del Estado de Arkansas. Cualquier disputa
            que surja de o en relación con estos términos, o el acceso y uso de este sitio web se decidirá bajo las
            leyes de y en los tribunales estatales en el Estado de Arkansas, y por la presente usted consiente y se
            somete a la jurisdicción personal de dichos tribunales con el fin de adjudicar dicha disputa.
          </Typography>
          <Typography variant="body2" component="p" gutterBottom>
            Nada de lo dispuesto en los presentes Términos de Uso y Política de Privacidad se interpretará como una
            renuncia a la Inmunidad Soberana del ESTADO DE ARKANSAS o de cualquiera de sus entidades.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Otros sitios web
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Este sitio web puede contener enlaces de hipertexto a otros sitios y páginas web que contienen información
            creada y gestionada por otras organizaciones públicas y privadas. Si decide visitar estos sitios enlazados,
            debe revisar las declaraciones de privacidad de estos otros sitios web. El Estado de Arkansas no se hace
            responsable del contenido de estos otros sitios web.
          </Typography>
          <Box m={3} />
          <Typography variant="h4" component="h2" gutterBottom>
            Divisibilidad
          </Typography>
          <Box m={3} />
          <Typography variant="body2" component="p" gutterBottom>
            Si un juzgado con jurisdicción competente determina que alguna de las disposiciones de los Términos de Uso y
            Política de Privacidad mencionados anteriormente es ilegal, nula o inaplicable, dicha disposición se
            considerará separable de los términos restantes y no afectará a la validez y aplicabilidad de las
            disposiciones restantes.
          </Typography>
          <Box m={3} />
        </Container>
      </div>
    </>
  );
};

export default TermsOfUseES;
