/* Material UI and other UI Dependencies */
import { CircularProgress, Box } from '@mui/material';

export default function Loading() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 12,
        mx: 2,
      }}
    >
      <CircularProgress size={120} thickness={4} />
    </Box>
  );
}

export const LoadingSmall = () => {
  return (
    <Box m={2} display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};
