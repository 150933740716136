import { useEffect, useState } from 'react';
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Styles */
import styles from 'styles/layout.module.scss';
import drawerStyles from 'styles/drawer.module.scss';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setLocale, setLoadingLanguage, setLoading } from 'app/slices/appSlice';

/* API */
import { useAuth0 } from '@auth0/auth0-react';
import { updateExperience } from 'shared/src/lib/API/experience';

/* Assets */
import cssVars from 'styles/vars.module.scss';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Material UI and other UI Dependencies */
import { Typography, lighten, Stack, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Notifications from 'components/Notifications';

// Mui v5
import { Button, Slide, Badge } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import useMediaQuery from '@mui/material/useMediaQuery';
import HamburgerMenu from 'react-hamburger-menu';

import useNotifications from 'lib/hooks/useNotifications';

/* Colors */
import colors from 'styles/vars.module.scss';
import { useQueryClient } from '@tanstack/react-query';
import { monthDay } from 'components/Notifications';
import { grey } from '@mui/material/colors';

const HeaderButtonDark = (props) => {
  return (
    <Button
      sx={{
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '4px 16px',
        border: '1px solid',
        lineHeight: 1.5,
        color: cssVars.black,
        backgroundColor: 'transparent',
        borderColor: cssVars.black,
        fontFamily: ['-apple-system', 'Avenir', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        '&:active': {
          boxShadow: 'none',
          backgroundColor: lighten(cssVars.black, 0.05),
          borderColor: lighten(cssVars.black, 0.05),
        },
      }}
      {...props}
    />
  );
};

const useStyles = makeStyles(() => ({
  headerLink: {
    textDecoration: 'none',
    paddingLeft: 30,
    color: 'white',
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const environment = process.env.REACT_APP_ENVIRONMENT;

const Header = ({ locale, serverUserLocale, loggedIn, signOut }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hamOpen, setHamOpen] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const showHamburger = useMediaQuery('(max-width:1279px)');
  const queryClient = useQueryClient();
  const { palette } = useTheme();
  const UIEnabled = process.env.REACT_APP_AR_UI === 'true';

  const [incompleteWeeks, likedSkills, showProfileNotification] = useNotifications();
  const pendingSkills = likedSkills.length < 10 ? 1 : 0;
  const total = incompleteWeeks.length + pendingSkills + showProfileNotification;

  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);

  const location = useLocation();
  const isLandingPage = location.pathname === '/' || location.pathname === `/${intl.locale}/`;
  const { preferredName, needsOnboarding, forceMobileMenuOpen, user_settings, loading, loadingLanguage } = useSelector(
    (state) => state.app
  );
  const worksearch_status = useSelector((state) => state.worksearch.status);
  const [showTodos, setShowTodos] = useState(false);
  const [desktopMenuAnchorEl, setDesktopMenuAnchorEl] = React.useState(null);
  const open = Boolean(desktopMenuAnchorEl);
  const handleDesktopProfileClick = (event) => {
    setDesktopMenuAnchorEl(event.currentTarget);
  };
  const handleDesktopMenuClose = () => {
    setDesktopMenuAnchorEl(null);
  };

  // TODO: Let's do this better, I'm disabling it for now because it is causing perfomance issues
  // const { data, isSuccess, refetch } = useInfiniteRatings({ limit: 2000 });
  // const allSaved = isSuccess ? data.pages.flat(1).filter((r) => r.rating === 1) : [];
  // useEffect(() => {
  //   refetch();
  // });

  if (cssVars.headerLogoHref) {
    cssVars.headerLogoHref = cssVars.headerLogoHref.replace(/['"]+/g, '');
  }

  const handleLogin = () => {
    dispatch(setLoading(true));
    trackEvent(`LOGIN_AUTH0`);
    loginWithRedirect({
      ...(environment === 'prod' && { connection: 'Login-gov' }),
      ...(environment === 'uat' && { connection: 'Login-Gov-Test' }),
      prompt: 'login',
    });
  };

  const languageEventDict = {
    en: 'ENGLISH',
    es: 'SPANISH',
  };

  const resetLanguage = async (language) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('lng', language);
      trackEvent(`HEADER_${languageEventDict[language]}`);
      const newPath =
        language === 'en'
          ? `${location.pathname.substring(1 + intl.locale.length)}`
          : intl.locale === 'en'
          ? `/${language}${location.pathname}`
          : `/${language}${location.pathname.substring(1 + intl.locale.length)}`;
      dispatch(setLocale(language));
      dispatch(setLoadingLanguage(true));
      window.location.href = newPath;
      if (isAuthenticated) {
        await updateExperience({ language }).catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
      }
      queryClient.resetQueries({
        predicate: (query) => {
          if (newPath.includes('contact-info')) {
            return false;
          }
          return newPath.includes('onboarding') ? query.queryKey[0] !== '/users/me/experience/' : true;
        },
      });
      dispatch(setLoadingLanguage(false));
    }
  };

  useEffect(() => {
    if (!loadingLanguage && serverUserLocale && locale !== serverUserLocale) {
      resetLanguage(serverUserLocale);
    }
  }, [locale, serverUserLocale, loadingLanguage]);

  const handleSignOut = () => {
    const eventName = 'HEADER_SIGNOUT';
    trackEvent(eventName);
    signOut();
  };

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          py: '10px',
          px: 2,
          textAlign: 'left',
          bgcolor: lighten(palette.primary.main, 0.9),
          color: grey['900'],
          fontSize: 14,
        }}
      >
        <Container maxWidth="lg">{intl.formatMessage({ id: 'layout.footer.officialWebsite' })}</Container>
      </Box>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          height: {
            xs: loggedIn ? 60 : 74,
            lg: loggedIn ? 90 : 74,
          },
          position: {
            xs: hamOpen || forceMobileMenuOpen ? 'fixed' : 'static',
            lg: loggedIn ? 'sticky' : 'static',
          },
          top: {
            xs: hamOpen || forceMobileMenuOpen ? 0 : 'auto',
            lg: loggedIn ? 0 : 'auto',
          },
          zIndex: {
            xs: hamOpen || forceMobileMenuOpen ? 3 : 'auto',
            lg: loggedIn ? 10 : 'auto',
          },
          width: {
            xs: hamOpen || forceMobileMenuOpen ? '100%' : 'auto',
            lg: 'auto',
          },
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box>
            <ReactLink
              to="/"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                textDecoration: 'none',
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                  top: 5,
                }}
              >
                <img
                  className={cn({
                    [styles.headerLogo]: true,
                  })}
                  src="/images/AR-Launch-Logo.svg"
                  alt={cssVars.organizationName}
                  id="lfj-logo-link"
                />
                <Typography variant="caption" sx={{ color: 'white', position: 'relative', top: -2, left: -1 }}>
                  For Jobseekers
                </Typography>
              </Stack>
            </ReactLink>
          </Box>

          <Box display="flex" alignItems="center" flexGrow={1} justifyContent="flex-end">
            {/* Language Selector */}
            {(!showHamburger || needsOnboarding || isLandingPage) && !loading ? (
              <div
                className={classes.headerLink}
                onClick={() => resetLanguage(locale === 'en' ? 'es' : 'en')}
                onKeyDown={(keyEvent) => {
                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    resetLanguage(locale === 'en' ? 'es' : 'en');
                  }
                }}
                style={{ paddingRight: loggedIn ? '8px' : '20px', color: '#ffffff' }}
                tabIndex={0}
                id={locale === 'en' ? 'espanol-link' : 'english-link'}
              >
                {locale === 'en' ? 'Español' : 'English'}
              </div>
            ) : null}
            {!isAuthenticated ? (
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                onClick={handleLogin}
                sx={{ color: 'white', display: { xs: 'none', lg: 'inline-block' } }}
                id="log-in-public-button"
              >
                {intl.formatMessage({ id: 'homepage.login' })}
              </Button>
            ) : null}
            {/* Hamburger */}
            {showHamburger && isAuthenticated && !needsOnboarding ? (
              <div className={styles.hamburgerButton__container}>
                <HamburgerMenu
                  className={styles.hamburgerButton}
                  isOpen={hamOpen || forceMobileMenuOpen}
                  menuClicked={() => setHamOpen(!hamOpen)}
                  width={27}
                  height={14}
                  strokeWidth={3}
                  rotate={0}
                  color={colors.white}
                  borderRadius={0}
                  animationDuration={0.5}
                />
              </div>
            ) : loggedIn && !needsOnboarding ? (
              <>
                <Notifications jobSearchEnabled={user_settings.job_search_activity_all} />
                <Badge
                  badgeContent={
                    <KeyboardArrowDownIcon
                      sx={{ color: 'white', fontSize: 22, position: 'relative', top: -19, left: 3 }}
                    />
                  }
                  sx={{
                    position: 'relative',
                    top: -1,
                    span: {
                      px: 0.1,
                    },
                    cursor: 'pointer',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  onClick={handleDesktopProfileClick}
                >
                  <IconButton
                    size="small"
                    sx={{ position: 'relative' }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    aria-label={intl.formatMessage({ id: 'layout.menu.userMenu' })}
                  >
                    <AccountCircleIcon sx={{ width: 32, height: 32, color: 'white' }} id="profile-icon" />
                  </IconButton>
                </Badge>
                <Menu
                  anchorEl={desktopMenuAnchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleDesktopMenuClose}
                  onClick={handleDesktopMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Box sx={{ m: 2 }}>
                    {intl.formatMessage({ id: 'recommendations.mobileMenuGreetings' }, { name: preferredName })}
                  </Box>
                  <Divider />
                  {!needsOnboarding ? (
                    <Box sx={{ pt: 1 }}>
                      <MenuItem onClick={handleDesktopMenuClose}>
                        <ReactLink
                          to={`${urlPrefix}/experience`}
                          onClick={() => {
                            handleDesktopMenuClose();
                            trackEvent('PROFILE_SKILLSEXPERIENCE');
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography className={styles.mobileMenu__link} id="experience-and-skills-icon-link">
                            {intl.formatMessage({ id: 'layout.menu.experience' })}
                          </Typography>
                        </ReactLink>
                      </MenuItem>
                      <MenuItem onClick={handleDesktopMenuClose}>
                        <ReactLink
                          to={`${urlPrefix}/saved-items`}
                          onClick={() => {
                            handleDesktopMenuClose();
                            trackEvent('PROFILE_RATINGS');
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography className={styles.mobileMenu__link} id="saved-items-icon-link">
                            {intl.formatMessage({ id: 'layout.menu.savedItems' })}
                          </Typography>
                        </ReactLink>
                      </MenuItem>
                      <MenuItem onClick={handleDesktopMenuClose}>
                        <ReactLink
                          to={`${urlPrefix}/my-profile`}
                          onClick={() => {
                            handleDesktopMenuClose();
                            trackEvent('PROFILE_MYPROFILE');
                          }}
                          style={{ textDecoration: 'none', width: '100%' }}
                        >
                          <Typography className={styles.mobileMenu__link} id="my-profile-icon-link">
                            {intl.formatMessage({ id: 'recommendations.updateContactInfo' })}
                          </Typography>
                        </ReactLink>
                      </MenuItem>
                      {worksearch_status.worksearch_enabled && UIEnabled && (
                        <>
                          <MenuItem onClick={handleDesktopMenuClose}>
                            <ReactLink
                              to={`${urlPrefix}/mailing`}
                              onClick={() => {
                                handleDesktopMenuClose();
                                trackEvent('PROFILE_UPDATE_ADDRESS');
                              }}
                              style={{ textDecoration: 'none', width: '100%' }}
                            >
                              <Typography className={styles.mobileMenu__link} id="update-address-icon-link">
                                {intl.formatMessage({ id: 'recommendations.updateAddress' })}
                              </Typography>
                            </ReactLink>
                          </MenuItem>
                          <MenuItem onClick={handleDesktopMenuClose}>
                            <ReactLink
                              to={`${urlPrefix}/payment`}
                              onClick={() => {
                                handleDesktopMenuClose();
                                trackEvent('PROFILE_UPDATE_PAYMENT');
                              }}
                              style={{ textDecoration: 'none', width: '100%' }}
                            >
                              <Typography className={styles.mobileMenu__link} id="update-payment-icon-link">
                                {intl.formatMessage({ id: 'recommendations.updatePayment' })}
                              </Typography>
                            </ReactLink>
                          </MenuItem>
                        </>
                      )}
                      <Divider />
                    </Box>
                  ) : null}
                  <MenuItem
                    onClick={() => {
                      trackEvent('HEADER_SIGNOUT');
                      handleDesktopMenuClose();
                      signOut();
                    }}
                    id="sign-out"
                  >
                    <Typography>{intl.formatMessage({ id: 'layout.menu.signOut' })}</Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : null}
          </Box>
        </Container>
      </Box>
      {showHamburger ? (
        <div
          className={cn({
            [styles.mobileMenu]: true,
            [styles.mobileMenuOpen]: hamOpen || forceMobileMenuOpen,
          })}
        >
          {loggedIn ? (
            <Box pt={2} px={2} display="flex">
              <Box>
                <AccountCircleIcon sx={{ fontSize: 40, color: '#424242' }} />
              </Box>
              <Box pl={2} display="flex" flexDirection="column">
                <Typography variant="h4" style={{ pointerEvents: 'none' }}>
                  {intl.formatMessage({ id: 'recommendations.mobileMenuGreetings' }, { name: preferredName })}
                </Typography>
                <div style={{ paddingTop: 4 }}>
                  <ReactLink
                    to={`${urlPrefix}/experience`}
                    onClick={() => setHamOpen(false)}
                    style={{ textDecoration: 'none' }}
                  >
                    <span className={styles.mobileMenu__link} style={{ fontSize: 16 }} id="experience-mobile-menu-item">
                      {intl.formatMessage({ id: 'layout.menu.experience' })}
                    </span>
                  </ReactLink>
                  <span style={{ position: 'relative', top: 1 }}> | </span>
                  <ReactLink
                    to={`${urlPrefix}/my-profile`}
                    onClick={() => setHamOpen(false)}
                    style={{ textDecoration: 'none' }}
                  >
                    <span className={styles.mobileMenu__link} style={{ fontSize: 16 }}>
                      {intl.formatMessage({ id: 'profile.title' })}
                    </span>
                  </ReactLink>
                </div>
              </Box>
            </Box>
          ) : null}
          <ul style={loggedIn ? { padding: '0 16px 0' } : {}}>
            {!loggedIn ? (
              <li className={styles.mobileMenu__item}>
                <Box pt={1} pb={1}>
                  <HeaderButtonDark
                    onClick={() => {
                      setHamOpen(false);
                      handleLogin();
                    }}
                  >
                    {intl.formatMessage({ id: 'homepage.login' })}
                  </HeaderButtonDark>
                </Box>
              </li>
            ) : null}
          </ul>
          {loggedIn ? (
            <>
              <div
                className="divider-menu"
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: cssVars.mediumGray,
                  marginTop: 16,
                }}
              />
              {!needsOnboarding ? (
                <>
                  <Slide
                    direction="left"
                    in={showTodos}
                    mountOnEnter
                    unmountOnExit
                    style={{
                      background: 'white',
                      borderRadius: 0,
                      width: '100%',
                      minHeight: `calc(100% - 132px)`,
                      position: 'absolute',
                      zIndex: 2,
                      padding: '14px 16px 20px 32px',
                      boxSizing: 'border-box',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div>
                      <Button
                        onClick={() => setShowTodos(false)}
                        sx={{ position: 'relative', left: -27, mb: 2, mt: 1 }}
                      >
                        <ArrowBackIcon sx={{ position: 'relative', top: -1, fontSize: 18 }} /> Back
                      </Button>
                      <Box sx={{ ml: 2.4 }}>
                        <Typography variant="h4" style={{ fontSize: 18 }}>
                          {intl.formatMessage({ id: 'layout.menu.todos' })}
                        </Typography>
                      </Box>
                      <Box sx={{ ml: 2.4, pt: 1, pb: 2 }}>
                        {showProfileNotification ? (
                          <Box sx={{ my: 1, position: 'relative', py: 1 }}>
                            <Typography style={{ maxWidth: '100%', position: 'relative' }}>
                              <span style={{ marginRight: '10px', top: '1px', left: '-16px', position: 'absolute' }}>
                                •
                              </span>
                              {intl.formatMessage(
                                { id: 'layout.notifications.lfeLive' },
                                { b: (chunks) => <b>{chunks}</b> }
                              )}
                            </Typography>
                            <Box sx={{ mt: 2, mb: 3 }}>
                              <Button
                                variant="outlined"
                                sx={{ fontSize: 14, lineHeight: 1.4, px: 2 }}
                                LinkComponent={ReactLink}
                                to={`${urlPrefix}/my-profile`}
                                onClick={() => {
                                  if (localStorage) {
                                    localStorage.setItem('myProfileNotificationShown', 'true');
                                  }
                                  setShowTodos(false);
                                  setHamOpen(false);
                                  trackEvent('NOTIFICATIONS_MY_PROFILE');
                                }}
                              >
                                {intl.formatMessage({ id: 'layout.notifications.visitMyProfile' })}
                              </Button>
                            </Box>
                            <Divider />
                          </Box>
                        ) : null}
                        {(user_settings.job_search_activity_all ? total : pendingSkills) === 0 ? (
                          <Box py={3}>
                            <Typography style={{ maxWidth: '100%' }}>
                              {intl.formatMessage({ id: 'layout.notifications.caughtUp' })}
                            </Typography>
                          </Box>
                        ) : null}
                        {likedSkills.length < 10 ? (
                          <Box>
                            <Typography style={{ maxWidth: '100%', position: 'relative' }}>
                              <span style={{ marginRight: '10px', top: '1px', left: '-16px', position: 'absolute' }}>
                                •
                              </span>
                              {intl.formatMessage({ id: 'layout.notifications.missingSkills' })}
                            </Typography>
                            <Box sx={{ mt: 2, mb: 2, pb: 1 }}>
                              <Button
                                variant="outlined"
                                style={{ fontSize: '14px' }}
                                LinkComponent={ReactLink}
                                onClick={() => {
                                  setShowTodos(false);
                                  setHamOpen(false);
                                  trackEvent('NOTIFICATIONS_ADD_SKILLS');
                                }}
                                to={`${urlPrefix}/experience`}
                              >
                                {intl.formatMessage({ id: 'layout.notifications.updateSkills' })}
                              </Button>
                            </Box>
                            <Divider />
                          </Box>
                        ) : null}
                        {user_settings.job_search_activity_all && incompleteWeeks.length > 0
                          ? incompleteWeeks.map((week, i, fullArray) => (
                              <Box key={week.from} sx={{ my: 1, position: 'relative', pt: 2 }}>
                                <Typography style={{ maxWidth: '100%', position: 'relative' }}>
                                  <span
                                    style={{ marginRight: '10px', top: '1px', left: '-16px', position: 'absolute' }}
                                  >
                                    •
                                  </span>
                                  {intl.formatMessage(
                                    { id: 'layout.notifications.completedTasks' },
                                    {
                                      completed: week.items.filter((i) => !i.draft).length,
                                      total: week.required,
                                      date: monthDay(week.to),
                                    }
                                  )}
                                </Typography>
                                <Box sx={{ mt: 2, mb: 2, pb: 2 }}>
                                  <Button
                                    variant="outlined"
                                    style={{ fontSize: '14px' }}
                                    LinkComponent={ReactLink}
                                    onClick={() => {
                                      setShowTodos(false);
                                      setHamOpen(false);
                                      trackEvent('NOTIFICATIONS_WORKSEARCH');
                                    }}
                                    to={`${urlPrefix}/job-search-activity`}
                                  >
                                    {intl.formatMessage({ id: 'layout.menu.updateActivity' })}
                                  </Button>
                                </Box>
                                {i !== fullArray.length - 1 ? <Divider /> : null}
                              </Box>
                            ))
                          : null}
                      </Box>
                    </div>
                  </Slide>
                  <ul onClick={(e) => e.stopPropagation()}>
                    <li
                      className={styles.mobileMenu__item}
                      style={{ background: 'white' }}
                      onClick={() => {
                        setShowTodos(true);
                        trackEvent('NOTIFICATIONS_OPEN');
                      }}
                    >
                      <span className={styles.mobileMenu__link} style={{ fontSize: 16 }}>
                        {intl.formatMessage({ id: 'layout.menu.todos' })}
                        <Badge
                          color="error"
                          badgeContent={user_settings.job_search_activity_all ? total : pendingSkills}
                          sx={{ position: 'relative', right: -20, top: -1 }}
                        />
                      </span>
                    </li>
                    {worksearch_status.worksearch_enabled && UIEnabled && (
                      <li className={styles.mobileMenu__item}>
                        <ReactLink to={`${urlPrefix}/ui-dashboard`} onClick={() => setHamOpen(false)}>
                          <span
                            className={styles.mobileMenu__link}
                            style={{ fontSize: 16 }}
                            id="ui-dashboard-menu-item"
                          >
                            {intl.formatMessage({ id: 'layout.menu.uidashboard' })}
                          </span>
                        </ReactLink>
                      </li>
                    )}
                    <li className={styles.mobileMenu__item}>
                      <ReactLink to={`${urlPrefix}/jobs`} onClick={() => setHamOpen(false)}>
                        <span className={styles.mobileMenu__link} style={{ fontSize: 16 }} id="jobs-mobile-menu-item">
                          {intl.formatMessage({ id: 'layout.menu.jobs' })}
                        </span>
                      </ReactLink>
                    </li>
                    <li className={styles.mobileMenu__item}>
                      <ReactLink to={`${urlPrefix}/careers`} onClick={() => setHamOpen(false)}>
                        <span
                          className={styles.mobileMenu__link}
                          style={{ fontSize: 16 }}
                          id="careers-mobile-menu-item"
                        >
                          {intl.formatMessage({ id: 'layout.menu.careers' })}
                        </span>
                      </ReactLink>
                    </li>
                    <li className={styles.mobileMenu__item}>
                      <ReactLink to={`${urlPrefix}/training`} onClick={() => setHamOpen(false)}>
                        <span
                          className={styles.mobileMenu__link}
                          style={{ fontSize: 16 }}
                          id="training-mobile-menu-item"
                        >
                          {intl.formatMessage({ id: 'layout.menu.training' })}
                        </span>
                      </ReactLink>
                    </li>
                    <li className={styles.mobileMenu__item}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        component="a"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://myardashboard.arkansas.gov/etprovidersmap.html"
                      >
                        {intl.formatMessage({ id: 'layout.menu.findTrainingProvider' })}
                        <OpenInNewIcon sx={{ fontSize: 18, ml: 0.5 }} />
                      </Typography>
                    </li>
                    <li className={styles.mobileMenu__item}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        component="a"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://myardashboard.arkansas.gov/hepg.html"
                      >
                        {intl.formatMessage({ id: 'layout.menu.findHigherEdProvider' })}
                        <OpenInNewIcon sx={{ fontSize: 18, ml: 0.5 }} />
                      </Typography>
                    </li>
                    <li className={styles.mobileMenu__item}>
                      <Typography
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                          color: 'rgba(0, 0, 0, 0.87)',
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                        component="a"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://myarciviform.arkansas.gov/programs"
                      >
                        {intl.formatMessage({ id: 'layout.menu.applyForBenefits' })}
                        <OpenInNewIcon sx={{ fontSize: 18, ml: 0.5 }} />
                      </Typography>
                    </li>
                    {user_settings.add_job_search_activity_hero_button ? (
                      <li className={styles.mobileMenu__item}>
                        <ReactLink to={`${urlPrefix}/job-search-activity`} onClick={() => setHamOpen(false)}>
                          <span className={styles.mobileMenu__link} style={{ fontSize: 16 }}>
                            {intl.formatMessage({ id: 'jobSearchActivity.title' })}
                          </span>
                        </ReactLink>
                      </li>
                    ) : null}
                    <li className={styles.mobileMenu__item}>
                      <ReactLink to={`${urlPrefix}/saved-items`} onClick={() => setHamOpen(false)}>
                        <span className={styles.mobileMenu__link} style={{ fontSize: 16 }}>
                          {intl.formatMessage({ id: 'layout.menu.savedItems' })}
                        </span>
                      </ReactLink>
                    </li>
                    {worksearch_status.worksearch_enabled && UIEnabled && (
                      <>
                        <li className={styles.mobileMenu__item}>
                          <ReactLink to={`${urlPrefix}/mailing`} onClick={() => setHamOpen(false)}>
                            <span className={styles.mobileMenu__link} style={{ fontSize: 16 }}>
                              {intl.formatMessage({ id: 'recommendations.updateAddress' })}
                            </span>
                          </ReactLink>
                        </li>
                        <li className={styles.mobileMenu__item}>
                          <ReactLink to={`${urlPrefix}/payment`} onClick={() => setHamOpen(false)}>
                            <span className={styles.mobileMenu__link} style={{ fontSize: 16 }}>
                              {intl.formatMessage({ id: 'recommendations.updatePayment' })}
                            </span>
                          </ReactLink>
                        </li>
                      </>
                    )}
                    <li className={styles.mobileMenu__item}>
                      <span
                        className={styles.mobileMenu__link}
                        style={{ fontSize: 16 }}
                        onClick={() => resetLanguage(locale === 'en' ? 'es' : 'en')}
                        id={locale === 'en' ? 'espanol-mobile-link' : 'english-mobile-link'}
                      >
                        {locale === 'en' ? 'Español' : 'English'}
                      </span>
                    </li>
                    <Divider sx={{ my: 1 }} />

                    <li className={styles.mobileMenu__item}>
                      <Button
                        variant="outlined"
                        onClick={handleSignOut}
                        sx={{
                          fontSize: 14,
                          borderRadius: '4px',
                          padding: {
                            xs: '3px 22px 2px',
                          },
                          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        }}
                      >
                        {intl.formatMessage({ id: 'layout.menu.signOut' })}
                      </Button>
                    </li>
                  </ul>
                </>
              ) : (
                ''
              )}
              {needsOnboarding ? (
                <>
                  <div
                    className="divider-menu"
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: cssVars.mediumGray,
                    }}
                  ></div>

                  <ul className={styles.mobileMenu__items}>
                    <li className={cn(styles.mobileMenu__item, styles.noTextTransform)}>
                      <span
                        className={cn(styles.mobileMenu__link, drawerStyles.menuDrawerLink__text__link)}
                        onClick={handleSignOut}
                        style={{ fontSize: '14px' }}
                      >
                        {intl.formatMessage({ id: 'layout.menu.signOut' })}
                      </span>
                    </li>
                  </ul>
                </>
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

Header.propTypes = {
  locale: PropTypes.string,
  serverUserLocale: PropTypes.string,
  loggedIn: PropTypes.bool,
  signOut: PropTypes.func,
};

export default Header;
