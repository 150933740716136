import { add, sub, format, differenceInDays } from 'date-fns';

export const FORMAT_DATE_MM_DD_YYYY = 'MM/dd/yyyy';
export const TARGET_DAYS_FROM_CURRENT_DATE_TO_USE_FOR_CLAIMS = 20;

export const calculateDaysLeftToClaim = (claimDate) => {
  const targetDate = add(claimDate, { days: TARGET_DAYS_FROM_CURRENT_DATE_TO_USE_FOR_CLAIMS });
  const daysRemainingFromCurrentDate = differenceInDays(targetDate, new Date());

  return daysRemainingFromCurrentDate > 0 ? daysRemainingFromCurrentDate : 0;
};

export const dateWithoutTZ = (date) => {
  return new Date(...date.split('-').map((n, i) => (i === 1 ? Number(n) - 1 : Number(n))));
};

export const formatDashboardData = (data) => {
  const summaryWeekEndDate = data.summary.week_end
    ? new Date(...data.summary.week_end.split('-').map((n, i) => (i === 1 ? Number(n) - 1 : Number(n))))
    : null;
  return {
    ...data,
    ...(data.claim_week && {
      claim_week: data.claim_week
        .sort((a, b) => new Date(a.week_end) - new Date(b.week_end))
        ?.map((week, index) => {
          const weekEndDate = new Date(
            ...week.week_end.split('-').map((n, i) => (i === 1 ? Number(n) - 1 : Number(n)))
          );
          return {
            ...week,
            week_end: week.week_end ? format(weekEndDate, FORMAT_DATE_MM_DD_YYYY) : '',
            to: week.week_end,
            // custom
            id: index,
            week_start: week.week_end ? format(sub(weekEndDate, { days: 6 }), FORMAT_DATE_MM_DD_YYYY) : '',
            days_left: calculateDaysLeftToClaim(weekEndDate),
          };
        }),
    }),
    ...(data.summary && {
      summary: {
        ...data.summary,
        week_end: data.summary.week_end ? format(summaryWeekEndDate, FORMAT_DATE_MM_DD_YYYY) : '',
        benefits_processed_dt: data.summary.benefits_processed_dt
          ? format(
              new Date(
                ...data.summary.benefits_processed_dt.split('-').map((n, i) => (i === 1 ? Number(n) - 1 : Number(n)))
              ),
              FORMAT_DATE_MM_DD_YYYY
            )
          : '',
        benefits_amount: data.summary.benefits_amount?.toLocaleString(),
        total_deductions: data.summary.total_deductions?.toLocaleString(),
        // custom
        week_start: data.summary.week_end ? format(sub(summaryWeekEndDate, { days: 6 }), FORMAT_DATE_MM_DD_YYYY) : '',
      },
    }),
    ...(data.annual_claim && {
      annual_claim: {
        ...data.annual_claim,
        start_date: format(
          data.annual_claim.start_date
            ? new Date(...data.annual_claim.start_date.split('-').map((n, i) => (i === 1 ? Number(n) - 1 : Number(n))))
            : new Date(),
          FORMAT_DATE_MM_DD_YYYY
        ),
        end_date: format(
          data.annual_claim.end_date
            ? new Date(...data.annual_claim.end_date.split('-').map((n, i) => (i === 1 ? Number(n) - 1 : Number(n))))
            : new Date(),
          FORMAT_DATE_MM_DD_YYYY
        ),
      },
    }),
  };
};
