import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { pathOr } from 'ramda';

import { useIntl } from 'react-intl';

const CareerVideo = ({ item }) => {
  const intl = useIntl();

  const title = `title_${intl.locale}` in item ? item[`title_${intl.locale}`] : item.title;

  let provider = 'edgefactor';
  if (!item.videos.edgefactor || item.videos.edgefactor.length === 0) {
    provider = 'careeronestop';
  }

  // EdgeFactor will not work on localhost because of CORS configuration on their end
  // Force careeronestop when running on craco dev server
  if (process.env.NODE_ENV !== 'production') {
    provider = 'careeronestop';
  }

  // TODO EdgeFactor videos are only enabled for dev at the moment
  if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
    provider = 'careeronestop';
  }

  const url = pathOr(item?.video, ['videos', provider, 0, 'video'], item);
  const sharedAttributes = {
    crossOrigin: 'anonymous',
    title,
  };

  let config = {};
  if (url && url.length > 0) {
    const sub_tracks = [];
    if (provider === 'careeronestop') {
      const base_url = url.substring(0, url.length - 4);
      const caption_url = base_url.replace('OccVids/OccupationVideos', 'CaptionFiles');
      sub_tracks.push(
        ...[
          ['en', '.vtt'],
          ['es', '_es.vtt'],
        ].map(([lang, ext]) => ({
          kind: 'subtitles',
          src: `${caption_url}${ext}`,
          srcLang: lang,
          label: lang === 'en' ? 'English' : 'Español',
          default: lang === 'en',
        }))
      );

      const poster = `${base_url}.jpg`;

      config = {
        attributes: { ...sharedAttributes, poster, preload: 'none' },
        tracks: sub_tracks,
      };
    } else if (provider === 'edgefactor') {
      config = {
        attributes: { ...sharedAttributes },
        forceHLS: true,
        hlsOptions: {
          maxMaxBufferLength: 1,
        },
      };
    }
  }

  const trackEvent = (event) => {
    window.dataLayer.push({
      event,
      'career-video-soc': item.soc,
      'career-video-title': item.title,
      'career-video-provider': provider,
    });
  };

  return url ? (
    <ReactPlayer
      config={{
        file: { ...config },
      }}
      url={url}
      controls={true}
      id={`career_video_${item.soc}`}
      width="100%"
      height="100%"
      onStart={() => trackEvent('click-video-play-careers')}
      onPause={() => trackEvent('click-video-pause-careers')}
      onPlay={() => trackEvent('click-video-unpause-careers')}
    />
  ) : null;
};

CareerVideo.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CareerVideo;
