import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Chip, IconButton, Button, CardContent, Typography, Box } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Unstable_Grid2';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import CareerVideo from './CareerVideo';

/* i18n */
import { useIntl } from 'react-intl';

export default function CareersCard({
  item,
  onButtonClick,
  onTrainingsButtonClick,
  rating,
  onLikeClick,
  onDislikeClick,
  tagsActive,
  ratingsPage = false,
}) {
  const intl = useIntl();
  const LikeIcon =
    rating === 'liked' ? ThumbUpAltIcon : rating === 'disliked' && ratingsPage ? ReplyOutlinedIcon : ThumbUpOffAltIcon;

  // Hide recommendation menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const skillsList =
    `common_skills_${intl.locale}` in item && item[`common_skills_${intl.locale}`].length > 0
      ? item[`common_skills_${intl.locale}`]
      : item.common_skills ?? [];

  return (
    <Grid xs={12} md={6} className="career-card">
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: ratingsPage ? 'none' : '0px 3px 12px rgba(0,0,0,0.1)',
          border: ratingsPage ? 'solid 1px rgba(0, 0, 0, 0.12)' : 'none',
        }}
      >
        <CardHeader
          avatar={
            getMatchPerc(item, tagsActive) ? (
              <Chip
                label={`${getMatchPerc(item, tagsActive)}% ${intl.formatMessage({ id: 'careers.card.match' })}`}
                color="success"
                variant="outlined"
              />
            ) : null
          }
          action={
            <>
              <Button
                startIcon={<LikeIcon sx={{ position: 'relative', bottom: 1 }} />}
                onClick={onLikeClick}
                sx={{ px: ratingsPage ? 2 : 1, position: 'relative', top: 1 }}
                id="save-career-button"
              >
                {intl.formatMessage({
                  id:
                    rating === 'liked'
                      ? ratingsPage
                        ? 'ratings.unsave'
                        : 'careers.saved'
                      : rating === 'disliked' && ratingsPage
                      ? 'ratings.unhide'
                      : 'careers.saveThis',
                })}
              </Button>
              {!ratingsPage && (
                <IconButton aria-label="settings" onClick={handleClick} color="secondary.dark">
                  <MoreVertIcon />
                </IconButton>
              )}

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.2)',
                  },
                }}
                disableScrollLock
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onDislikeClick();
                  }}
                  id="hide-recommendation-career-link"
                >
                  {intl.formatMessage({ id: 'recommendations.hide' })}
                </MenuItem>
              </Menu>
            </>
          }
        />
        <CardContent sx={{ pt: 1.5 }}>
          <Typography variant="h4" component="h2">
            {`title_${intl.locale}` in item && item[`title_${intl.locale}`].trim() !== ''
              ? item[`title_${intl.locale}`]
              : item.title}
          </Typography>
        </CardContent>
        <Box
          mt={1}
          sx={{
            video: {
              width: '100%',
            },
          }}
        >
          <CareerVideo
            item={item}
            title={`title_${intl.locale}` in item ? item[`title_${intl.locale}`] : item.title}
            soc_code={item.soc}
            url={item.video}
          />
        </Box>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
          <Box>
            {skillsList.length ? (
              <Box mb={2} mt={1}>
                <Typography sx={{ fontWeight: 700 }}>
                  {intl.formatMessage({ id: 'recommendations.card.commonSkills' })}
                </Typography>
                <Typography variant="body2" component="span" gutterBottom>
                  {skillsList.join(', ')}
                </Typography>
              </Box>
            ) : null}
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 700 }}>{intl.formatMessage({ id: 'careers.avgSalary' })}</Typography>
                {item.average_pay_state || item.average_pay_national
                  ? `$${Math.round(Number(item.average_pay_state || item.average_pay_national) / 1000)}k`
                  : ''}
                {`/${intl.formatMessage({ id: 'training.duration.year' })}`}
              </Box>
              <Box sx={{ display: item.bright_outlook ? 'flex' : 'none', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 700 }}>
                  {intl.formatMessage({ id: 'careers.projectedGrowth' })}
                </Typography>
                {`bright_outlook_${intl.locale}` in item && item[`bright_outlook_${intl.locale}`]
                  ? item[`bright_outlook_${intl.locale}`]
                  : item.bright_outlook}
              </Box>
            </Box>
          </Box>
          <Box
            mt={4}
            sx={{
              display: 'grid',
              gap: '12px',
              gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
              gridTemplateRows: { xs: '1fr 1fr', sm: '1fr' },
            }}
          >
            <Box sx={{ display: { xs: 'flex', sm: 'block' }, justifyContent: 'center' }}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={onButtonClick}
                sx={{ width: { xs: '200px', sm: 'auto' } }}
                id="discover-jobs-career-button"
              >
                {intl.formatMessage({ id: 'careers.discoverJobs' })} ({item.available_jobs})
              </Button>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'block' }, justifyContent: 'center' }}>
              {item.available_trainings > 0 && (
                <Button
                  color="primary"
                  onClick={onTrainingsButtonClick}
                  size="large"
                  variant="contained"
                  sx={{ width: { xs: '200px', sm: 'auto' } }}
                  id="explore-training-career-button"
                >
                  {intl.formatMessage({ id: 'careers.exploreTraining' })} ({item.available_trainings})
                </Button>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

CareersCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    video: PropTypes.string,
    soc: PropTypes.string,
    average_pay_state: PropTypes.string,
    average_pay_national: PropTypes.string,
    bright_outlook: PropTypes.string,
    available_jobs: PropTypes.number,
    available_trainings: PropTypes.number,
    common_skills: PropTypes.arrayOf(PropTypes.string),
  }),
  onButtonClick: PropTypes.func,
  onTrainingsButtonClick: PropTypes.func,
  onLikeClick: PropTypes.func,
  onDislikeClick: PropTypes.func,
  rating: PropTypes.string,
  tagsActive: PropTypes.arrayOf(PropTypes.string),
  ratingsPage: PropTypes.bool,
};

function getMatchPerc(item, tagsActive) {
  if (!tagsActive.includes) {
    return item.match_rating_0;
  }
  if (tagsActive.includes('boostEarnings') && tagsActive.includes('manyJobs')) {
    return item.match_rating_3;
  } else if (tagsActive.includes('boostEarnings')) {
    return item.match_rating_1;
  } else if (tagsActive.includes('manyJobs')) {
    return item.match_rating_2;
  }
  return item.match_rating_0;
}
