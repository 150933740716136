import cn from 'classnames';
import PropTypes from 'prop-types';
import { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { apiRequest } from 'lib/API';
import { useQuery } from '@tanstack/react-query';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Styles */
import editJobStyles from 'styles/edit-job-activity.module.scss';
import 'react-circular-progressbar/dist/styles.css';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  Dialog,
  DialogContent,
  Button,
  useMediaQuery,
  Tooltip,
  Divider,
  useTheme,
  darken,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import info from 'assets/images/Info_Cards.svg';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';

/* Redux */
import { useSelector } from 'react-redux';

/* UI Components */
import EditJobActivity from 'components/EditJobActivity';
import SearchActivityCard from 'components/SearchActivityCard';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import SSNDialog from 'components/forms/SSNDialog';

import { useExportActivitySummary, useFormatDateRange } from 'shared/hooks';
import { grey } from '@mui/material/colors';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: '#151515',
  },
}));

function getTo(fromStr) {
  const year = fromStr.slice(0, 4);
  const month = fromStr.slice(5, 7);
  const day = fromStr.slice(8, 10);
  const from = new Date(year, month - 1, day);
  from.setDate(from.getDate() + 6);
  return from.toISOString().slice(0, 10);
}

export function getWeekArr(status, activities, sortReverse = false) {
  if (!status.week_start_dates || !status.week_start_dates.length) {
    return [];
  }
  const weeksObj = status.week_start_dates.reduce((acc, curr) => {
    acc[curr] = {
      from: curr,
      to: getTo(curr),
      required: 5,
      items: [],
    };
    return acc;
  }, {});
  activities.forEach((curr) => {
    const week = curr.week_start_date;
    weeksObj[week]?.items?.push(curr);
  });
  const weeks = Object.keys(weeksObj).sort((a, b) => {
    if (sortReverse) {
      return new Date(a) - new Date(b);
    }
    return new Date(b) - new Date(a);
  });
  const weekArr = weeks.map((w) => weeksObj[w]);
  return weekArr;
}

const LoadingComponent = (props) => {
  return (
    <Box
      mt={3}
      sx={{
        color: grey['800'],
        backgroundColor: '#ebf9e9',
        border: `solid 2px #8bb98b`,
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="span"
        sx={{
          height: '19px',
          width: '24px',
          backgroundColor: '#0e6e10',
          borderRadius: '50%',
          color: 'white',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '6px',
        }}
      >
        <CheckIcon
          sx={{
            fontSize: '17px !important',
          }}
        />
      </Box>
      {props.title}
    </Box>
  );
};

LoadingComponent.propTypes = {
  title: PropTypes.string,
};

const JobSearchActivity = () => {
  const { palette } = useTheme();
  const intl = useIntl();
  const tooltipClasses = useStylesBootstrap();
  const formatDateRange = useFormatDateRange();
  const isSmall = useMediaQuery('(max-width:767px)');
  const urlPrefix = getUrlPrefix(intl.locale);
  const user_settings = useSelector((state) => state.app.user_settings);

  const [editModalsOpen, setEditModalsOpen] = useState([false]);
  const [formsSelectedActivities, setFormSelectedActivities] = useStateCallback(['']);
  const [ssnDialogOpen, setSsnDialogOpen] = useState(false);
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const { lang } = useParams();
  const history = useHistory();

  if (lang === 'onboarding') {
    history.push(`${urlPrefix}/jobs`);
  }
  const { isFetching, isFetched, data } = useQuery({
    queryKey: ['/users/me/worksearch/'],
    queryFn: async ({ queryKey }) => apiRequest('GET', queryKey[0]),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const weekArr = data ? getWeekArr(data?.status, data?.activities) : [];
  const worksearch_status = data?.status ?? {};

  const completedCurrentWeek =
    ((worksearch_status?.weekly_activities_completed ?? 0) / (worksearch_status?.weekly_activities_required ?? 5)) *
    100;

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'jobSearchActivity.title' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('JOBSEARCH');
    TimeMe.startTimer();
  }, []);

  useEffect(() => {
    if (isFetched) {
      setSsnDialogOpen(worksearch_status.ssn_required);
      const newEditModalsOpen = new Array(weekArr.length).fill(false);
      const newFormSelectedActivities = new Array(weekArr.length).fill('');

      setEditModalsOpen(newEditModalsOpen);
      setFormSelectedActivities(newFormSelectedActivities);
    }
  }, [isFetched, worksearch_status]);

  function activityString(from, to) {
    return `${intl.formatMessage({ id: 'jobSearchActivity.activityFor' })} ${formatDateRange(from, to)}`;
  }
  function formatDate(date) {
    const year = date.substr(2, 2);
    const month = date.substr(5, 2);
    const day = date.substr(8, 2);
    return `${parseInt(month, 10)}.${parseInt(day, 10)}.${year}`;
  }
  const activitiesDict = {
    applied_for_job: intl.formatMessage({ id: 'jobSearchActivity.applyForAJob' }),
    participate_in_resea: intl.formatMessage({ id: 'jobSearchActivity.activity.participatedReseaWorkshop' }),
    attend_job_fair: intl.formatMessage({ id: 'jobSearchActivity.attendJobFair' }),
    attend_job_seminar: intl.formatMessage({ id: 'jobSearchActivity.attendJobSearch' }),
    job_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.scheduleMeeting' }),
    interview_with_employer: intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' }),
    follow_up_with_employer: intl.formatMessage({ id: 'jobSearchActivity.directContact' }),
    register_with_school: intl.formatMessage({ id: 'jobSearchActivity.registerFacility' }),
    resume_upload: intl.formatMessage({ id: 'jobSearchActivity.activity.uploadedResume' }),
    draft: intl.formatMessage({ id: 'jobSearchActivity.draft' }),
  };
  const fieldsDict = {
    company_name: intl.formatMessage({ id: 'jobSearchActivity.form.companyName' }),
    contact_name: intl.formatMessage({ id: 'jobSearchActivity.form.contactName' }),
    contact_email: intl.formatMessage({ id: 'jobSearchActivity.form.contactEmail' }),
    contact_phone_number: intl.formatMessage({ id: 'jobSearchActivity.form.contactPhoneNumber' }),
    position_applied_for: intl.formatMessage({ id: 'jobSearchActivity.form.positionApplied' }),
    notes: intl.formatMessage({ id: 'jobSearchActivity.form.notes' }),
    method_of_contact: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfContact' }),
    application_filed: intl.formatMessage({ id: 'jobSearchActivity.form.applicationFiled' }),
    name_of_school: intl.formatMessage({ id: 'jobSearchActivity.form.nameOfSchoolPlacement' }),
    result_of_contact: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfContact' }),
    result_of_seminar: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfSeminar' }),
    result_of_interview: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfInterview' }),
    result_of_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.form.resultOfCoachMeeting' }),
    method_of_coach_meeting: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfCoachMeeting' }),
    method_of_interview: intl.formatMessage({ id: 'jobSearchActivity.form.methodOfInterview' }),
    type_of_seminar: intl.formatMessage({ id: 'jobSearchActivity.form.typeOfSeminar' }),
    type_of_activity: intl.formatMessage({ id: 'jobSearchActivity.form.typeOfActivity' }),
    attended_skills_assessment: intl.formatMessage({ id: 'jobSearchActivity.form.attendedSkills' }),
    application_submission_format: intl.formatMessage({ id: 'jobSearchActivity.form.applyJob.asf' }),
    in_person: intl.formatMessage({ id: 'jobSearchActivity.form.inPerson' }),
    performed_interview: intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption3' }),
    resumes_submitted: intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption1' }),
    interviews_scheduled: intl.formatMessage({ id: 'jobSearchActivity.fair.resultOption2' }),
    result_of_job_fair: intl.formatMessage({ id: 'jobSearchActivity.fair.ResultOfJobFair' }),
    job_fair: intl.formatMessage({ id: 'jobSearchActivity.fair.jobFair' }),
    job_fair_website: intl.formatMessage({ id: 'jobSearchActivity.fair.jobFairWebsite' }),
    location: intl.formatMessage({ id: 'jobSearchActivity.fair.locationOfJobFair' }),
    time_of_job_fair: intl.formatMessage({ id: 'jobSearchActivity.fair.timeOfJobFair' }),
    interviewer_name: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerName' }),
    interviewer_phone_number: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerPhone' }),
    interviewer_email: intl.formatMessage({ id: 'jobSearchActivity.form.interviewerEmail' }),
    position_interviewed_for: intl.formatMessage({ id: 'jobSearchActivity.form.positionInterviewedFor' }),
  };

  const exportActivitySummary = useExportActivitySummary(fieldsDict, activitiesDict);
  const handlePrintHistory = () => {
    setLoadingDialogOpen(true);
    trackEvent('ACTIVITY_SHARE_DOWNLOAD');

    apiRequest('GET', '/users/me/worksearch/').then(({ activities }) => {
      exportActivitySummary(
        intl.formatMessage({ id: 'jobSearchActivity.activitySummary' }),
        '/images/AR-Launch-Logo-Green-Small.png',
        activities,
        26.48,
        12,
        'launch.arkansas.gov'
      );
      setLoadingDialogOpen(false);
    });
  };

  return (
    <>
      <Box sx={{ py: 3.75 }}>
        <Container maxWidth="lg">
          {user_settings.job_search_activity_header ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  justifyContent: { md: 'space-between' },
                  alignItems: { md: 'center' },
                }}
              >
                <Typography variant="h1" component="h2">
                  {intl.formatMessage({ id: 'jobSearchActivity.title' })}
                </Typography>
                <Box pb={!isSmall ? 5 : 1} />
              </Box>
              <Divider sx={{ mb: 1, mt: 0.5 }} />
            </>
          ) : null}
          {isFetching ? (
            <Box m={7} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {user_settings.job_search_activity_summary_print_or_share ? <Box pb={1} /> : null}
              {user_settings.job_search_activity_summary_print_or_share ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: 12,
                    flexDirection: { xs: 'column', md: 'row' },
                  }}
                >
                  <div />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-end' },
                    }}
                  >
                    {user_settings.job_search_activity_summary_print_link ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handlePrintHistory}
                        style={{ borderRadius: 4 }}
                        id="download-work-search-button"
                      >
                        {intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                        <PdfIcon
                          style={{ fontSize: 18, marginLeft: 4, position: 'relative', bottom: 1 }}
                          alt={intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                          name={intl.formatMessage({ id: 'jobSearchActivity.downloadPdf' })}
                        />
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              ) : null}

              {user_settings.job_search_activity_summary ? (
                <Box
                  sx={{
                    backgroundColor: 'white',
                    pt: 1.5,
                    pb: 2.5,

                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <p>{intl.formatMessage({ id: 'jobSearchActivity.explanationText' })}</p>
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pr: { md: 2.5 },
                        width: { md: '50%' },
                      }}
                    >
                      <Typography variant="h5" component="h2">
                        {intl.formatMessage({ id: 'jobSearchActivity.youreRequired' })}
                      </Typography>
                      <Box component="ul" sx={{ pl: { xs: 1, md: 3 }, '& li:marker': { opacity: 0.5 } }}>
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <Typography
                              component={Link}
                              to={`${urlPrefix}/jobs`}
                              sx={{
                                color: palette.info.main,
                                textDecoration: 'underline',
                                fontWeight: 700,
                                '&:hover': {
                                  color: darken(palette.info.main, 0.1),
                                },
                              }}
                              onClick={() => {
                                trackEvent('ACTIVITY_LINK_APPLYJOB');
                              }}
                            >
                              {intl.formatMessage({ id: 'jobSearchActivity.applyForJob' })}
                            </Typography>
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' })}</span>
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.attendJobFair' })}</span>
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body2" gutterBottom>
                            <span>{intl.formatMessage({ id: 'jobSearchActivity.participateInWorkshop' })}</span>
                          </Typography>
                        </li>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      {user_settings.job_search_activity_required_weekly_activity_chart ? (
                        <>
                          <Box
                            sx={{
                              display: { xs: 'none', md: 'flex' },
                              borderColor: grey['300'],
                              borderStyle: 'solid',
                              borderWidth: 1,
                              padding: '24px 35px 24px 30px',
                              boxSizing: 'border-box',
                              justifyContent: 'space-between',
                              '&:first-of-type': {
                                mb: 3,
                              },
                            }}
                          >
                            <Box pr={2}>
                              <Typography
                                component="h3"
                                variant="body1"
                                sx={{
                                  fontWeight: 700,
                                }}
                              >
                                {intl.formatMessage({ id: 'jobSearchActivity.requiredWeekly' })}
                                <br />
                                {formatDateRange(
                                  worksearch_status.weekly_activity_start_date,
                                  worksearch_status.weekly_activity_end_date
                                )}
                              </Typography>
                              <Typography sx={{ fontSize: 15 }}>
                                {intl.formatMessage(
                                  { id: 'jobSearchActivity.completedTasks' },
                                  {
                                    completed: worksearch_status.weekly_activities_completed,
                                    total: worksearch_status.weekly_activities_required,
                                  }
                                )}
                              </Typography>
                            </Box>
                            <Box sx={{ width: 111, height: 111 }}>
                              <CircularProgressbarWithChildren
                                value={completedCurrentWeek > 100 ? 100 : completedCurrentWeek}
                                strokeWidth={18}
                                styles={buildStyles({
                                  strokeLinecap: 'butt',
                                  pathColor: completedCurrentWeek >= 100 ? palette.primary.main : '#FCCB00',
                                  backgroundColor: '#B7B7B7',
                                })}
                              >
                                <Typography
                                  component="span"
                                  variant="body1"
                                  sx={{
                                    fontSize: { xs: 18, md: 24 },
                                    fontWeight: 700,
                                  }}
                                >
                                  {worksearch_status.weekly_activities_completed}
                                </Typography>
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: { xs: 12, md: 13 },
                                    lineHeight: '18px',
                                  }}
                                >
                                  {intl.formatMessage({ id: 'jobSearchActivity.of' })}{' '}
                                  {worksearch_status.weekly_activities_required}
                                </Typography>
                              </CircularProgressbarWithChildren>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: { xs: 'flex', md: 'none' },
                              flexDirection: 'column',
                              borderWidth: 1,
                              borderStyle: 'solid',
                              borderColor: grey['300'],
                              padding: '19px 35px 24px 30px',
                              boxSizing: 'border-box',
                              justifyContent: 'space-between',
                              '&:first-of-type': {
                                marginBottom: 3,
                              },
                            }}
                          >
                            <Typography variant="h2" component="h3" sx={{ textAlign: 'center', fontSize: 18, mb: 1.3 }}>
                              {intl.formatMessage({ id: 'jobSearchActivity.requiredWeekly' })}
                              <br />
                              {formatDateRange(
                                worksearch_status.weekly_activity_start_date,
                                worksearch_status.weekly_activity_end_date
                              )}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Box sx={{ width: 111, height: 111 }}>
                                <CircularProgressbarWithChildren
                                  value={completedCurrentWeek > 100 ? 100 : completedCurrentWeek}
                                  strokeWidth={18}
                                  styles={buildStyles({
                                    strokeLinecap: 'butt',
                                    pathColor: completedCurrentWeek >= 100 ? palette.primary.main : '#FCCB00',
                                    backgroundColor: '#B7B7B7',
                                  })}
                                >
                                  <Typography
                                    component="span"
                                    variant="body1"
                                    sx={{
                                      fontSize: { xs: 18, md: 24 },
                                      fontWeight: 700,
                                    }}
                                  >
                                    {worksearch_status.weekly_activities_completed}
                                  </Typography>
                                  <Typography
                                    component="span"
                                    sx={{
                                      fontSize: 13,
                                      lineHeight: '18px',
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'jobSearchActivity.of' })}{' '}
                                    {worksearch_status.weekly_activities_required}
                                  </Typography>
                                </CircularProgressbarWithChildren>
                              </Box>
                            </Box>
                            <Typography sx={{ fontSize: 15, mt: 2, textAlign: 'center' }}>
                              {intl.formatMessage(
                                { id: 'jobSearchActivity.completedTasks' },
                                {
                                  completed: worksearch_status.weekly_activities_completed,
                                  total: worksearch_status.weekly_activities_required,
                                }
                              )}
                            </Typography>
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              ) : null}
              {worksearch_status.overall_activities_required === 0 &&
              !worksearch_status.is_inactive &&
              !ssnDialogOpen &&
              !isFetching ? (
                <LoadingComponent title={intl.formatMessage({ id: 'jobSearchActivity.waived' })} />
              ) : null}
              {!worksearch_status.ssn_required &&
              !worksearch_status.worksearch_enabled &&
              !ssnDialogOpen &&
              !isFetching ? (
                <LoadingComponent title={intl.formatMessage({ id: 'jobSearchActivity.ssnNotFound' })} />
              ) : null}

              {worksearch_status.is_inactive ? (
                <Box
                  mt={3}
                  sx={{
                    color: grey['800'],
                    backgroundColor: '#fccb0025',
                    border: `solid 2px #fccb0095`,
                    padding: '8px 16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <InfoIcon sx={{ position: 'relative', right: { xs: 5, md: 8 }, color: '#796204d4' }} />
                  {intl.formatMessage({ id: 'jobSearchActivity.inactive' })}
                </Box>
              ) : null}
              {user_settings.job_search_activity_summary ? <Box mb={3} /> : null}
              {user_settings.job_search_activity_weekly_activity
                ? weekArr.map((week, idx) => (
                    <Fragment key={`${week.from}${week.to}`}>
                      {user_settings.job_search_activity_summary || idx !== 0 ? (
                        <Divider sx={{ mb: 1, mt: 0.5 }} />
                      ) : null}
                      <Box mb={5} />
                      <Typography variant="h4" component="h3" style={{ display: 'flex' }}>
                        {activityString(week.from, week.to)}{' '}
                        <Tooltip
                          enterTouchDelay={0}
                          title={
                            <Fragment>
                              <span style={{ fontSize: 14 }}>
                                {intl.formatMessage({ id: 'jobSearchActivity.activity.weekTooltip' })}
                              </span>
                            </Fragment>
                          }
                          placement="top"
                          arrow
                          classes={tooltipClasses}
                        >
                          <Box ml={1}>
                            <img
                              alt="Info"
                              src={info}
                              style={{
                                width: '15px',
                                height: '15px',
                                cursor: 'pointer',
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography variant="body2">
                        {intl.formatMessage(
                          { id: 'jobSearchActivity.tasksCompleted' },
                          { completed: week.items.filter((it) => !it.draft).length, total: week.required }
                        )}
                      </Typography>
                      <div
                        className={cn({
                          [editJobStyles.editJobContainer]: true,
                          [editJobStyles.editJobContainerOpaque]: editModalsOpen[idx],
                        })}
                      >
                        <EditJobActivity
                          week={week}
                          editModalOpen={editModalsOpen[idx]}
                          setEditModalOpen={(open) => {
                            setEditModalsOpen(
                              editModalsOpen
                                .slice(0, idx)
                                .concat([open])
                                .concat(editModalsOpen.slice(idx + 1))
                            );
                          }}
                          selectedActivity={{ ...formsSelectedActivities[idx] }}
                        />
                        <div
                          className={cn({
                            [editJobStyles.cardsContainer]: true,
                            [editJobStyles.absolute]: editModalsOpen[idx],
                          })}
                        >
                          {week.items.map((item) => (
                            <SearchActivityCard
                              key={item.worksearch_id}
                              type={item.state}
                              item={item}
                              date={formatDate(item.activity_date)}
                              notClickable={item.activity_type === 'resume_upload'}
                              onClick={() => {
                                if (item.activity_type === 'resume_upload') {
                                  return;
                                }
                                trackEvent('WEEK_VIEWACTIVITY_COMPLETE');
                                setFormSelectedActivities(
                                  formsSelectedActivities
                                    .slice(0, idx)
                                    .concat([item])
                                    .concat(formsSelectedActivities.slice(idx + 1)),
                                  () =>
                                    setEditModalsOpen(
                                      editModalsOpen
                                        .slice(0, idx)
                                        .concat([true])
                                        .concat(editModalsOpen.slice(idx + 1))
                                    )
                                );
                              }}
                              refreshCounter={refreshCounter}
                              setRefreshCounter={setRefreshCounter}
                            />
                          ))}
                          {week.items.length < week.required ? (
                            <SearchActivityCard
                              type="ADD_NEW"
                              title={`[${intl.formatMessage({ id: 'jobSearchActivity.addContactInformation' })}]`}
                              onClick={() => {
                                trackEvent('WEEK_ADDACTIVITY_NEW');
                                setFormSelectedActivities(
                                  formsSelectedActivities
                                    .slice(0, idx)
                                    .concat([false])
                                    .concat(formsSelectedActivities.slice(idx + 1)),
                                  () =>
                                    setEditModalsOpen(
                                      editModalsOpen
                                        .slice(0, idx)
                                        .concat([true])
                                        .concat(editModalsOpen.slice(idx + 1))
                                    )
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <Box m={6} />
                    </Fragment>
                  ))
                : null}
            </>
          )}
        </Container>
      </Box>
      <LoadingDialog open={loadingDialogOpen} />
      <SSNDialog
        open={ssnDialogOpen && !isFetching}
        setOpen={setSsnDialogOpen}
        refreshCounter={refreshCounter}
        setRefreshCounter={setRefreshCounter}
      />
    </>
  );
};

function useStateCallback(initialState) {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null);

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb;
    setState(state);
  }, []);

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null;
    }
  }, [state]);

  return [state, setStateCallback];
}

export default JobSearchActivity;

function LoadingDialog({ open }) {
  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent>
        <Box pt={1} pl={6} pr={6} pb={2}>
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
LoadingDialog.propTypes = {
  open: PropTypes.bool,
};
