import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';

import theme from 'styles/ui-theme';
import CONTINUED_CLAIM_QUESTIONS from 'lib/arknet/continuedClaimQuestions';
import {
  q01ValidationSchema,
  q02ValidationSchema,
  q03ValidationSchema,
  q04ValidationSchema,
  q05ValidationSchema,
  q06ValidationSchema,
  q07ValidationSchema,
  q08ValidationSchema,
  q16ValidationSchema,
} from 'lib/arknet/continuedClaimValidationSchemas';
import DatePickerField from './inputs/DatePickerField';
import SelectField from './inputs/SelectField';
import YesNoRadioGroupField from './inputs/YesNoRadioGroupField';
import NumericInputField from './inputs/NumericInputField';
import TextInputField from './inputs/TextInputField';
import FraudWarningDialog from './FraudWarningDialog';
import { useSelector } from 'react-redux';
import ContinuedClaimProgressTracker from './ContinuedClaimProgressTracker';
import ContinuedClaimConfirmation from './ContinuedClaimConfirmation';

const fakeMutation = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ success: true });
    }, 500);
  });
};

const questionsIdsThatTriggerUiHotline = ['q02_07', 'q03_10', 'q04_13', 'q05_16', 'q06_19', 'q07_22', 'q08_24'];
const totalQuestions = 16;

function calculateProgress(completedQuestions) {
  // 'q09' is an optional question so we don't use it for calculations
  const completedCount = Object.keys(completedQuestions).filter(
    (key) => completedQuestions[key] === 'completed' && key !== 'q09'
  ).length;

  const progress = (completedCount / totalQuestions) * 100;

  return Math.round(progress);
}

function ContinuedClaim() {
  const intl = useIntl();
  const history = useHistory();
  const locale = intl.locale;
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const [completedQuestions, setCompletedQuestions] = useState({});
  const [fraudWarningDialogAnswer, setFraudWarningDialogAnswer] = useState('');

  const updateContinuedClaimMutation = useMutation(fakeMutation);
  const { selectedClaim } = useSelector((state) => state.ui);

  const showUiHotlineQuestionAfterCluster = questionsIdsThatTriggerUiHotline.some(
    (val) => completedQuestions[val] === 'yes'
  );

  function handleAddCompletedQuestion(question, completedSectionId) {
    updateContinuedClaimMutation.mutate(question, {
      onSuccess: () => {
        setCompletedQuestions((prevState) => ({
          ...prevState,
          ...question,
          ...(completedSectionId && { [completedSectionId]: 'completed' }),
        }));

        // Scroll to the top after the user completes the continued claim
        if (completedSectionId === 'q17') {
          window.scrollTo({ top: 0 });
        }
      },
    });
  }

  function handleChangeFraudWarningAnswer(answer) {
    setFraudWarningDialogAnswer(answer);
  }

  const progress = calculateProgress(completedQuestions);

  useEffect(() => {
    if (!selectedClaim.weekEnd) {
      history.push(`${urlPrefix}/ui-dashboard`);
    }
  }, [selectedClaim]);

  return (
    <ThemeProvider
      theme={{
        palette: { ...theme.palette },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
              },
            },
          },
          MuiFormControl: {
            defaultProps: {
              fullWidth: true,
            },
          },
          MuiInputBase: {
            defaultProps: {
              fullWidth: true,
            },
            styleOverrides: {
              root: {
                // Remove up/down arrow dials from input
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none',
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                '&.Mui-disabled': {
                  color: 'rgba(0, 0, 0, 0.55)',
                },
              },
            },
          },
        },
      }}
    >
      <Container maxWidth="lg">
        {completedQuestions['q17'] ? (
          <ContinuedClaimConfirmation />
        ) : (
          <Box sx={{ py: 3.75 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: { md: 'flex-end' },
                flexDirection: { xs: 'column', md: 'row' },
                mb: 2,
              }}
            >
              <Typography variant="h1">
                <AssignmentTwoToneIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
                {intl.formatMessage({ id: 'continuedClaim.title' })}
              </Typography>

              <Button variant="outlined" component={Link} to="/ui-dashboard">
                {intl.formatMessage({ id: 'continuedClaim.cancel' })}
              </Button>
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Alert severity="warning">
              <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
                {intl.formatMessage({ id: 'continuedClaim.cautionAlert.title' })}
              </AlertTitle>
              {intl.formatMessage({ id: 'continuedClaim.cautionAlert.body' })}
            </Alert>

            <Container maxWidth="sm" disableGutters>
              <ContinuedClaimProgressTracker
                title={`${selectedClaim.weekStart} – ${selectedClaim.weekEnd}`}
                value={progress}
              />

              <Stack spacing={2}>
                <Paper sx={{ p: { xs: 2, md: 3 } }}>
                  <Formik
                    initialValues={{ q01_01: '', q01_02: null, q01_03: '', q01_04: '', q01_05: '', q01_06: '' }}
                    validationSchema={q01ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q01: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q01_01"
                            label={CONTINUED_CLAIM_QUESTIONS['q01_01'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q01_01)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                          />

                          {completedQuestions['q01_01'] === 'yes' && (
                            <>
                              <DatePickerField
                                name="q01_02"
                                label={CONTINUED_CLAIM_QUESTIONS['q01_02'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q01_02'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q01_02'])}
                                error={touched['q01_02'] && Boolean(errors['q01_02'])}
                              />

                              <SelectField
                                name="q01_03"
                                label={CONTINUED_CLAIM_QUESTIONS['q01_03'].label[locale]}
                                disabled={Boolean(completedQuestions['q01_03'])}
                                error={touched['q01_03'] && Boolean(errors['q01_03'])}
                                locale={locale}
                                options={CONTINUED_CLAIM_QUESTIONS['q01_03'].options}
                              />

                              {(values.q01_03 === 'Fired' || values.q01_03 === 'Quit' || values.q01_03 === 'Other') && (
                                <YesNoRadioGroupField
                                  name="q01_04"
                                  label={CONTINUED_CLAIM_QUESTIONS['q01_04'].label[locale]}
                                  locale={locale}
                                  disabled={Boolean(completedQuestions['q01_04'])}
                                  error={touched['q01_04'] && Boolean(errors['q01_04'])}
                                />
                              )}

                              <NumericInputField
                                name="q01_05"
                                label={CONTINUED_CLAIM_QUESTIONS['q01_05'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q01_05'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q01_05'])}
                                error={touched['q01_05'] && Boolean(errors['q01_05'])}
                              />

                              <NumericInputField
                                name="q01_06"
                                label={CONTINUED_CLAIM_QUESTIONS['q01_06'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q01_06'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q01_06'])}
                                error={touched['q01_06'] && Boolean(errors['q01_06'])}
                                decimalScale={2}
                              />

                              <Box>
                                <Button
                                  sx={{ mb: 1 }}
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q01']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q01']
                                    ? intl.formatMessage({ id: 'continuedClaim.questions.saved' })
                                    : intl.formatMessage({ id: 'continuedClaim.questions.next' })}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q01'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display: completedQuestions['q01'] ? 'block' : 'none',
                  }}
                >
                  <Typography id="q02_07" gutterBottom color={completedQuestions['q07'] ? 'textSecondary' : 'inherit'}>
                    {intl.formatMessage({ id: 'continuedClaim.questions.q17.label' })}
                  </Typography>
                  <Formik
                    initialValues={{ q02_07: '', q02_08: '', q02_09: '' }}
                    validationSchema={q02ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q02: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q02_07"
                            label={CONTINUED_CLAIM_QUESTIONS['q02_07'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q02_07)}
                            horizontalLayoutEnabled
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            isLoading={
                              updateContinuedClaimMutation.isLoading &&
                              Boolean(updateContinuedClaimMutation.variables['q02_07'])
                            }
                          />

                          {completedQuestions['q02_07'] === 'yes' && (
                            <>
                              <NumericInputField
                                name="q02_08"
                                label={CONTINUED_CLAIM_QUESTIONS['q02_08'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q02_08'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q02_08'])}
                                error={touched['q02_08'] && Boolean(errors['q02_08'])}
                              />

                              <NumericInputField
                                name="q02_09"
                                label={CONTINUED_CLAIM_QUESTIONS['q02_09'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q02_09'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q02_09'])}
                                error={touched['q02_09'] && Boolean(errors['q02_09'])}
                                decimalScale={2}
                              />

                              <Box>
                                <Button
                                  sx={{ mb: 1 }}
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q02']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q02'] ? 'Saved' : 'Next'}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>

                  <Formik
                    initialValues={{ q03_10: '', q03_11: '', q03_12: '' }}
                    validationSchema={q03ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q03: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q03_10"
                            label={CONTINUED_CLAIM_QUESTIONS['q03_10'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q03_10) || !completedQuestions['q02']}
                            horizontalLayoutEnabled
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            isLoading={
                              updateContinuedClaimMutation.isLoading &&
                              Boolean(updateContinuedClaimMutation.variables['q03_10'])
                            }
                          />

                          {completedQuestions['q03_10'] === 'yes' && (
                            <>
                              <NumericInputField
                                name="q03_11"
                                label={CONTINUED_CLAIM_QUESTIONS['q03_11'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q03_11'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q03_11'])}
                                error={touched['q03_11'] && Boolean(errors['q03_11'])}
                              />

                              <NumericInputField
                                name="q03_12"
                                label={CONTINUED_CLAIM_QUESTIONS['q03_12'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q03_12'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q03_12'])}
                                error={touched['q03_12'] && Boolean(errors['q03_12'])}
                                decimalScale={2}
                              />

                              <Box>
                                <Button
                                  sx={{ mb: 1 }}
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q03']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q03'] ? 'Saved' : 'Next'}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>

                  <Formik
                    initialValues={{ q04_13: '', q04_14: '', q04_15: '' }}
                    validationSchema={q04ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q04: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q04_13"
                            label={CONTINUED_CLAIM_QUESTIONS['q04_13'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q04_13) || !completedQuestions['q03']}
                            horizontalLayoutEnabled
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            isLoading={
                              updateContinuedClaimMutation.isLoading &&
                              Boolean(updateContinuedClaimMutation.variables['q04_13'])
                            }
                          />

                          {completedQuestions['q04_13'] === 'yes' && (
                            <>
                              <NumericInputField
                                name="q04_14"
                                label={CONTINUED_CLAIM_QUESTIONS['q04_14'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q04_14'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q04_14'])}
                                error={touched['q04_14'] && Boolean(errors['q04_14'])}
                              />

                              <NumericInputField
                                name="q04_15"
                                label={CONTINUED_CLAIM_QUESTIONS['q04_15'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q04_15'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q04_15'])}
                                error={touched['q04_15'] && Boolean(errors['q04_15'])}
                                decimalScale={2}
                              />

                              <Box>
                                <Button
                                  sx={{ mb: 1 }}
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q04']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q04'] ? 'Saved' : 'Next'}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>

                  <Formik
                    initialValues={{ q05_16: '', q05_17: '', q05_18: '' }}
                    validationSchema={q05ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q05: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q05_16"
                            label={CONTINUED_CLAIM_QUESTIONS['q05_16'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q05_16) || !completedQuestions['q04']}
                            horizontalLayoutEnabled
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            isLoading={
                              updateContinuedClaimMutation.isLoading &&
                              Boolean(updateContinuedClaimMutation.variables['q05_16'])
                            }
                          />

                          {completedQuestions['q05_16'] === 'yes' && (
                            <>
                              <NumericInputField
                                name="q05_17"
                                label={CONTINUED_CLAIM_QUESTIONS['q05_17'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q05_17'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q05_17'])}
                                error={touched['q05_17'] && Boolean(errors['q05_17'])}
                              />

                              <NumericInputField
                                name="q05_18"
                                label={CONTINUED_CLAIM_QUESTIONS['q05_18'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q05_18'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q05_18'])}
                                error={touched['q05_18'] && Boolean(errors['q05_18'])}
                                decimalScale={2}
                              />

                              <Box>
                                <Button
                                  sx={{ mb: 1 }}
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q05']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q05'] ? 'Saved' : 'Next'}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>

                  <Formik
                    initialValues={{ q06_19: '', q06_20: '', q06_21: '' }}
                    validationSchema={q06ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q06: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q06_19"
                            label={CONTINUED_CLAIM_QUESTIONS['q06_19'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q06_19) || !completedQuestions['q05']}
                            horizontalLayoutEnabled
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            isLoading={
                              updateContinuedClaimMutation.isLoading &&
                              Boolean(updateContinuedClaimMutation.variables['q06_19'])
                            }
                          />

                          {completedQuestions['q06_19'] === 'yes' && (
                            <>
                              <NumericInputField
                                name="q06_20"
                                label={CONTINUED_CLAIM_QUESTIONS['q06_20'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q06_20'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q06_20'])}
                                error={touched['q06_20'] && Boolean(errors['q06_20'])}
                              />

                              <NumericInputField
                                name="q06_21"
                                label={CONTINUED_CLAIM_QUESTIONS['q06_21'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q06_21'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q06_21'])}
                                error={touched['q06_21'] && Boolean(errors['q06_21'])}
                                decimalScale={2}
                              />

                              <Box>
                                <Button
                                  sx={{ mb: 1 }}
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q06']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q06'] ? 'Saved' : 'Next'}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>

                  <Formik
                    initialValues={{ q07_22: '', q07_23: '' }}
                    validationSchema={q07ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q07: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q07_22"
                            label={CONTINUED_CLAIM_QUESTIONS['q07_22'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q07_22) || !completedQuestions['q06']}
                            horizontalLayoutEnabled
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            isLoading={
                              updateContinuedClaimMutation.isLoading &&
                              Boolean(updateContinuedClaimMutation.variables['q07_22'])
                            }
                          />

                          {completedQuestions['q07_22'] === 'yes' && (
                            <>
                              <NumericInputField
                                name="q07_23"
                                label={CONTINUED_CLAIM_QUESTIONS['q07_23'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q07_23'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q07_23'])}
                                error={touched['q07_23'] && Boolean(errors['q07_23'])}
                                decimalScale={2}
                              />

                              <Box>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q07']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q07'] ? 'Saved' : 'Next'}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                </Paper>

                <Paper sx={{ p: { xs: 2, md: 3 }, display: completedQuestions['q07'] ? 'block' : 'none' }}>
                  <Formik
                    initialValues={{ q08_24: '', q08_25: '', q08_26: '' }}
                    validationSchema={q08ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q08: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q08_24"
                            label={CONTINUED_CLAIM_QUESTIONS['q08_24'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q08_24)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                          />

                          {completedQuestions['q08_24'] === 'yes' && (
                            <>
                              <NumericInputField
                                name="q08_25"
                                label={CONTINUED_CLAIM_QUESTIONS['q08_25'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q08_25'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q08_25'])}
                                error={touched['q08_25'] && Boolean(errors['q08_25'])}
                              />

                              <NumericInputField
                                name="q08_26"
                                label={CONTINUED_CLAIM_QUESTIONS['q08_26'].label[locale]}
                                helperText={CONTINUED_CLAIM_QUESTIONS['q08_26'].helperText[locale]}
                                disabled={Boolean(completedQuestions['q08_26'])}
                                error={touched['q08_26'] && Boolean(errors['q08_26'])}
                                decimalScale={2}
                              />

                              <Box>
                                <Button
                                  sx={{ mb: 1 }}
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q08']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q08']
                                    ? intl.formatMessage({ id: 'continuedClaim.questions.saved' })
                                    : intl.formatMessage({ id: 'continuedClaim.questions.next' })}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q08'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display: completedQuestions['q08'] && showUiHotlineQuestionAfterCluster ? 'block' : 'none',
                  }}
                >
                  <Formik initialValues={{ q09_27: '' }}>
                    {({ values }) => (
                      <Form>
                        <YesNoRadioGroupField
                          name="q09_27"
                          label={CONTINUED_CLAIM_QUESTIONS['q09_27'].label[locale]}
                          locale={locale}
                          disabled={Boolean(values.q09_27)}
                          handleAddCompletedQuestion={handleAddCompletedQuestion}
                          optionReportingSectionAsCompleted="all"
                        />
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q09'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display:
                      completedQuestions['q09'] || (completedQuestions['q08'] && !showUiHotlineQuestionAfterCluster)
                        ? 'block'
                        : 'none',
                  }}
                >
                  <Formik initialValues={{ q10_28: '', q10_29: '' }}>
                    {({ values }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q10_28"
                            label={CONTINUED_CLAIM_QUESTIONS['q10_28'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q10_28)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            optionReportingSectionAsCompleted="yes"
                          />

                          {completedQuestions['q10_28'] === 'no' && (
                            <>
                              <YesNoRadioGroupField
                                name="q10_29"
                                label={CONTINUED_CLAIM_QUESTIONS['q10_29'].label[locale]}
                                locale={locale}
                                disabled={Boolean(values.q10_29)}
                                handleAddCompletedQuestion={handleAddCompletedQuestion}
                                optionReportingSectionAsCompleted="all"
                              />
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q10'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display: completedQuestions['q10'] ? 'block' : 'none',
                  }}
                >
                  <Formik initialValues={{ q11_30: '', q11_31: '' }}>
                    {({ values }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q11_30"
                            label={CONTINUED_CLAIM_QUESTIONS['q11_30'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q11_30)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                          />

                          {completedQuestions['q11_30'] === 'yes' && (
                            <>
                              <YesNoRadioGroupField
                                name="q11_31"
                                label={CONTINUED_CLAIM_QUESTIONS['q11_31'].label[locale]}
                                locale={locale}
                                disabled={Boolean(values.q11_31)}
                                handleAddCompletedQuestion={handleAddCompletedQuestion}
                                optionReportingSectionAsCompleted="all"
                              />
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q11'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display: completedQuestions['q11'] ? 'block' : 'none',
                  }}
                >
                  <Formik initialValues={{ q12_32: '', q12_33: '' }}>
                    {({ values }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q12_32"
                            label={CONTINUED_CLAIM_QUESTIONS['q12_32'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q12_32)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                          />

                          {completedQuestions['q12_32'] === 'yes' && (
                            <>
                              <YesNoRadioGroupField
                                name="q12_33"
                                label={CONTINUED_CLAIM_QUESTIONS['q12_33'].label[locale]}
                                locale={locale}
                                disabled={Boolean(values.q12_33)}
                                handleAddCompletedQuestion={handleAddCompletedQuestion}
                                optionReportingSectionAsCompleted="all"
                              />
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q12'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display: completedQuestions['q12'] ? 'block' : 'none',
                  }}
                >
                  <Formik initialValues={{ q13_34: '', q13_35: '' }}>
                    {({ values }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q13_34"
                            label={CONTINUED_CLAIM_QUESTIONS['q13_34'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q13_34)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                          />

                          {completedQuestions['q13_34'] === 'yes' && (
                            <>
                              <YesNoRadioGroupField
                                name="q13_35"
                                label={CONTINUED_CLAIM_QUESTIONS['q13_35'].label[locale]}
                                locale={locale}
                                disabled={Boolean(values.q13_35)}
                                handleAddCompletedQuestion={handleAddCompletedQuestion}
                                optionReportingSectionAsCompleted="all"
                              />
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q13'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display: completedQuestions['q13'] ? 'block' : 'none',
                  }}
                >
                  <Formik initialValues={{ q14_36: '', q14_37: '' }}>
                    {({ values }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q14_36"
                            label={CONTINUED_CLAIM_QUESTIONS['q14_36'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q14_36)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                          />

                          {completedQuestions['q14_36'] === 'yes' && (
                            <>
                              <YesNoRadioGroupField
                                name="q14_37"
                                label={CONTINUED_CLAIM_QUESTIONS['q14_37'].label[locale]}
                                locale={locale}
                                disabled={Boolean(values.q14_37)}
                                handleAddCompletedQuestion={handleAddCompletedQuestion}
                                optionReportingSectionAsCompleted="all"
                              />
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q14'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display: completedQuestions['q14'] ? 'block' : 'none',
                  }}
                >
                  <Formik initialValues={{ q15_38: '', q15_39: '' }}>
                    {({ values }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q15_38"
                            label={CONTINUED_CLAIM_QUESTIONS['q15_38'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q15_38)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            optionReportingSectionAsCompleted="yes"
                          />

                          {completedQuestions['q15_38'] === 'no' && (
                            <>
                              <YesNoRadioGroupField
                                name="q15_39"
                                label={CONTINUED_CLAIM_QUESTIONS['q15_39'].label[locale]}
                                locale={locale}
                                disabled={Boolean(values.q15_39)}
                                handleAddCompletedQuestion={handleAddCompletedQuestion}
                                optionReportingSectionAsCompleted="all"
                              />
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q15'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                <Paper sx={{ p: { xs: 2, md: 3 }, display: completedQuestions['q15'] ? 'block' : 'none' }}>
                  <Formik
                    initialValues={{ q16_40: '', q16_41: '' }}
                    validationSchema={q16ValidationSchema}
                    onSubmit={(values) => {
                      updateContinuedClaimMutation.mutate(values, {
                        onSuccess: () =>
                          setCompletedQuestions((prevState) => ({ ...prevState, ...values, q16: 'completed' })),
                      });
                    }}
                  >
                    {({ values, errors, touched }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q16_40"
                            label={CONTINUED_CLAIM_QUESTIONS['q16_40'].label[locale]}
                            locale={locale}
                            disabled={Boolean(values.q16_40)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                          />

                          {completedQuestions['q16_40'] === 'yes' && (
                            <>
                              <TextInputField
                                name="q16_41"
                                label={CONTINUED_CLAIM_QUESTIONS['q16_41'].label[locale]}
                                disabled={Boolean(completedQuestions['q16_41'])}
                                error={touched['q16_41'] && Boolean(errors['q16_41'])}
                              />

                              <Box>
                                <Button
                                  sx={{ mb: 1 }}
                                  type="submit"
                                  variant="contained"
                                  color="secondary"
                                  disabled={
                                    Boolean(completedQuestions['q16']) || updateContinuedClaimMutation.isLoading
                                  }
                                >
                                  {completedQuestions['q16']
                                    ? intl.formatMessage({ id: 'continuedClaim.questions.saved' })
                                    : intl.formatMessage({ id: 'continuedClaim.questions.next' })}
                                </Button>
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q16'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>

                {fraudWarningDialogAnswer === 'disagree' && (
                  <Alert severity="error">
                    <AlertTitle>
                      {intl.formatMessage({ id: 'continuedClaim.fraudWarningDisagreeAlert.title' })}
                    </AlertTitle>
                    <Typography>
                      {intl.formatMessage({ id: 'continuedClaim.fraudWarningDisagreeAlert.subtitle' })}
                    </Typography>
                    <Button
                      component={Link}
                      to="/ui-dashboard"
                      sx={{ mt: 1 }}
                      variant="contained"
                      color="secondary"
                      startIcon={<ArrowBackIcon />}
                    >
                      {intl.formatMessage({ id: 'continuedClaim.fraudWarningDisagreeAlert.cta' })}
                    </Button>
                  </Alert>
                )}

                <Paper
                  sx={{
                    p: { xs: 2, md: 3 },
                    display: completedQuestions['q16'] && fraudWarningDialogAnswer === 'agree' ? 'block' : 'none',
                  }}
                >
                  <Formik initialValues={{ q17_42: '' }}>
                    {({ values }) => (
                      <Form>
                        <Stack spacing={1}>
                          <YesNoRadioGroupField
                            name="q17_42"
                            label={intl.formatMessage(
                              { id: 'continuedClaim.questions.q17_42.label' },
                              { weekStart: selectedClaim.weekStart, weekEnd: selectedClaim.weekEnd }
                            )}
                            locale={locale}
                            disabled={Boolean(values.q17_42)}
                            handleAddCompletedQuestion={handleAddCompletedQuestion}
                            optionReportingSectionAsCompleted="all"
                          />
                        </Stack>
                      </Form>
                    )}
                  </Formik>
                  <LinearProgress
                    sx={{
                      visibility:
                        updateContinuedClaimMutation.isLoading && !completedQuestions['q17'] ? 'visible' : 'hidden',
                    }}
                  />
                </Paper>
              </Stack>

              <Box mt={5} />
            </Container>
          </Box>
        )}

        <FraudWarningDialog
          open={Boolean(completedQuestions['q16']) && !fraudWarningDialogAnswer}
          handleChangeFraudWarningAnswer={handleChangeFraudWarningAnswer}
        />
      </Container>
    </ThemeProvider>
  );
}

export default ContinuedClaim;
