import { useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

function FraudWarningDialog({ open, handleChangeFraudWarningAnswer }) {
  const intl = useIntl();
  const contentRef = useRef(null);

  const [buttonsEnabled, setButtonsEnabled] = useState(false);

  const checkScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    setButtonsEnabled(scrollTop + clientHeight >= scrollHeight);
  };

  useEffect(() => {
    if (contentRef.current) checkScroll(); // Enable if content fits without scroll
  }, [open]);

  return (
    <Dialog maxWidth="sm" open={open} keepMounted>
      <DialogTitle>{intl.formatMessage({ id: 'continuedClaim.fraudWarningDialog.header' })}</DialogTitle>
      <DialogContent ref={contentRef} onScroll={checkScroll}>
        <FormattedMessage
          id="continuedClaim.fraudWarningDialog.body"
          values={{
            p: (chunks) => <Typography gutterBottom>{chunks}</Typography>,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: { xs: 'wrap', md: 'nowrap' },
          pb: 3,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => handleChangeFraudWarningAnswer('disagree')}
          disabled={!buttonsEnabled}
        >
          {intl.formatMessage({ id: 'continuedClaim.fraudWarningDialog.disagree' })}
        </Button>
        <Button variant="contained" onClick={() => handleChangeFraudWarningAnswer('agree')} disabled={!buttonsEnabled}>
          {intl.formatMessage({ id: 'continuedClaim.fraudWarningDialog.agree' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FraudWarningDialog.propTypes = {
  open: PropTypes.bool,
  handleChangeFraudWarningAnswer: PropTypes.func,
};

export default FraudWarningDialog;
