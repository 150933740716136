import { propOr, pathOr } from 'ramda';

/* Core Dependencies */
import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollToTopOnPathChange } from 'components/ScrollToTopButton';

/* i18n Dependencies */
import { useIntl } from 'react-intl';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS, esES } from '@mui/x-date-pickers/locales';
import dateFnsAdapterEsLocale from 'date-fns/locale/es';
/* UI Dependencies */
import { ThemeProvider } from '@mui/material/styles';
import Layout from 'components/layout';
import theme from 'styles/ui-theme';

/* API */
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';

/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';
import { deviceDetect } from 'react-device-detect';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSoc } from 'app/slices/jobsSlice';
import { setExperienceForm, setExperienceHasResume } from 'app/slices/experienceSlice';
import { setActivities, setGuid, setStatus, setLoaded } from 'app/slices/workSearchSlice';
import {
  setLoading,
  setNeedsContactInfo,
  setNeedsOnboarding,
  setName,
  setPreferredName,
  setUserSettings,
} from 'app/slices/appSlice';

/* Pages */
import { About, LaunchHP, TermsOfUse, TermsOfUseES, CreditsCitations } from 'components/pages/unauthenticated';
import OnboardingPagesSwitch from 'components/pages/onboarding/OnboardingPagesSwitch';
import AuthenticatedPagesSwitch from 'components/pages/authenticated/AuthenticatedPagesSwitch';

/* UI Components */
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { configureAuthNew, apiRequest } from 'shared/API';

const englishLocale = enUS.components.MuiLocalizationProvider.defaultProps.localeText;
const spanishLocale = esES.components.MuiLocalizationProvider.defaultProps.localeText;

function App() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { getAccessTokenSilently, isAuthenticated, isLoading: auth0_loading, logout, user: auth0_user } = useAuth0();

  const { loading, needsContactInfo, needsOnboarding, unrecoverableError } = useSelector((state) => state.app);
  const { loaded: worksearchLoaded } = useSelector((state) => state.worksearch);

  const signOut = () => {
    dispatch(setLoading(true));
    logout({
      returnTo: window.location.origin,
      federated: true,
    });
    dispatch(setLoading(false));
  };

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently();
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    configureAuthNew(getAccessToken);
    if (!auth0_loading && isAuthenticated) {
      trackEvent('LOAD', {
        device: deviceDetect(),
        referrer: document.referrer,
        path: window.location.hash,
        language: navigator.language || navigator.userLanguage,
        localtime: Date(),
        username: auth0_user.sub,
      });
    } else {
      if (!auth0_loading) {
        dispatch(setLoading(false));
      }
    }
  }, [auth0_loading, isAuthenticated]);

  const idQuery = useQuery({
    queryKey: ['/users/me'],
    enabled: isAuthenticated,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const additionalRequests = isAuthenticated && idQuery.isSuccess;

  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
    enabled: additionalRequests,
    refetchOnWindowFocus: false,
  });

  const settingsQuery = useQuery({
    queryKey: ['/users/me/settings'],
    enabled: additionalRequests,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (
      idQuery.isSuccess &&
      !experienceQuery.isLoading &&
      experienceQuery.isSuccess &&
      settingsQuery.isSuccess &&
      worksearchLoaded
    ) {
      if (window.dataLayer && idQuery.data?.guid) {
        window.dataLayer.push({
          userId: idQuery.data?.guid,
        });
      }
      dispatch(setPreferredName(propOr('', 'first_name', idQuery.data)));
      dispatch(
        setName({
          first_name: propOr('', 'first_name', idQuery.data),
          last_name: propOr('', 'last_name', idQuery.data),
        })
      );
      dispatch(setUserSettings(settingsQuery.data));
      const has_experience =
        experienceQuery.isSuccess &&
        typeof experienceQuery.data.job_experience != 'undefined' &&
        experienceQuery.data.job_experience.length > 0;
      const has_education = pathOr(false, ['education', '0', 'degree'], experienceQuery.data);

      dispatch(setExperienceForm(experienceQuery.data));
      dispatch(setExperienceHasResume(experienceQuery.data.has_resume));

      if (has_experience) {
        const currentSoc = pathOr('', ['data', 'job_experience', 0, 'soc'], experienceQuery);
        if (currentSoc !== '') {
          dispatch(setCurrentSoc(currentSoc));
          apiRequest('get', `/careers/${currentSoc}`).then((response) => {
            if ('title' in response) {
              dispatch(setExperienceForm({ soc_code: response.soc, soc_title: response.title }));
            }
          });
        }
      }
      //debugger; //eslint-disable-line
      if (!has_experience || !has_education) {
        dispatch(setNeedsOnboarding(true));
      }
      dispatch(setLoading(false));
    }

    if (idQuery.isError) {
      dispatch(setNeedsOnboarding(true));
      dispatch(setNeedsContactInfo(true));
      dispatch(setLoading(false));
    }
  }, [
    idQuery.isSuccess,
    idQuery.isError,
    experienceQuery.isSuccess,
    experienceQuery.isLoading,
    settingsQuery.isSuccess,
    worksearchLoaded,
  ]);

  useEffect(() => {
    if (idQuery.isSuccess) {
      apiRequest('GET', '/users/me/worksearch/').then((res) => {
        if (res.status && res.status.worksearch_enabled) {
          dispatch(setActivities(res.activities));
          dispatch(setGuid(res.guid));
          dispatch(setStatus(res.status));
        } else if (res.status) {
          dispatch(setStatus(res.status));
        }
        dispatch(setLoaded(true));
      });
    }
  }, [idQuery.isSuccess]);

  useEffect(() => {
    /**
     * Configuration of idle time before we track
     * the user as inactive
     */
    TimeMe.initialize({
      idleTimeoutInSeconds: 300, // seconds
    });
  }, []);

  useEffect(() => {
    if (idQuery.isSuccess) {
      TimeMe.callWhenUserLeaves(() => {
        const timeReport = TimeMe.getTimeOnAllPagesInSeconds();
        timeReport.forEach(({ pageName, timeOnPage }) => {
          if (pageName !== 'default-page-name') {
            trackEvent(`TIME_${pageName}_PAGETOTAL`, { timeOnPage: timeOnPage.toString() });
          }
        });
        const timeActiveSession = timeReport.reduce((prev, { timeOnPage }) => prev + timeOnPage, 0);
        trackEvent('TIME_ACTIVE_SESSION', { timeOnPage: timeActiveSession.toString() });
      });

      TimeMe.callWhenUserReturns(() => {
        TimeMe.resetAllRecordedPageTimes();
      });
    }
  }, [idQuery.isSuccess]);

  function render() {
    if (unrecoverableError) {
      return <ErrorMessage />;
    } else if (loading || auth0_loading || (isAuthenticated && !idQuery.isFetched)) {
      return <Loading />;
    } else if (!isAuthenticated) {
      return (
        <Switch>
          <Route path={['/', '/:lang']}>
            <LaunchHP />
          </Route>
        </Switch>
      );
    } else if (needsContactInfo || needsOnboarding) {
      return <OnboardingPagesSwitch />;
    } else {
      return <AuthenticatedPagesSwitch />;
    }
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={intl.locale === 'en' ? undefined : dateFnsAdapterEsLocale}
          localeText={intl.locale === 'en' ? englishLocale : spanishLocale}
        >
          <Layout
            locale={intl.locale}
            loggedIn={isAuthenticated}
            signOut={signOut}
            serverUserLocale={experienceQuery?.data?.language}
          >
            <ScrollToTopOnPathChange />
            <Switch>
              <Route path={['/terms-of-use', '/:lang/terms-of-use']}>
                {intl.locale === 'en' ? <TermsOfUse /> : null}
                {intl.locale === 'es' ? <TermsOfUseES /> : null}
              </Route>
              <Route path={['/credits-and-citations', '/:lang/credits-and-citations']}>
                <CreditsCitations />
              </Route>
              <Route path={['/about', '/:lang/about']}>
                <About />
              </Route>
              <Route>{render()}</Route>
            </Switch>
          </Layout>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
