import { useEffect, useState, useRef } from 'react';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { getBankingInfo } from 'lib/API';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  useMediaQuery,
  IconButton,
  Divider,
} from '@mui/material';
import DepositEnrollCard from 'components/DepositEnrollCard';
import DepositInfoCard from 'components/DepositInfoCard';
import DebitCardCard from 'components/DebitCardCard';
import PaymentMethodAlert from 'components/PaymentMethodAlert';
import DirectDepositEnrollForm from 'components/forms/DirectDepositEnrollForm';
import CloseIcon from '@mui/icons-material/Close';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

import TimeMe from 'timeme.js';

const UpdatePaymentMethod = () => {
  const containerRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);
  const [paymentInfoData, setPaymentInfoData] = useState();
  const [isFetchingBanking, setIsFetchingBanking] = useState(true);
  const [showEnroll, setShowEnroll] = useState(false);

  const intl = useIntl();
  const isSmall = useMediaQuery('(max-width:767px)');

  useEffect(() => {
    getBankingInfo().then((response) => {
      setPaymentInfoData(response.data);
      setIsFetchingBanking(false);
    });
  }, []);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'fileClaim.bankingModal.title' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('UI_FILE_A_CLAIM');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  if (isFetchingBanking) {
    return (
      <Box mt={6} mb={2.5} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 6, mb: 2.5 }}>
        <Typography variant="h1">
          <CreditCardOutlinedIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
          {intl.formatMessage({ id: 'fileClaim.bankingModal.title' })}
        </Typography>
        <Typography variant="body2">{intl.formatMessage({ id: 'payments.dwsPaymentSystem' })}</Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      <PaymentMethodAlert />
      <Box mt={3} mb={3}>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={11}>
            <Typography variant="h5">{intl.formatMessage({ id: 'fileClaim.bankingModal.paymentDetails' })}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2.5} sx={{ pt: 2 }}>
          <Grid item xs={12} md={4}>
            <DebitCardCard paymentInfoData={paymentInfoData} />
          </Grid>
          <Grid item xs={12} md={4}>
            {paymentInfoData?.current_payment === 'DD' ? (
              <DepositInfoCard
                setPaymentInfoData={setPaymentInfoData}
                paymentInfoData={paymentInfoData}
                setShowEnroll={setShowEnroll}
              />
            ) : (
              <DepositEnrollCard paymentInfoData={paymentInfoData} setShowEnroll={setShowEnroll} />
            )}
          </Grid>
        </Grid>
      </Box>
      <Dialog open={showEnroll} onClose={() => setShowEnroll(false)} maxWidth="md" fullWidth>
        <DialogTitle variant="h4" component="h2">
          {intl.formatMessage({ id: 'fileClaim.bankingModal.title' })}
          <IconButton
            aria-label="close"
            onClick={() => setShowEnroll(false)}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent ref={containerRef} sx={{ position: 'relative', minHeight: isSmall ? 400 : 540 }}>
          <Box
            sx={{
              bgcolor: 'white',
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              pt: 1,
              px: 3,
              boxSizing: 'border-box',
            }}
          >
            <DirectDepositEnrollForm
              setPaymentInfoData={setPaymentInfoData}
              submitting={submitting}
              setSubmitting={setSubmitting}
              setOpen={setShowEnroll}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default UpdatePaymentMethod;
