import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    selectedClaim: {
      weekStart: null,
      weekEnd: null,
      activities: [],
    },
    claims: [],
  },
  reducers: {
    setSelectedClaim: (state, action) => {
      state.selectedClaim = action.payload;
    },
    setClaims: (state, action) => {
      state.claims = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedClaim, setClaims } = uiSlice.actions;

export default uiSlice.reducer;
