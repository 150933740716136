import Joyride from 'react-joyride';
import { Typography } from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import { setNewUser } from 'app/slices/appSlice';

export default function NewUserTutorial() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const preferredName = useSelector((state) => state.app.preferredName);
  const stepLocale = {
    skip: intl.formatMessage({ id: 'newUserTutorial.skip' }),
    next: intl.formatMessage({ id: 'forms.onboarding.next' }),
    back: intl.formatMessage({ id: 'forms.onboarding.back' }),
    last: intl.formatMessage({ id: 'newUserTutorial.getStarted' }),
  };
  const steps = [
    {
      target: 'body',
      content: (
        <Typography sx={{ textAlign: 'left' }}>
          {intl.formatMessage({ id: 'newUserTutorial.step1' }, { name: preferredName })}
        </Typography>
      ),
      placement: 'center',
    },
    {
      target: '#experience-menu-item',
      content: (
        <Typography sx={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'newUserTutorial.step2' })}</Typography>
      ),
    },
    {
      target: '#jobs-menu-item',
      content: (
        <Typography sx={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'newUserTutorial.step4' })}</Typography>
      ),
    },
    {
      target: '#explore-careers-menu-item',
      content: (
        <Typography sx={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'newUserTutorial.step3' })}</Typography>
      ),
      placement: 'top',
    },
    {
      target: '#training-menu-item',
      content: (
        <Typography sx={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'newUserTutorial.step5' })}</Typography>
      ),
    },
  ];

  return (
    <Joyride
      locale={stepLocale}
      steps={steps}
      showSkipButton
      continuous
      hideCloseButton
      styles={{ options: { primaryColor: '#007041' } }}
      callback={({ status, action }) => {
        if (['skipped', 'ready', 'finished'].includes(status) || action === 'close') {
          dispatch(setNewUser(false));
          if (window) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }
      }}
    />
  );
}
