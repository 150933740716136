import { Box, Card, CardContent, CardHeader, lighten, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { grey } from '@mui/material/colors';

/* Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

/* i18n */
import { useIntl } from 'react-intl';

export default function DebitCardCard({ paymentInfoData: { current_payment } }) {
  const intl = useIntl();

  const headerContent = (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '33px' }}>
      {current_payment === 'DB' && <CheckCircleIcon sx={{ color: 'success.main', fontSize: 22 }} />}{' '}
      {current_payment === 'DD' && <CancelOutlinedIcon sx={{ color: 'error.main', fontSize: 22 }} />}{' '}
      <Typography sx={{ color: current_payment === 'DB' ? 'success.main' : 'error.main', fontWeight: 700, pl: 0.5 }}>
        {intl.formatMessage({
          id: current_payment === 'DB' ? 'fileClaim.bankingModal.currentMethod' : 'fileClaim.bankingModal.notInUse',
        })}
      </Typography>
    </Box>
  );

  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardHeader
        title={headerContent}
        sx={{
          py: 1,
          bgcolor: (theme) =>
            lighten(current_payment === 'DB' ? theme.palette.success.main : theme.palette.error.main, 0.95),
        }}
      />
      <CardContent sx={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: grey['300'] }}>
        <Typography variant="h3" sx={{ textAlign: 'center', pt: 2.5 }}>
          {intl.formatMessage({ id: 'fileClaim.bankingModal.debitCard' })}
        </Typography>
      </CardContent>
    </Card>
  );
}

DebitCardCard.propTypes = {
  paymentInfoData: PropTypes.shape({
    account_number: PropTypes.string,
    routing_number: PropTypes.number,
    account_type: PropTypes.string,
    current_payment: PropTypes.string,
  }),
};
