import { FormControl, FormLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Field } from 'formik';
import PropTypes from 'prop-types';

export default function DatePickerField({ name, label, helperText, disabled, error }) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl disabled={disabled} error={error}>
          <FormLabel htmlFor={field.name}>{label}</FormLabel>
          <DatePicker
            aria-labelledby={field.name}
            name={field.name}
            value={field.value}
            onChange={form.getFieldHelpers(field.name).setValue}
            disabled={disabled}
            disableFuture
            slotProps={{
              textField: {
                helperText,
                id: field.name,
                error: error,
              },
            }}
          />
        </FormControl>
      )}
    </Field>
  );
}

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};
