import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import React from 'react';

const PhoneNumberInput = React.forwardRef(function PhoneNumberInput(props, ref) {
  const { onChange, name, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={ref}
      displayType={'input'}
      type={'tel'}
      format="(###) ###-####"
      placeholder="(###) ###-####"
      mask="_"
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
    />
  );
});

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default PhoneNumberInput;
