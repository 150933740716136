import { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { trackEvent } from 'lib/analytics';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { apiRequest } from 'lib/API';

/* Material UI and other UI Dependencies */
import { Typography, Autocomplete, Radio, FormControlLabel, RadioGroup, Chip } from '@mui/material';
import OnboardingSteps from 'components/OnboardingSteps';
import {
  Box,
  FormControl,
  TextField,
  FormHelperText,
  useMediaQuery,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { preventSubmitOnEnter } from 'shared/utils';

export default function OnboardingLocation() {
  const isSmall = useMediaQuery('(max-width:767px)');
  const [submitting, setSubmitting] = useState(false);

  const citiesQuery = useQuery({
    queryKey: ['/cities/'],
  });

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();

  const initialValues = {
    current_city: null,
    desired_commute_mi: '',
    willing_to_relocate: null,
    alternate_cities: [],
  };

  const queryClient = useQueryClient();

  return (
    <Box sx={{ boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.1)' }}>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom sx={{ fontSize: 24, mt: 1 }}>
            {intl.formatMessage({ id: 'onboarding.title' }, { step: 4, totalSteps: 5 })}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: 222, marginLeft: 'auto', marginRight: 'auto', mt: 4, mb: 1 }}>
          <OnboardingSteps activeStep={4} />
        </Box>
      </div>
      <hr className={layoutStyles.separatorBold} />
      <Box className={styles.onboardingExperience__cardsContainer} sx={{ maxWidth: 500, margin: 'auto' }}>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.current_city) {
              errors.current_city = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            if (!values.desired_commute_mi) {
              errors.desired_commute_mi = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            if (!values.willing_to_relocate) {
              errors.willing_to_relocate = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            if (values.willing_to_relocate === 'yes' && !values.alternate_cities?.length) {
              errors.alternate_cities = intl.formatMessage({ id: 'jobSearchActivity.form.fieldRequired' });
            }
            return errors;
          }}
          onSubmit={(values) => {
            setSubmitting(true);
            const valuesToSubmit = {
              current_city: values.current_city.id,
              ...(values.desired_commute_mi ? { desired_commute_mi: Number(values.desired_commute_mi) } : {}),
              ...(values.willing_to_relocate ? { willing_to_relocate: values.willing_to_relocate === 'yes' } : {}),
              ...(values.alternate_cities?.length
                ? { alternate_cities: values.alternate_cities.map(({ id }) => id) }
                : {}),
            };
            trackEvent('ONBOARDING_LOCATION_NEXT');
            apiRequest('PUT', '/users/me/location/', {
              data: valuesToSubmit,
            })
              .then(() => {
                setSubmitting(false);
                queryClient.invalidateQueries({ queryKey: ['/users/me/location/'] });
                history.push(`${urlPrefix}/onboarding/goals`);
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Typography align="center" variant="h5" gutterBottom px={{ xs: 1, md: 6 }}>
                {intl.formatMessage({ id: 'onboarding.location.citySubtitle' })}
              </Typography>
              <Box mb={3} />
              <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 700 }}>
                  {intl.formatMessage({ id: 'onboarding.location.city' })}
                  <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                    *
                  </Typography>
                </Typography>
                <Autocomplete
                  id="where-located-onboard-dropdown"
                  options={citiesQuery?.data ?? []}
                  getOptionLabel={({ city }) => city}
                  getOptionKey={({ id }) => id}
                  value={values.current_city ?? null}
                  sx={{
                    '.MuiFilledInput-root:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label={intl.formatMessage({ id: 'onboarding.location.cityLabel' })}
                      helperText={touched.current_city && errors.current_city ? errors.current_city : ''}
                      error={touched.current_city && Boolean(errors.current_city)}
                      id="where-located-onboard-dropdown"
                    />
                  )}
                  onChange={(_, value) => setFieldValue('current_city', value)}
                  classes={{
                    paper: 'autocomplete-paper-border',
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* Distance */}
                <Box>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({
                      id: 'onboarding.location.distance',
                    })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <FormControl error={!!touched.desired_commute_mi && !!errors.desired_commute_mi} variant="filled">
                    <InputLabel id="travel-distance-label">
                      {intl.formatMessage({ id: 'onboarding.location.distanceLabel' })}
                    </InputLabel>
                    <Select
                      labelId="travel-distance-label"
                      value={values.desired_commute_mi}
                      onChange={(evt) => setFieldValue('desired_commute_mi', evt.target.value)}
                      id="travel-for-work-onboard-dropdown"
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          variant: 'outlined',
                        },
                        sx: {
                          maxHeight: 440,
                          '& .MuiPaper-root': {
                            borderRight: '1px solid #ccc', // Set the border style and color
                            borderBottom: '1px solid #ccc', // Set the border style and color
                            borderLeft: '1px solid #ccc', // Set the border style and color
                          },
                        },
                      }}
                      sx={{
                        '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                        '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                      }}
                      onKeyDown={preventSubmitOnEnter}
                    >
                      <MenuItem value="0">
                        <em>{intl.formatMessage({ id: 'onboarding.location.myCityOnly' })}</em>
                      </MenuItem>
                      <MenuItem value="1">
                        {intl.formatMessage({ id: 'onboarding.location.within' })} 1{' '}
                        {intl.formatMessage({ id: 'onboarding.location.mile' })} (1.6km)
                      </MenuItem>
                      <MenuItem value="5">
                        {intl.formatMessage({ id: 'onboarding.location.within' })} 5{' '}
                        {intl.formatMessage({ id: 'onboarding.location.miles' })} (8km)
                      </MenuItem>
                      <MenuItem value="10">
                        {intl.formatMessage({ id: 'onboarding.location.within' })} 10{' '}
                        {intl.formatMessage({ id: 'onboarding.location.miles' })} (16km)
                      </MenuItem>
                      <MenuItem value="25">
                        {intl.formatMessage({ id: 'onboarding.location.within' })} 25{' '}
                        {intl.formatMessage({ id: 'onboarding.location.miles' })} (40km)
                      </MenuItem>
                      <MenuItem value="50">
                        {intl.formatMessage({ id: 'onboarding.location.within' })} 50{' '}
                        {intl.formatMessage({ id: 'onboarding.location.miles' })} (80km)
                      </MenuItem>
                      <MenuItem value="100">
                        {intl.formatMessage({ id: 'onboarding.location.within' })} 100{' '}
                        {intl.formatMessage({ id: 'onboarding.location.miles' })} (160km)
                      </MenuItem>
                      <MenuItem value="200">
                        {intl.formatMessage({ id: 'onboarding.location.within' })} 200{' '}
                        {intl.formatMessage({ id: 'onboarding.location.miles' })} (321km)
                      </MenuItem>
                    </Select>
                    {!!touched.desired_commute_mi && !!errors.desired_commute_mi && (
                      <FormHelperText>
                        {errors.desired_commute_mi ? intl.formatMessage({ id: errors.desired_commute_mi }) : ''}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                {/* Willing to relocate */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: 'onboarding.location.willingToRelocate' })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <Box>
                    <FormControl error={!!touched.willing_to_relocate && !!errors.willing_to_relocate}>
                      <RadioGroup
                        aria-label={intl.formatMessage({ id: 'onboarding.location.willingToRelocate' })}
                        name="willing_to_relocate"
                        value={values.willing_to_relocate}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" id="yes-relocate-onboard-radio-button" />}
                          label={intl.formatMessage({ id: 'jobSearchActivity.form.yes' })}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" id="no-relocate-onboard-radio-button" />}
                          label={intl.formatMessage({ id: 'jobSearchActivity.form.no' })}
                        />
                      </RadioGroup>
                      {!!touched.willing_to_relocate && !!errors.willing_to_relocate && (
                        <FormHelperText>
                          {errors.willing_to_relocate ? intl.formatMessage({ id: errors.willing_to_relocate }) : ''}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Box>
                {/* Cities to relocate */}
                <Box
                  sx={{
                    display: values.willing_to_relocate === 'yes' ? 'flex' : 'none',
                    mb: 1,
                    flexDirection: 'column',
                  }}
                >
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({ id: 'onboarding.location.whichCities' })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <Autocomplete
                    multiple
                    id="relocation-city-onboard-dropdown"
                    options={citiesQuery?.data ?? []}
                    getOptionLabel={({ city }) => city}
                    getOptionKey={({ id }) => id}
                    value={values.alternate_cities}
                    renderTags={(value, getTagProps) => {
                      return (
                        <div>
                          {value.map((option, index) => {
                            const { className, ...rest } = getTagProps({ index }); //eslint-disable-line

                            return (
                              <Chip
                                key={option}
                                size="small"
                                label={value[index].city}
                                {...rest}
                                sx={{ maxWidth: 180, px: 0, my: 0.5 }}
                              />
                            );
                          })}
                        </div>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label={intl.formatMessage({ id: 'onboarding.location.addCity' })}
                        id="relocation-city-onboard-dropdown"
                        helperText={touched.alternate_cities && errors.alternate_cities ? errors.alternate_cities : ''}
                        error={touched.alternate_cities && Boolean(errors.alternate_cities)}
                      />
                    )}
                    onChange={(_, value) => setFieldValue('alternate_cities', value)}
                    classes={{
                      paper: 'autocomplete-paper-border',
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        backgroundColor: 'white',
                        paddingTop: '18px!important',
                      },
                      '& .MuiAutocomplete-input': {
                        fontSize: 12,
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        paddingRight: '0 !important',
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  marginTop: isSmall ? 10 : 0,
                  justifyContent: isSmall ? 'space-between' : 'flex-end',
                }}
              >
                <Button
                  color={'secondary'}
                  disabled={submitting}
                  size="large"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    history.push(`${urlPrefix}/onboarding/education`);
                  }}
                  id="back-onboard-step-4-button"
                >
                  {intl.formatMessage({ id: 'forms.onboarding.back' })}
                </Button>

                <Button
                  color={'secondary'}
                  disabled={submitting}
                  size="large"
                  type="submit"
                  variant="contained"
                  id="next-onboard-step-5-button"
                >
                  {submitting ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                    </div>
                  ) : (
                    intl.formatMessage({ id: 'forms.onboarding.next' })
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
