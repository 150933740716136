import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiRequest } from '../../API/wrapper';

const queryKey = ['/users/me/worksearch/'];

export const usePostUpdateWorkSearch = (editting, cb) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values) => {
      return apiRequest(editting ? 'put' : 'post', queryKey[0], { data: { activity: values } });
    },
    onSuccess: (data) => {
      cb();
      if (editting) {
        queryClient.setQueryData(queryKey, (old) => {
          return {
            ...old,
            ...data,
            ...{
              activities: old.activities.map((activity) => {
                if (activity.worksearch_id === data?.activity?.worksearch_id) {
                  return { ...activity, ...data?.activity };
                }
                return activity;
              }),
            },
          };
        });
      } else {
        queryClient.setQueryData(queryKey, (old) => {
          return {
            ...old,
            ...data,
            ...{ activities: old.activities.concat(data.activity) },
          };
        });
      }
    },
  });
};
