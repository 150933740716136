import PropTypes from 'prop-types';

import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';

export default function MailingAddressCard({ mailingAddressData, callBack, title }) {
  const intl = useIntl();
  return (
    <Card elevation={3}>
      <CardContent sx={{ px: 3, pt: 3 }}>
        <Typography variant="h4" component="h2" sx={{ pb: 1, color: 'secondary.dark' }}>
          {title ?? intl.formatMessage({ id: 'fileClaim.currentAddress' })}
        </Typography>
        <Typography variant="body1">
          {mailingAddressData.addr_line}
          {mailingAddressData.addr_line2 ? `, ${mailingAddressData.addr_line2}` : ''}
        </Typography>
        <Typography variant="body1">
          {' '}
          {mailingAddressData.city}, {mailingAddressData.state} {mailingAddressData.zip}
        </Typography>
      </CardContent>
      <CardActions sx={{ px: 3, pb: 3 }}>
        <Button variant="contained" size="small" onClick={() => callBack()}>
          {intl.formatMessage({ id: 'fileClaim.buttons.edit' })}
        </Button>
      </CardActions>
    </Card>
  );
}

MailingAddressCard.propTypes = {
  title: PropTypes.string,
  mailingAddressData: PropTypes.shape({
    addr_line: PropTypes.string.isRequired,
    addr_line2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }),
  callBack: PropTypes.func,
};
