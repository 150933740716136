import { useAuth0 } from '@auth0/auth0-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/slick-theme-overrides.css';
import PropTypes from 'prop-types';

/* i18n */
import { useIntl } from 'react-intl';

/* Redux */
import { useDispatch } from 'react-redux';
import { setLoading } from 'app/slices/appSlice';

/* Styles */
import useMediaQuery from '@mui/material/useMediaQuery';

/* Material UI and other UI Dependencies */
import { Container, Box, Button, Typography, Card, CardMedia, Stack, useTheme, lighten } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import JobsCard from 'shared/src/components/JobsCard';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Assets */
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import RouteIcon from '@mui/icons-material/Route';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LooksOneTwoToneIcon from '@mui/icons-material/LooksOneTwoTone';
import LooksTwoTwoToneIcon from '@mui/icons-material/LooksTwoTwoTone';
import Looks3TwoToneIcon from '@mui/icons-material/Looks3TwoTone';
import Looks4TwoToneIcon from '@mui/icons-material/Looks4TwoTone';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import SupportIcon from '@mui/icons-material/Support';
import LaunchIcon from '@mui/icons-material/Launch';

const environment = process.env.REACT_APP_ENVIRONMENT;

function LaunchHP() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loginWithRedirect } = useAuth0();
  const isSmallScreen = useMediaQuery('(max-width:959px)');
  const { palette } = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleLogin = () => {
    dispatch(setLoading(true));
    trackEvent(`LOGIN_AUTH0`);
    loginWithRedirect({
      ...(environment === 'prod' && { connection: 'Login-gov' }),
      ...(environment === 'uat' && { connection: 'Login-Gov-Test' }),
      prompt: 'login',
    });
  };

  const getJobCard = (item) => (
    <JobsCard
      key={item.id}
      item={item}
      rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
      onButtonClick={() => {}}
      onLikeClick={() => {}}
      onDislikeClick={() => {}}
      homePage
    />
  );

  return (
    <>
      <Box
        sx={{
          bgcolor: lighten(palette.info.main, 0.9),
          color: 'cssVars.black',
          h1: {
            position: 'relative',
            display: 'inline',
            fontSize: 36,
          },
        }}
      >
        <Container maxWidth="lg" sx={{ pt: 7, pb: { xs: 8, md: 6 } }}>
          <Box sx={{ width: '100%', fontWeight: 500, fontSize: 30 }}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid xs={12} md={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                  <Typography variant="h1" sx={{ fontSize: 36, mb: 2 }} component="div" color="secondary">
                    {intl.formatMessage({ id: 'homepage.hero.title' })}
                  </Typography>

                  <Typography sx={{ fontSize: 30, lineHeight: '36px', mb: 4, color: 'secondary.main' }}>
                    {intl.formatMessage(
                      { id: 'homepage.hero.discover' },
                      {
                        yourSkills: (
                          <Typography
                            variant="inherit"
                            component="span"
                            sx={{ color: 'secondary.main', display: 'inline' }}
                          >
                            {intl.formatMessage({ id: 'homepage.hero.yourSkills' })}
                          </Typography>
                        ),
                      }
                    )}
                  </Typography>

                  <Box>
                    <Button
                      variant="contained"
                      color="info"
                      sx={{
                        fontSize: 20,
                        padding: '8px 24px',
                      }}
                      onClick={handleLogin}
                      id="where-skills-take-you-public-button"
                    >
                      {intl.formatMessage({ id: 'homepage.hero.cta' })}
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={0} md={4}>
                <picture>
                  {/* WebP format for modern browsers */}
                  <source srcSet="/images/hp-hero-image.webp" type="image/webp" />
                  {/* PNG fallback for browsers that don't support WebP */}
                  <source srcSet="/images/hp-hero-image.png" type="image/png" />

                  <img
                    src="/images/hp-hero-image.png"
                    alt="People and brand logos"
                    loading="eager"
                    width={400}
                    height={400}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                    }}
                  />
                </picture>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      {/* Companies' Logos */}
      <Box>
        <Container maxWidth="lg" sx={{ pt: 4, pb: 6, '& button:before, & button': { display: 'none' } }}>
          <Typography variant="h4" component="h2" sx={{ textAlign: 'center', pb: 4, pt: 2 }}>
            {intl.formatMessage(
              { id: 'homepage.companies' },
              {
                number: (
                  <Typography component="span" variant="h4" sx={{ color: 'secondary.main' }}>
                    1,000+
                  </Typography>
                ),
              }
            )}
          </Typography>
          {isSmallScreen ? (
            <Slider slidesToShow={3} slidesToScroll={1} infinite centerMode autoplay pauseOnHover>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 90 }}>
                  <img
                    src="/images/tyson-foods-logo.svg"
                    style={{ width: '70%' }}
                    alt={intl.formatMessage({ id: 'homepage.imgAlt.tysonFoods' })}
                  />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 90 }}>
                  <img
                    src="/images/accenture-logo.png"
                    style={{ width: '70%' }}
                    alt={intl.formatMessage({ id: 'homepage.imgAlt.accenture' })}
                  />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 90 }}>
                  <img
                    src="/images/pwc-logo.svg"
                    style={{ width: '70%' }}
                    alt={intl.formatMessage({ id: 'homepage.imgAlt.pwc' })}
                  />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 90 }}>
                  <img
                    src="/images/oracle-logo.png"
                    style={{ width: '70%' }}
                    alt={intl.formatMessage({ id: 'homepage.imgAlt.oracle' })}
                  />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 90 }}>
                  <img
                    src="/images/auto-zone-logo.png"
                    style={{ width: '70%' }}
                    alt={intl.formatMessage({ id: 'homepage.imgAlt.autoZone' })}
                  />
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 90 }}>
                  <img
                    src="/images/ar-state-gov-logo.jpg"
                    style={{ width: '70%' }}
                    alt={intl.formatMessage({ id: 'homepage.imgAlt.arState' })}
                  />
                </Box>
              </Box>
            </Slider>
          ) : (
            <Grid container spacing={0}>
              <Grid xs={2} pl={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/images/tyson-foods-logo.svg"
                  style={{ width: '50%' }}
                  alt={intl.formatMessage({ id: 'homepage.imgAlt.tysonFoods' })}
                />
              </Grid>
              <Grid xs={2} pl={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/images/accenture-logo.png"
                  style={{ width: '50%' }}
                  alt={intl.formatMessage({ id: 'homepage.imgAlt.accenture' })}
                />
              </Grid>
              <Grid xs={2} pl={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/images/pwc-logo.svg"
                  style={{ width: '50%' }}
                  alt={intl.formatMessage({ id: 'homepage.imgAlt.pwc' })}
                />
              </Grid>
              <Grid xs={2} pl={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/images/oracle-logo.png"
                  style={{ width: '50%' }}
                  alt={intl.formatMessage({ id: 'homepage.imgAlt.oracle' })}
                />
              </Grid>
              <Grid xs={2} pl={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/images/auto-zone-logo.png"
                  style={{ width: '50%' }}
                  alt={intl.formatMessage({ id: 'homepage.imgAlt.autoZone' })}
                />
              </Grid>
              <Grid xs={2} pl={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/images/ar-state-gov-logo.jpg"
                  style={{ width: '50%' }}
                  alt={intl.formatMessage({ id: 'homepage.imgAlt.arState' })}
                />
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
      {/* Three Items */}
      <Box sx={{ pt: { xs: 6, md: 10 }, pb: { xs: 6, md: 10 } }} style={{ backgroundColor: '#f9f9fa' }}>
        <Container maxWidth="lg" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Grid container sx={{ width: '100%' }} spacing={isMobile ? 4 : 5}>
            <Grid xs={12} md={4} mb={3}>
              <FeatureCard
                icon={<RouteIcon sx={{ fontSize: 45, color: 'primary.main' }} />}
                title={intl.formatMessage({ id: 'homepage.middle.p1Title' })}
                subtitle={intl.formatMessage({ id: 'homepage.middle.p1' }, { count: '400+' })}
                p={intl.formatMessage({ id: 'homepage.bottom.p1' })}
              />
            </Grid>
            <Grid xs={12} md={4} mb={3}>
              <FeatureCard
                icon={<HandshakeIcon sx={{ fontSize: 45, color: 'primary.main' }} />}
                title={intl.formatMessage({ id: 'homepage.middle.p2Title' })}
                subtitle={intl.formatMessage({ id: 'homepage.middle.p2' }, { count: '400+' })}
                p={intl.formatMessage({ id: 'homepage.bottom.p2' })}
              />
            </Grid>
            <Grid xs={12} md={4} mb={3}>
              <FeatureCard
                icon={<LocalLibraryIcon sx={{ fontSize: 45, color: 'primary.main' }} />}
                title={intl.formatMessage({ id: 'homepage.middle.p3Title' })}
                subtitle={intl.formatMessage({ id: 'homepage.middle.p3' }, { count: '400+' })}
                p={intl.formatMessage({ id: 'homepage.bottom.p3' })}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '40px 0 40px',
        }}
      >
        <Container maxWidth="lg" sx={{ pt: { xs: 2, md: 6 }, pb: { xs: 2, md: 6 }, alignItems: 'center' }}>
          <Grid container spacing={3}>
            <Grid xs={12} md={5} sx={{ pt: { md: 3.5 }, display: 'flex', justifyContent: 'center' }}>
              <Box
                sx={{
                  maxWidth: { xs: '100%', md: 350, margin: { xs: 'auto', md: 'initial' } },
                }}
              >
                <CardMedia component="img" image="/images/women-hiw.png" alt="Women" />
              </Box>
            </Grid>
            <Grid xs={12} md={7}>
              <Box>
                <Typography variant="h2" sx={{ fontSize: 32, mb: 3, fontWeight: 500 }}>
                  {intl.formatMessage({ id: 'homepage.benefits.title' })}
                </Typography>

                <Stack spacing={2} mb={3}>
                  <MechanicStep
                    icon={<LooksOneTwoToneIcon color="secondary" sx={{ fontSize: '1.5rem' }} />}
                    title={intl.formatMessage({ id: 'homepage.benefits.b1' })}
                  />
                  <MechanicStep
                    icon={<LooksTwoTwoToneIcon color="secondary" sx={{ fontSize: '1.5rem' }} />}
                    title={intl.formatMessage({ id: 'homepage.benefits.b2' })}
                  />
                  <MechanicStep
                    icon={<Looks3TwoToneIcon color="secondary" sx={{ fontSize: '1.5rem' }} />}
                    title={intl.formatMessage({ id: 'homepage.benefits.b3' })}
                  />
                  <MechanicStep
                    icon={<Looks4TwoToneIcon color="secondary" sx={{ fontSize: '1.5rem' }} />}
                    title={intl.formatMessage({ id: 'homepage.benefits.b4' })}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth={isMobile}
                    onClick={handleLogin}
                    sx={{ fontWeight: 700 }}
                    id="log-in-how-it-works-public-button"
                  >
                    {intl.formatMessage({ id: 'homepage.login' })}
                  </Button>

                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth={isMobile}
                    onClick={handleLogin}
                    sx={{ fontWeight: 700 }}
                    id="sign-up-how-it-works-public-button"
                  >
                    {intl.formatMessage({ id: 'homepage.signup' })}
                  </Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {false && (
        <Box sx={{ backgroundColor: '#e7f5ff' }}>
          <Container maxWidth="lg" sx={{ pt: 4, pb: 10 }}>
            <Typography variant="h4" component="h2" sx={{ textAlign: 'center', pb: 4, pt: 2 }}>
              {intl.formatMessage({ id: 'homepage.openRoles' })}
            </Typography>
            <Slider slidesToShow={1} slidesToScroll={1} infinite={false} dots>
              {!isSmallScreen
                ? sampleJobs.map((arr) => (
                    <Box sx={{ py: 2.5 }} key={arr[0]?.id}>
                      <Grid container spacing={2}>
                        {arr.map((item) => getJobCard(item))}
                      </Grid>
                    </Box>
                  ))
                : sampleJobs.flat().map((item) => getJobCard(item))}
            </Slider>
          </Container>
        </Box>
      )}
      <Box sx={{ backgroundColor: '#f9f9fa' }}>
        <Container maxWidth="md" sx={{ pt: 7, pb: 10 }} className="slider-container">
          <Typography variant="h2" sx={{ fontSize: 32, mb: 4, fontWeight: 500, textAlign: 'center' }}>
            {intl.formatMessage({ id: 'homepage.additionalResources' })}
          </Typography>
          <Grid container spacing={4} mt={1}>
            <Grid xs={12} lg={6}>
              <Card elevation={3} sx={{ p: 3, boxShadow: `rgba(0, 0, 0, 0.3) 2px 2px 5px -1px` }}>
                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                  <DashboardOutlinedIcon fontSize="large" sx={{ fontSize: 45, color: 'secondary.dark' }} />
                  <Box>
                    <Typography variant="h3" fontWeight="bold" sx={{ mb: 1.5, color: 'tertiary.main' }}>
                      {intl.formatMessage({ id: 'homepage.additionalRecs1.title' })}
                    </Typography>

                    <Button
                      variant="outlined"
                      href={'https://myardashboard.arkansas.gov/'}
                      target="_blank"
                      rel="noopener noreferrer"
                      endIcon={<LaunchIcon />}
                      sx={{ borderColor: (theme) => theme.palette.primary.main }}
                      id="workforce-dashboard-public-button"
                    >
                      {intl.formatMessage({ id: 'homepage.additionalRecs.cta' })}
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid xs={12} lg={6}>
              <Card elevation={3} sx={{ p: 3, boxShadow: `rgba(0, 0, 0, 0.3) 2px 2px 5px -1px` }}>
                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                  <SupportIcon fontSize="large" sx={{ fontSize: 45, color: 'secondary.dark' }} />
                  <Box>
                    <Typography variant="h3" fontWeight="bold" sx={{ mb: 1.5, color: 'tertiary.main' }}>
                      {intl.formatMessage({ id: 'homepage.additionalRecs2.title' })}
                    </Typography>

                    <Button
                      variant="outlined"
                      href="https://myarciviform.arkansas.gov/programs"
                      target="_blank"
                      rel="noopener noreferrer"
                      endIcon={<LaunchIcon />}
                      sx={{ borderColor: (theme) => theme.palette.primary.main }}
                      id="support-services-public-button"
                    >
                      {intl.formatMessage({ id: 'homepage.additionalRecs.cta' })}
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

const sampleJobs = [
  [
    {
      id: '1',
      title: 'Piedmont Pilot Cadet Program',
      title_es: 'Programa de Cadetes Piloto de Piamonte',
      org: 'Piedmont Airlines',
      soc_name: 'Police and Sheriff’s Patrol Officers',
      soc_name_es: 'Oficiales de Patrulla de Policía y Alguaciles',
      average_pay_state: '39000',
      city: 'Little Rock',
    },
    {
      id: '2',
      title: 'Senior Principal Software Engineer Health Data and Intelligence',
      title_es: 'Ingeniero Principal de Software Senior Datos e Inteligencia de Salud',
      org: 'Oracle',
      soc_name: 'Private Detectives and Investigators',
      soc_name_es: 'Detectives Privados e Investigadores',
      average_pay_state: '75000',
      city: 'Little Rock',
    },
    {
      id: '3',
      title: 'Industrial Client Service Leader',
      title_es: 'Líder de Servicio al Cliente Industrial',
      org: 'CDM Smith',
      soc_name: 'First-Line Supervisors of Non-Retail Sale...',
      soc_name_es: 'Supervisores de Primera Línea de Ventas no Minoristas',
      average_pay_state: '67000',
      city: 'Fort Smith',
    },
  ],
  [
    {
      id: '4',
      title: 'IT/GIS Product Owner II',
      title_es: 'Propietario de Producto TI/GIS II',
      org: 'Weyerhaeuser',
      soc_name: 'First-Line Supervisors of Production and ...',
      soc_name_es: 'Supervisores de Primera Línea de Producción y ...',
      average_pay_state: '57000',
      city: 'Hot Springs',
    },
    {
      id: '5',
      title: 'Payroll Specialist',
      title_es: 'Especialista en Nómina',
      org: 'Canon USA & Affiliates',
      soc_name: 'Payroll and Timekeeping Clerks',
      soc_name_es: 'Empleados de Nómina y Cronometraje',
      average_pay_state: '41000',
      city: 'Little Rock',
    },
    {
      id: '6',
      title: 'Associated Outside Operator',
      title_es: 'Operador Externo Asociado',
      org: 'Associated Electric Cooperative, Inc.',
      soc_name: 'Chemical Equipment Operators and Ten...',
      soc_name_es: 'Operador de Equipos Químicos y ...',
      average_pay_state: '48000',
      city: 'Dell',
    },
  ],
  [
    {
      id: '7',
      title: 'Part-Time Nabisco Merchandiser',
      title_es: 'Comerciante de Nabisco a Tiempo Parcial',
      org: 'Mondelez International',
      soc_name: 'Sales Representatives, Wholesale and ...',
      soc_name_es: 'Representantes de ventas, mayoristas y ...',
      average_pay_state: '57000',
      city: 'Fort Smith',
    },
    {
      id: '8',
      title: 'Entry Level Background Investigator-Southeast Region',
      title_es: 'Investigador de Antecedentes de Nivel inicial-Región Sudeste',
      org: 'CACI International',
      soc_name: 'Private Detectives and Investigators',
      soc_name_es: 'Detectives Privados e Investigadores',
      average_pay_state: '75000',
      city: 'Little Rock',
    },
    {
      id: '9',
      title: 'Representative-Admissions',
      title_es: 'Representante-Admisiones',
      org: 'Baptist Memorial',
      soc_name: 'Interviewers, Except Eligibility and Loan',
      soc_name_es: 'Entrevistadores, Excepto Elegibilidad y Préstamo',
      average_pay_state: '36000',
      city: 'Jonesboro',
    },
  ],
  [
    {
      id: '10',
      title: 'Route Sales Support Driver',
      title_es: 'Conductor de Soporte de Ventas en Ruta',
      org: 'Vestis Services',
      soc_name: 'Driver/Sales Workers',
      soc_name_es: 'Conductor / Trabajadores de Ventas',
      average_pay_state: '31000',
      city: 'Texarkana',
    },
  ],
];

function MechanicStep(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {props.icon}
      <Typography variant="h6" component="p" fontWeight="regular">
        {props.title}
      </Typography>
    </Box>
  );
}
MechanicStep.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
};

function FeatureCard(props) {
  return (
    <Card elevation={3} sx={{ pt: 3, px: 3, pb: 0.5, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
        {props.icon}
        <Typography variant="h3" fontWeight="bold" sx={{ color: 'primary.main' }}>
          {props.title}
        </Typography>
      </Box>

      <Typography sx={{ fontSize: 18 }}>{props.p}</Typography>
    </Card>
  );
}

FeatureCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  p: PropTypes.string,
  icon: PropTypes.node,
};

export default LaunchHP;
