import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { css, cx } from '@emotion/css';
import { Formik, FieldArray } from 'formik';
import { pathOr, equals, assocPath, propOr } from 'ramda';
import PropTypes from 'prop-types';

/* API */
import { updateExperience } from 'shared/src/lib/API/experience';

/* Styles */
import cssVars from 'styles/vars.module.scss';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import { setExperienceForm } from 'app/slices/experienceSlice';
import { setCurrentSoc } from 'app/slices/jobsSlice';
import { setJobExperience } from 'app/slices/onboardingSlice';

/* i18n */
import { useIntl } from 'react-intl';

import {
  Box,
  FormControl,
  FormControlLabel,
  TextField,
  Checkbox,
  useMediaQuery,
  Button,
  Typography,
  RadioGroup,
  Radio,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MYInput from './inputs/MYInput';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Utils */
import { preventSubmitOnEnter } from 'shared/utils';

const styles = {
  formContainer: css({
    maxWidth: '500px',
    margin: '0 auto',
  }),
  helperText: css({
    padding: '0',
    position: 'absolute',
    top: '100%',
    right: '0',
    lineHeight: '1.25',
    '@media screen and (max-width: 671px)': {
      margin: '0 5px',
    },
  }),
  textFieldWhite: css({
    backgroundColor: 'white !important',
    borderRadius: '3px 3px 0 0 !important',
    input: {
      backgroundColor: 'white',
      borderRadius: '4px 4px 0 0',
    },
  }),
  formControlRoot: css({
    display: 'inline-block !important',
    width: 'auto !important', //overriding style defined in ui-theme.js
    marginBottom: '0 !important', //overriding style defined in ui-theme.js
  }),
  formControlLabelRoot: css({
    display: 'inline-block',
    marginRight: '6px !important',
    '@media screen and (max-width: 767px)': {
      marginRight: '3px !important',
    },
  }),
  jobContainer: css({
    border: 'solid 1px #DBDBDB',
    padding: '8px',
    position: 'relative',
    '@media screen and (min-width: 672px)': {
      padding: '1.15em',
      paddingBottom: '1em',
    },
  }),
  jobContainerRed: css({
    border: `solid 1px ${cssVars.errorColorMain}`,
  }),
  jobExperienceRemoveButton: css({
    position: 'absolute',
    right: '-12px',
    top: '-12px',
    border: `solid 2px ${cssVars.actionBlue}`,
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    fontSize: '10px',
    padding: 0,
    backgroundColor: cssVars.white,
    svg: {
      fontSize: '20.3px',
      color: cssVars.actionBlue,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: cssVars.lightGray,
    },
  }),
  formControlLabeLabel: css({
    fontSize: '0.8rem !important',
    color: cssVars.darkGray,
    '@media screen and (max-width: 767px)': {
      fontSize: '0.68rem !important',
    },
  }),
  formError: css({
    color: cssVars.errorColorMain,
    fontSize: '0.8rem',
    marginTop: '0.5rem',
    position: 'relative',
    top: '-10px',
  }),
};

const basicJobItem = {
  branch_of_service: null,
  employment_type: null,
  end_date: '',
  rank: null,
  start_date: '',
  title: '',
  work_schedule: null,
};

export const initialExperienceFormValues = {
  job_experience: [{ ...basicJobItem }],
};

export function formatDateInverse(dateStr) {
  if (/\d{4}-\d{2}-\d{2}/.test(dateStr)) {
    // This is the format returned in API v2
    const [year, month] = dateStr.split('-');
    return month + '/' + year;
  }
  if (dateStr.length !== 8 && dateStr.length !== 6) {
    return '';
  }
  let year = dateStr.substring(0, 4);
  let month = dateStr.substring(4, 6);
  return month + '/' + year;
}

export function formatDate(dateStr) {
  let date = new Date(dateStr);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  return `${year}${month}${day}`;
}

export function getExperienceInitialValues(value) {
  const locality = process.env.REACT_APP_LOCALITY;
  const isEmpty = (val) => !val;
  const isBlankJob = (jobValues) => Object.values(jobValues).every(isEmpty);
  let experienceInitialValues = { ...initialExperienceFormValues };

  const dateFromMmYyyy = (dateStr) =>
    new Date(dateStr.slice(0, 4), parseInt(dateStr.slice(4, 6)) - 1, dateStr.slice(6));

  const fromSeverValues = { job_experience: [] };
  if ('job_experience' in value) {
    //Most recent job first
    value.job_experience.sort((a, b) => {
      const dateA = a.end_date ? dateFromMmYyyy(a.end_date) : dateFromMmYyyy(a.start_date);
      const dateB = b.end_date ? dateFromMmYyyy(b.end_date) : dateFromMmYyyy(b.start_date);
      return dateB - dateA;
    });

    if (value.job_experience.length > 0) {
      value.job_experience.forEach((job) => {
        if (isBlankJob(job)) {
          return;
        }
        fromSeverValues.job_experience.push({
          ...job,
          start_date: job.start_date ? formatDateInverse(job.start_date) : '',
          end_date: job.end_date ? formatDateInverse(job.end_date) : '',
        });
      });
    }
  }

  if (localStorage && !fromSeverValues.job_experience.length && locality === 'hi') {
    const lsProfile = JSON.parse(localStorage.getItem('ui_profile')) || {};
    if (['job_title', 'emp_start_date', 'emp_end_date'].some((k) => k in lsProfile)) {
      fromSeverValues.job_experience.push({
        title: lsProfile.job_title || '',
        start_date: lsProfile.emp_start_date ? formatDateInverse(lsProfile.emp_start_date) : '',
        end_date: lsProfile.emp_end_date ? formatDateInverse(lsProfile.emp_end_date) : '',
      });
    }
  }

  if (fromSeverValues.job_experience.length > 0) {
    return fromSeverValues;
  }
  return { ...experienceInitialValues };
}

function isFutureDate(dateStr) {
  const month = dateStr.slice(0, 2);
  const year = dateStr.slice(3, 7);
  const originalDate = new Date(year, parseInt(month) - 1);
  const date = new Date();
  const currentDate = new Date(date.getFullYear(), date.getMonth());
  return originalDate > currentDate;
}

export const makevalidate = (currentJobs) => (values) => {
  let errors = {};

  const dateRegExp = /(0[1-9]|10|11|12)\/(19|20)[0-9]{2}$/;

  values.job_experience.forEach((job, idx) => {
    if (!job.title) {
      errors = assocPath(['job_experience', idx, 'title'], 'jobSearchActivity.form.fieldRequired', errors);
    } else if (job.title.length < 3) {
      errors = assocPath(['job_experience', idx, 'title'], 'forms.onboarding.minLenght', errors);
    }

    if (!job.start_date) {
      errors = assocPath(['job_experience', idx, 'start_date'], 'jobSearchActivity.form.fieldRequired', errors);
    } else if (!dateRegExp.test(job.start_date)) {
      errors = assocPath(['job_experience', idx, 'start_date'], 'onboarding.experience.mmyyFormat', errors);
    } else if (isFutureDate(job.start_date)) {
      errors = assocPath(['job_experience', idx, 'start_date'], 'onboarding.experience.dateInFuture', errors);
    }

    if (!currentJobs[idx]) {
      if (!job.end_date) {
        errors = assocPath(['job_experience', idx, 'end_date'], 'jobSearchActivity.form.fieldRequired', errors);
      } else if (!dateRegExp.test(job.end_date)) {
        errors = assocPath(['job_experience', idx, 'end_date'], 'onboarding.experience.mmyyFormat', errors);
      } else if (isFutureDate(job.end_date)) {
        errors = assocPath(['job_experience', idx, 'end_date'], 'onboarding.experience.dateInFuture', errors);
      } else {
        const start = new Date(job.start_date.split('/').join('/01/'));
        const end = new Date(job.end_date.split('/').join('/01/'));
        if (start > end) {
          errors = assocPath(['job_experience', idx, 'end_date'], 'onboarding.experience.endDatePrior', errors);
        }
      }
    }
  });

  return errors;
};

export const JobDateRange = ({
  formik,
  index,
  isCurrentJob,
  onboarding,
  setFormError,
  handleDiffs,
  choseToStay,
  keysWithChanges,
}) => {
  const intl = useIntl();

  const isFirstSection = index === 0;
  const intlKey = isFirstSection ? 'recentJobTitle' : 'otherJob';

  const formikJobTitleKey = `job_experience[${index}].title`;
  const formikStartDateKey = `job_experience[${index}].start_date`;
  const formikEndDateKey = `job_experience[${index}].end_date`;

  const hasTitleError =
    pathOr(false, ['job_experience', index, 'title'], formik.touched) &&
    !!pathOr(false, ['job_experience', index, 'title'], formik.errors);
  const choseStayChangedTitle = choseToStay && keysWithChanges.includes(formikJobTitleKey);
  const hasFromError =
    pathOr(false, ['job_experience', index, 'start_date'], formik.touched) &&
    !!pathOr(false, ['job_experience', index, 'start_date'], formik.errors);
  const choseStayChangedStartDate = choseToStay && keysWithChanges.includes(formikStartDateKey);
  const hasToError =
    pathOr(false, ['job_experience', index, 'end_date'], formik.touched) &&
    !!pathOr(false, ['job_experience', index, 'end_date'], formik.errors);
  const choseStayChangedEndDate = choseToStay && keysWithChanges.includes(formikEndDateKey);
  const hasDateError = hasToError || hasFromError;

  return (
    <>
      <TextField
        FormHelperTextProps={{ className: styles.helperText }}
        error={hasTitleError || choseStayChangedTitle}
        name={formikJobTitleKey}
        type="text"
        variant="filled"
        classes={{ root: onboarding ? null : styles.textFieldWhite }}
        fullWidth
        helperText={
          hasTitleError &&
          intl.formatMessage({ id: pathOr('', ['job_experience', index, 'title'], formik.errors) }, { length: 3 })
        }
        autoComplete="off"
        label={
          <>
            {intl.formatMessage({ id: `onboarding.experience.${intlKey}` })}
            <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
          </>
        }
        value={pathOr('', ['values', 'job_experience', index, 'title'], formik)}
        onChange={(e) => {
          formik.handleChange(e);
          handleDiffs(index, 'title', e);
        }}
        onKeyDown={preventSubmitOnEnter}
        onBlur={formik.handleBlur}
        inputProps={{ title: intl.formatMessage({ id: `onboarding.experience.${intlKey}` }) }}
      />
      <Box display="flex" mb={hasDateError ? 1 : 0}>
        <TextField
          FormHelperTextProps={{ className: styles.helperText }}
          error={hasFromError || choseStayChangedStartDate}
          name={formikStartDateKey}
          type="text"
          variant="filled"
          classes={{ root: onboarding ? null : styles.textFieldWhite }}
          fullWidth
          helperText={
            hasFromError &&
            intl.formatMessage(
              { id: pathOr('', ['job_experience', index, 'start_date'], formik.errors) },
              { length: 3 }
            )
          }
          autoComplete="off"
          label={
            <>
              {intl.formatMessage({ id: 'onboarding.experience.started' })}
              <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
            </>
          }
          value={pathOr('', ['values', 'job_experience', index, 'start_date'], formik)}
          onChange={(e) => {
            setFormError('');
            formik.handleChange(e);
            handleDiffs(index, 'start_date', e);
          }}
          onKeyDown={preventSubmitOnEnter}
          onBlur={formik.handleBlur}
          InputProps={{ inputComponent: MYInput }}
          inputProps={{
            title: intl.formatMessage({ id: 'onboarding.experience.started' }),
            date_placeholder: intl.formatMessage({ id: 'onboarding.experience.mmyyPlaceholder' }),
            date_mask: intl.formatMessage({ id: 'onboarding.experience.mmyyMask' }),
          }}
        />
        {!isCurrentJob && (
          <>
            <Box px={{ xs: 1, sm: 3 }} style={{ position: 'relative', top: 10 }}>
              {intl.formatMessage({ id: 'jobs.filters.to' })}
            </Box>
            <TextField
              FormHelperTextProps={{ className: styles.helperText }}
              error={hasToError || choseStayChangedEndDate}
              name={formikEndDateKey}
              type="text"
              variant="filled"
              classes={{ root: onboarding ? null : styles.textFieldWhite }}
              fullWidth
              helperText={
                hasToError &&
                intl.formatMessage(
                  { id: pathOr('', ['job_experience', index, 'end_date'], formik.errors) },
                  { length: 3 }
                )
              }
              autoComplete="off"
              label={
                <>
                  {intl.formatMessage({ id: 'onboarding.experience.ended' })}
                  <span style={{ color: propOr('red', 'errorColorMain', cssVars) }}>*</span>
                </>
              }
              value={pathOr('', ['values', 'job_experience', index, 'end_date'], formik)}
              onChange={(e) => {
                setFormError('');
                formik.handleChange(e);
                handleDiffs(index, 'end_date', e);
              }}
              onKeyDown={preventSubmitOnEnter}
              onBlur={formik.handleBlur}
              InputProps={{ inputComponent: MYInput }}
              inputProps={{
                title: intl.formatMessage({ id: 'onboarding.experience.ended' }),
                date_placeholder: intl.formatMessage({ id: 'onboarding.experience.mmyyPlaceholder' }),
                date_mask: intl.formatMessage({ id: 'onboarding.experience.mmyyMask' }),
              }}
            />
          </>
        )}
      </Box>
    </>
  );
};

export const CurrentJobCheckbox = ({ isCurrentJob, setIsCurrentJobHandler, isSmall, index, onboarding }) => {
  const intl = useIntl();

  const boxProps = isSmall
    ? { marginTop: '-12px' }
    : { marginTop: '-18px', display: 'flex', justifyContent: onboarding ? 'flex-end' : 'flex-start' };

  return (
    <Box {...boxProps}>
      <FormControl
        classes={{
          root: styles.formControlRoot,
        }}
      >
        <FormControlLabel
          classes={{
            root: styles.formControlLabelRoot,
            label: 'MuiTypography-caption',
          }}
          label={intl.formatMessage({ id: 'onboarding.experience.currentJob' })}
          control={
            <Checkbox
              color="primary"
              checked={isCurrentJob}
              onChange={(e) => {
                if (e.currentTarget.checked) {
                  trackEvent(`ONBOARDING_EXPERIENCE_CURRENT_JOB_${index}`);
                }
                setIsCurrentJobHandler(e.currentTarget.checked);
              }}
            />
          }
          labelPlacement="end"
        />
      </FormControl>
    </Box>
  );
};

const FullPartTime = ({ formik, index, isSmall, handleDiffs }) => {
  const intl = useIntl();

  const formikKey = `job_experience[${index}].work_schedule`;

  const handleLabelClick = (currentLabel) => {
    const currentValue = pathOr('', ['values', 'job_experience', index, 'work_schedule'], formik);
    if (currentLabel === currentValue) {
      formik.setFieldValue(formikKey, '', false);
      formik.setFieldTouched(formikKey, false, false);
    }
    handleDiffs(index, 'work_schedule', {
      target: { value: currentLabel === currentValue ? '' : currentLabel },
    });
  };
  const handleValueChanged = () => {
    trackEvent(`ONBOARDING_EXPERIENCE_JOB${index}_FTPT`);
  };

  return (
    <>
      <div>
        <Typography variant="caption" style={{ color: '#000' }}>
          {intl.formatMessage({ id: 'forms.onboarding.fullPartTime' })}
        </Typography>
        <RadioGroup
          key={`${formikKey}-selected-${
            pathOr('', ['values', 'job_experience', index, 'work_schedule'], formik) || 'none'
          }`}
          row={!isSmall}
          name={formikKey}
          value={pathOr('', ['values', 'job_experience', index, 'work_schedule'], formik)}
          onChange={(e) => {
            handleValueChanged(); // When adding the track event to handleLabelClick it was triggered twice
            formik.handleChange(e);
          }}
        >
          <FormControlLabel
            classes={{ label: styles.formControlLabeLabel }}
            onClick={() => handleLabelClick('fullTime')}
            value="fullTime"
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'forms.onboarding.fullTime' })}
          />
          <FormControlLabel
            classes={{ label: styles.formControlLabeLabel }}
            onClick={() => handleLabelClick('partTime')}
            value="partTime"
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'forms.onboarding.partTime' })}
          />
        </RadioGroup>
      </div>
    </>
  );
};

const ExperienceType = ({ formik, index, isSmall, onboarding, handleDiffs, choseToStay, keysWithChanges }) => {
  const intl = useIntl();

  const formikKey = `job_experience[${index}].employment_type`;

  const handleLabelClick = (currentLabel) => {
    const currentValue = pathOr('', ['values', 'job_experience', index, 'employment_type'], formik);
    if (currentLabel === currentValue) {
      formik.setFieldValue(formikKey, '', false);
      formik.setFieldTouched(formikKey, false, false);
    }
    handleDiffs(index, 'employment_type', {
      target: { value: currentLabel === currentValue ? '' : currentLabel },
    });
  };
  const handleValueChanged = () => {
    trackEvent(`ONBOARDING_EXPERIENCE_JOB${index}_TYPE`);
  };

  const employmentTypeValue = pathOr('', ['values', 'job_experience', index, 'employment_type'], formik);
  return (
    <>
      <div>
        <Typography variant="caption" style={{ color: '#000' }}>
          {intl.formatMessage({ id: 'onboarding.experience.isWas' })}
        </Typography>
        <RadioGroup
          key={`${formikKey}-selected-${employmentTypeValue || 'none'}`}
          row={!isSmall}
          name={formikKey}
          value={employmentTypeValue}
          onChange={(e) => {
            handleValueChanged(); // When adding the track event to handleLabelClick it was triggered twice
            formik.handleChange(e);
            handleDiffs(index, 'employment_type', e);
          }}
        >
          <FormControlLabel
            classes={{ label: styles.formControlLabeLabel }}
            onClick={() => handleLabelClick('apprenticeship')}
            value="apprenticeship"
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'onboarding.experience.apprenticeship' })}
          />
          <FormControlLabel
            classes={{ label: styles.formControlLabeLabel }}
            onClick={() => handleLabelClick('internship')}
            value="internship"
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'onboarding.experience.internship' })}
          />
          <FormControlLabel
            classes={{ label: styles.formControlLabeLabel }}
            onClick={() => handleLabelClick('military')}
            value="military"
            control={<Radio color="primary" />}
            label={intl.formatMessage({ id: 'onboarding.experience.military' })}
          />
        </RadioGroup>
      </div>
      {employmentTypeValue === 'military' && (
        <Box mt={1}>
          <TextField
            FormHelperTextProps={{ className: styles.helperText }}
            name={`job_experience[${index}].branch_of_service`}
            type="text"
            variant="filled"
            classes={{ root: onboarding ? null : styles.textFieldWhite }}
            fullWidth
            autoComplete="off"
            label={intl.formatMessage({ id: 'onboarding.experience.military.branchOfService' })}
            value={pathOr('', ['values', 'job_experience', index, 'branch_of_service'], formik)}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs(index, 'branch_of_service', e);
            }}
            onKeyDown={preventSubmitOnEnter}
            onBlur={formik.handleBlur}
            inputProps={{ title: intl.formatMessage({ id: 'onboarding.experience.military.branchOfService' }) }}
          />
          <TextField
            FormHelperTextProps={{ className: styles.helperText }}
            name={`job_experience[${index}].rank`}
            type="text"
            variant="filled"
            classes={{ root: onboarding ? null : styles.textFieldWhite }}
            fullWidth
            autoComplete="off"
            label={intl.formatMessage({ id: 'onboarding.experience.military.rank' })}
            value={pathOr('', ['values', 'job_experience', index, 'rank'], formik)}
            onChange={(e) => {
              formik.handleChange(e);
              handleDiffs(index, 'rank', e);
            }}
            onKeyDown={preventSubmitOnEnter}
            onBlur={formik.handleBlur}
            inputProps={{ title: intl.formatMessage({ id: 'onboarding.experience.military.rank' }) }}
          />
        </Box>
      )}
    </>
  );
};

OnboardingExperienceForm.propTypes = {
  workHistory: PropTypes.object,
  redirect: PropTypes.bool,
  submitLabel: PropTypes.string,
  classNames: PropTypes.string,
  submitCallback: PropTypes.func,
  onboarding: PropTypes.bool,
  valueChanged: PropTypes.func,
  lengthChanged: PropTypes.func,
  currentJobsChanged: PropTypes.func,
  choseToStay: PropTypes.bool,
  showTitle: PropTypes.bool,
};
export function OnboardingExperienceForm({
  workHistory,
  redirect = true,
  submitLabel,
  classNames,
  submitCallback = () => {},
  onboarding = false,
  valueChanged = () => {},
  lengthChanged = () => {},
  currentJobsChanged = () => {},
  showTitle = false,
  choseToStay,
}) {
  const locality = process.env.REACT_APP_LOCALITY;
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [currentJobs, setCurrentJobs] = useState(
    workHistory.job_experience.map((job) => job.start_date && !job.end_date)
  );
  const { job_experience } = useSelector((state) => state.onboarding);
  const [initialJobCount] = useState(workHistory.length);
  const [formError, setFormError] = useState('');
  const [keysWithChanges, setKeysWithChanges] = useState([]);
  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();
  const isSmall = useMediaQuery('(max-width:767px)');

  let initialValues = {
    ...(job_experience?.job_experience?.length ? job_experience : workHistory),
  };
  const [initialFormikValues, setInitialFormikValues] = useState({ ...initialValues });

  const validate = makevalidate(currentJobs);
  const makeSetCurrentJob = (idx) => (val) => {
    const arrayCopy = [...currentJobs];
    arrayCopy[idx] = val;
    setCurrentJobs(arrayCopy);
  };

  useEffect(() => {
    if (!workHistory.empty && initialFormikValues.empty) {
      setInitialFormikValues({ ...workHistory });
    }
  }, [workHistory]);

  useEffect(() => {
    let changed = false;
    const keysWithChangesSet = new Set(keysWithChanges);
    currentJobs.forEach((currentJob, index) => {
      if (
        currentJob !==
        (pathOr(false, ['job_experience', index, 'start_date'], initialFormikValues) &&
          !pathOr(false, ['job_experience', index, 'end_date'], initialFormikValues))
      ) {
        changed = true;
        keysWithChangesSet.add(`job_experience[${index}].current_job`);
      } else {
        keysWithChangesSet.delete(`job_experience[${index}].current_job`);
      }
    });

    if (changed) {
      keysWithChangesSet.delete('dummy');
      setKeysWithChanges([...keysWithChangesSet]);
    } else {
      keysWithChangesSet.add('dummy');
      setKeysWithChanges([...keysWithChangesSet]);
    }
    currentJobsChanged(changed);
  }, [currentJobs]);

  const handleDiffs = (formik) => (index, fieldName, evt) => {
    const initialValues = initialFormikValues.job_experience.reduce((acc, curr, idx) => {
      const newKeys = {};
      for (const key in curr) {
        newKeys[`job_experience[${idx}].${key}`] = curr[key];
      }
      return { ...acc, ...newKeys };
    }, {});
    const currentValues = assocPath(
      ['job_experience', index, fieldName],
      evt.target.value,
      formik.values
    ).job_experience.reduce((acc, curr, idx) => {
      const newKeys = {};
      for (const key in curr) {
        newKeys[`job_experience[${idx}].${key}`] = curr[key];
      }
      return { ...acc, ...newKeys };
    }, {});

    if (equals(initialValues, currentValues)) {
      setKeysWithChanges(['dummy_values']);
      valueChanged(false);
    } else {
      const differentKeys = [];
      for (const key in initialValues) {
        if (initialValues[key] !== currentValues[key]) {
          differentKeys.push(key);
        }
      }
      setKeysWithChanges(differentKeys);
      valueChanged(true);
    }
  };

  return (
    <>
      {showTitle && !submitting ? (
        <>
          <Typography align="center" variant="h5" gutterBottom>
            {intl.formatMessage({
              id: 'onboarding.experience.enterJobExperience',
            })}
          </Typography>
          <Box mb={3} />
          <p className={styles.formContainer}>
            {intl.formatMessage({
              id: 'onboarding.experience.upToThree',
            })}
          </p>
          <Box mb={3} />
        </>
      ) : null}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={async ({ ...vals }) => {
          setFormError('');
          if (redirect) {
            // Only do this during onboarding
            trackEvent('ONBOARDING_EXPERIENCE_NEXT');
            trackEvent('ONBOARDING_EXPERIENCE_JOB_1');
          }
          const allowedFields = [
            'title',
            'start_date',
            'end_date',
            'work_schedule',
            'employment_type',
            'branch_of_service',
            'rank',
          ];
          let data = { job_experience: [] };

          propOr([], 'job_experience', vals).forEach((job, idx) => {
            for (const k of Object.keys(job)) {
              if (allowedFields.includes(k) && pathOr(false, ['job_experience', idx, k], vals)) {
                let fieldValue = job[k];
                if (k === 'start_date' || k == 'end_date') {
                  fieldValue = formatDate(job[k].split('/').join('/01/'));
                }
                data = assocPath(['job_experience', idx, k], fieldValue, data);
              }
              // Events
              if (
                (pathOr(false, ['job_experience', idx, 'branch_of_service'], vals) ||
                  pathOr(false, ['job_experience', idx, 'rank'], vals)) &&
                redirect
              ) {
                trackEvent(`ONBOARDING_EXPERIENCE_JOB${idx + 1}_MILITARY`);
              }
            }
          });

          // More events
          if (
            pathOr(0, ['job_experience', 'length'], vals) > 1 &&
            pathOr(false, ['job_experience', 1, 'start_date'], vals) &&
            redirect
          ) {
            trackEvent('ONBOARDING_EXPERIENCE_JOB_2');
          }
          if (
            pathOr(0, ['job_experience', 'length'], vals) > 2 &&
            pathOr(false, ['job_experience', 2, 'start_date'], vals) &&
            redirect
          ) {
            trackEvent('ONBOARDING_EXPERIENCE_JOB_3');
          }

          setSubmitting(true);

          updateExperience(data, redirect ? { on_boarding: true } : {})
            .then((response) => {
              const socCode = pathOr(false, ['value', 'soc_code'], response);
              if (socCode) {
                dispatch(setCurrentSoc(socCode));
              }
              dispatch(setExperienceForm({ job_experience: response.job_experience }));
              setSubmitting(false);
              if (redirect) {
                history.push(`${urlPrefix}/onboarding/education`);
              }
              setInitialFormikValues({ ...vals });
              valueChanged(false);
              lengthChanged(false);
              currentJobsChanged(false);
              setKeysWithChanges([]);
              dispatch(setJobExperience({}));
              submitCallback();
            })
            .catch((e) => {
              const errorMessage = pathOr('', ['response', 'data', 'error_message'], e) || e?.message;
              const matches = errorMessage.match(
                /Could not find a SOC code matching experience item with title '([\w\d\sáéíóúÁÉÍÓÚüÜ¿?!¡]+)'/
              );
              const matchesFuture = errorMessage.match(/is in the future$/);
              if (matches && matches[1]) {
                setFormError(
                  intl.formatMessage(
                    {
                      id: 'onboarding.experience.jobNotFound',
                    },
                    { name: matches[1] }
                  )
                );
              } else if (matchesFuture) {
                setFormError(intl.formatMessage({ id: 'onboarding.experience.dateInFuture' }));
              } else {
                setFormError(intl.formatMessage({ id: 'onboarding.experience.unexpectedError' }));
              }
              setSubmitting(false);
            });
        }}
      >
        {(formikProps) => {
          if (submitting && redirect) {
            return (
              <Box m={2} pb={6} pt={4} display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            );
          }
          return (
            <form
              onSubmit={(e) => {
                formikProps.handleSubmit(e);
                setInitialFormikValues({ ...formikProps.values });
                valueChanged(false);
              }}
              className={classNames}
            >
              <FieldArray
                name="job_experience"
                render={(arrayHelpers) => {
                  const experienceLength = pathOr(0, ['values', 'job_experience', 'length'], formikProps);
                  return (
                    <div>
                      {experienceLength > 0
                        ? formikProps.values.job_experience.map((job, idx) => (
                            <>
                              {idx > 0 ? <Box mb={4} /> : null}
                              <div
                                className={cx({
                                  [styles.jobContainer]: true,
                                  [styles.jobContainerRed]:
                                    choseToStay &&
                                    (keysWithChanges.some((k) => k.startsWith(`job_experience[${idx}]`)) ||
                                      (keysWithChanges.includes(`job_experience[${idx}].current_job`) &&
                                        pathOr(false, ['job_experience', idx, 'title'], initialFormikValues))),
                                })}
                              >
                                {experienceLength > 1 ? (
                                  <button
                                    title={intl.formatMessage({ id: 'experience.remove' })}
                                    type="button"
                                    className={styles.jobExperienceRemoveButton}
                                    onClick={() => {
                                      setFormError('');
                                      if (redirect) {
                                        trackEvent('ONBOARDING_EXPERIENCE_REMOVE'); // Only do this during onboarding
                                      }
                                      if (experienceLength - 1 !== initialJobCount) {
                                        lengthChanged(true);
                                      } else {
                                        lengthChanged(false);
                                      }
                                      arrayHelpers.remove(idx);
                                      const currentJobsCopy = [...currentJobs];
                                      currentJobsCopy.splice(idx, 1);
                                      setCurrentJobs(currentJobsCopy);
                                    }}
                                    tabIndex={0}
                                  >
                                    <CloseIcon />
                                  </button>
                                ) : null}
                                <JobExperienceFormSection
                                  key={idx}
                                  setFormError={setFormError}
                                  formik={formikProps}
                                  index={idx}
                                  isCurrentJob={currentJobs[idx]}
                                  setIsCurrentJobHandler={makeSetCurrentJob(idx)}
                                  handleDiffs={handleDiffs(formikProps)}
                                  keysWithChanges={keysWithChanges}
                                  choseToStay={choseToStay}
                                  onboarding={onboarding}
                                />
                              </div>
                            </>
                          ))
                        : null}
                      <Box mb={2} />
                      {formError.length > 0 ? <div className={styles.formError}>{formError}</div> : null}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: onboarding ? 'space-between' : 'flex-end',
                          gap: '16px',
                          flexDirection: isSmall ? 'column' : 'row',
                        }}
                      >
                        {experienceLength < 3 ? (
                          <Button
                            color="primary"
                            disabled={submitting}
                            size="large"
                            variant="contained"
                            onClick={() => {
                              arrayHelpers.push({ ...basicJobItem });
                              setFormError('');
                              if (experienceLength + 1 !== initialJobCount) {
                                lengthChanged(true);
                              } else {
                                lengthChanged(false);
                              }
                              setCurrentJobs(currentJobs.concat([false]));
                            }}
                          >
                            + {intl.formatMessage({ id: 'onboarding.experience.anotherJob' })}
                          </Button>
                        ) : (
                          <div />
                        )}
                        <div
                          style={{
                            display: 'flex',
                            marginTop: isSmall ? 10 : 0,
                            justifyContent: isSmall ? 'space-between' : 'flex-end',
                          }}
                        >
                          {onboarding ? (
                            <Button
                              color={locality === 'nj' ? 'primary' : 'secondary'}
                              disabled={submitting}
                              size="large"
                              variant="contained"
                              style={{ marginRight: 10 }}
                              onClick={() => {
                                dispatch(setJobExperience(formikProps?.values));
                                history.push(`${urlPrefix}/onboarding/resume-upload`);
                              }}
                            >
                              {intl.formatMessage({ id: 'forms.onboarding.back' })}
                            </Button>
                          ) : null}
                          <Button
                            color={locality === 'nj' ? 'primary' : 'secondary'}
                            disabled={submitting}
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {submitting ? (
                              <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                              </div>
                            ) : (
                              submitLabel
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
}

function JobExperienceFormSection(props) {
  const sectionProps = { ...props, isSmall: useMediaQuery('(max-width:767px)') };
  const localStyles = {
    form: css({
      display: 'grid',
      padding: '10px 0 0 12px',
      gridTemplateColumns: '1fr 1fr',
      gap: '34px',
      '@media screen and (max-width: 671px)': {
        gridTemplateColumns: '1fr',
        padding: 0,
        gap: 0,
      },
    }),
  };

  if (props.onboarding) {
    return (
      <>
        <JobDateRange {...sectionProps} />
        <CurrentJobCheckbox {...sectionProps} />
        <FullPartTime {...sectionProps} />
        <ExperienceType {...sectionProps} />
      </>
    );
  }

  return (
    <div className={localStyles.form}>
      <div>
        <JobDateRange {...sectionProps} />
        <CurrentJobCheckbox {...sectionProps} />
      </div>
      <div>
        <FullPartTime {...sectionProps} />
        <ExperienceType {...sectionProps} />
      </div>
    </div>
  );
}
