import { FormControl, FormHelperText, FormLabel, OutlinedInput } from '@mui/material';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import NumericFormat from 'react-number-format';

export default function NumericInputField({ name, label, helperText, disabled, error, decimalScale }) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl disabled={disabled} error={error}>
          <FormLabel htmlFor={field.name}>{label}</FormLabel>

          <NumericFormat
            id={field.name}
            aria-labelledby={field.name}
            name={field.name}
            value={field.value}
            onValueChange={({ value }) => {
              form.getFieldHelpers(field.name).setValue(value);
            }}
            customInput={OutlinedInput}
            thousandSeparator
            isNumericString
            decimalScale={decimalScale ? decimalScale : 0}
            fixedDecimalScale
            allowNegative={false}
            inputProps={{ inputMode: decimalScale ? 'decimal' : 'numeric' }}
          />

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
}

NumericInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  decimalScale: PropTypes.number,
};
