import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';

/* i18n */
import { useIntl } from 'react-intl';
import { grey } from '@mui/material/colors';

/* Icons */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function DepositEnrollCard({ setShowEnroll, paymentInfoData: { current_payment } }) {
  const intl = useIntl();

  const headerContent = (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '33px' }}>
      {current_payment === 'DD' && <CheckCircleIcon sx={{ color: 'success.main', fontSize: 22 }} />}{' '}
      <Typography sx={{ fontWeight: 700, pl: 0.5 }}>
        {intl.formatMessage({
          id:
            current_payment === 'DD' ? 'fileClaim.bankingModal.currentMethod' : 'fileClaim.bankingModal.directDeposit',
        })}
      </Typography>
    </Box>
  );

  return (
    <Card
      variant="outlined"
      sx={{
        borderStyle: 'dashed',
        borderColor: grey['700'],
        height: '100%',
        '&:hover': { boxShadow: 2, cursor: 'pointer' },
      }}
      onClick={() => setShowEnroll(true)}
    >
      <CardHeader title={headerContent} sx={{ py: 1 }} />
      <CardContent sx={{ borderTopStyle: 'dashed', borderTopWidth: 1, borderTopColor: grey['700'] }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <AddCircleOutlineIcon sx={{ fontSize: 34 }} />
        </Box>
        <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
          {intl.formatMessage({ id: 'fileClaim.bankingModal.enroll' })}
        </Typography>
      </CardContent>
    </Card>
  );
}

DepositEnrollCard.propTypes = {
  paymentInfoData: PropTypes.shape({
    account_number: PropTypes.string,
    routing_number: PropTypes.number,
    account_type: PropTypes.string,
    current_payment: PropTypes.string,
  }),
  setShowEnroll: PropTypes.func,
};
